(function(exports, require$$0) {
  "use strict";
  const template = '<!DOCTYPE html>\n<html>\n<head>\n<meta charset="UTF-8">\n<meta name="viewport" content="width=device-width, initial-scale=1.0">\n<meta http-equiv="X-UA-Compatible" content="ie=edge">\n<title>Markmap</title>\n<style>\n* {\n  margin: 0;\n  padding: 0;\n}\n#mindmap {\n  display: block;\n  width: 100vw;\n  height: 100vh;\n}\n</style>\n<!--CSS-->\n</head>\n<body>\n<svg id="mindmap"></svg>\n<!--JS-->\n</body>\n</html>\n';
  const baseJsPaths = [
    `d3@${"7.8.5"}/dist/d3.min.js`,
    `markmap-view@${"0.15.8"}/dist/browser/index.js`
  ];
  var textarea;
  function decodeEntity(name2) {
    textarea = textarea || document.createElement("textarea");
    textarea.innerHTML = "&" + name2 + ";";
    return textarea.value;
  }
  var hasOwn = Object.prototype.hasOwnProperty;
  function has(object, key) {
    return object ? hasOwn.call(object, key) : false;
  }
  function assign(obj) {
    var sources = [].slice.call(arguments, 1);
    sources.forEach(function(source) {
      if (!source) {
        return;
      }
      if (typeof source !== "object") {
        throw new TypeError(source + "must be object");
      }
      Object.keys(source).forEach(function(key) {
        obj[key] = source[key];
      });
    });
    return obj;
  }
  var UNESCAPE_MD_RE = /\\([\\!"#$%&'()*+,.\/:;<=>?@[\]^_`{|}~-])/g;
  function unescapeMd(str2) {
    if (str2.indexOf("\\") < 0) {
      return str2;
    }
    return str2.replace(UNESCAPE_MD_RE, "$1");
  }
  function isValidEntityCode(c) {
    if (c >= 55296 && c <= 57343) {
      return false;
    }
    if (c >= 64976 && c <= 65007) {
      return false;
    }
    if ((c & 65535) === 65535 || (c & 65535) === 65534) {
      return false;
    }
    if (c >= 0 && c <= 8) {
      return false;
    }
    if (c === 11) {
      return false;
    }
    if (c >= 14 && c <= 31) {
      return false;
    }
    if (c >= 127 && c <= 159) {
      return false;
    }
    if (c > 1114111) {
      return false;
    }
    return true;
  }
  function fromCodePoint(c) {
    if (c > 65535) {
      c -= 65536;
      var surrogate1 = 55296 + (c >> 10), surrogate2 = 56320 + (c & 1023);
      return String.fromCharCode(surrogate1, surrogate2);
    }
    return String.fromCharCode(c);
  }
  var NAMED_ENTITY_RE = /&([a-z#][a-z0-9]{1,31});/gi;
  var DIGITAL_ENTITY_TEST_RE = /^#((?:x[a-f0-9]{1,8}|[0-9]{1,8}))/i;
  function replaceEntityPattern(match, name2) {
    var code2 = 0;
    var decoded = decodeEntity(name2);
    if (name2 !== decoded) {
      return decoded;
    } else if (name2.charCodeAt(0) === 35 && DIGITAL_ENTITY_TEST_RE.test(name2)) {
      code2 = name2[1].toLowerCase() === "x" ? parseInt(name2.slice(2), 16) : parseInt(name2.slice(1), 10);
      if (isValidEntityCode(code2)) {
        return fromCodePoint(code2);
      }
    }
    return match;
  }
  function replaceEntities(str2) {
    if (str2.indexOf("&") < 0) {
      return str2;
    }
    return str2.replace(NAMED_ENTITY_RE, replaceEntityPattern);
  }
  var HTML_ESCAPE_TEST_RE = /[&<>"]/;
  var HTML_ESCAPE_REPLACE_RE = /[&<>"]/g;
  var HTML_REPLACEMENTS = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;"
  };
  function replaceUnsafeChar(ch) {
    return HTML_REPLACEMENTS[ch];
  }
  function escapeHtml$1(str2) {
    if (HTML_ESCAPE_TEST_RE.test(str2)) {
      return str2.replace(HTML_ESCAPE_REPLACE_RE, replaceUnsafeChar);
    }
    return str2;
  }
  var rules = {};
  rules.blockquote_open = function() {
    return "<blockquote>\n";
  };
  rules.blockquote_close = function(tokens, idx) {
    return "</blockquote>" + getBreak(tokens, idx);
  };
  rules.code = function(tokens, idx) {
    if (tokens[idx].block) {
      return "<pre><code>" + escapeHtml$1(tokens[idx].content) + "</code></pre>" + getBreak(tokens, idx);
    }
    return "<code>" + escapeHtml$1(tokens[idx].content) + "</code>";
  };
  rules.fence = function(tokens, idx, options, env, instance) {
    var token = tokens[idx];
    var langClass = "";
    var langPrefix = options.langPrefix;
    var langName = "", fences2, fenceName;
    var highlighted;
    if (token.params) {
      fences2 = token.params.split(/\s+/g);
      fenceName = fences2.join(" ");
      if (has(instance.rules.fence_custom, fences2[0])) {
        return instance.rules.fence_custom[fences2[0]](tokens, idx, options, env, instance);
      }
      langName = escapeHtml$1(replaceEntities(unescapeMd(fenceName)));
      langClass = ' class="' + langPrefix + langName + '"';
    }
    if (options.highlight) {
      highlighted = options.highlight.apply(options.highlight, [token.content].concat(fences2)) || escapeHtml$1(token.content);
    } else {
      highlighted = escapeHtml$1(token.content);
    }
    return "<pre><code" + langClass + ">" + highlighted + "</code></pre>" + getBreak(tokens, idx);
  };
  rules.fence_custom = {};
  rules.heading_open = function(tokens, idx) {
    return "<h" + tokens[idx].hLevel + ">";
  };
  rules.heading_close = function(tokens, idx) {
    return "</h" + tokens[idx].hLevel + ">\n";
  };
  rules.hr = function(tokens, idx, options) {
    return (options.xhtmlOut ? "<hr />" : "<hr>") + getBreak(tokens, idx);
  };
  rules.bullet_list_open = function() {
    return "<ul>\n";
  };
  rules.bullet_list_close = function(tokens, idx) {
    return "</ul>" + getBreak(tokens, idx);
  };
  rules.list_item_open = function() {
    return "<li>";
  };
  rules.list_item_close = function() {
    return "</li>\n";
  };
  rules.ordered_list_open = function(tokens, idx) {
    var token = tokens[idx];
    var order = token.order > 1 ? ' start="' + token.order + '"' : "";
    return "<ol" + order + ">\n";
  };
  rules.ordered_list_close = function(tokens, idx) {
    return "</ol>" + getBreak(tokens, idx);
  };
  rules.paragraph_open = function(tokens, idx) {
    return tokens[idx].tight ? "" : "<p>";
  };
  rules.paragraph_close = function(tokens, idx) {
    var addBreak = !(tokens[idx].tight && idx && tokens[idx - 1].type === "inline" && !tokens[idx - 1].content);
    return (tokens[idx].tight ? "" : "</p>") + (addBreak ? getBreak(tokens, idx) : "");
  };
  rules.link_open = function(tokens, idx, options) {
    var title = tokens[idx].title ? ' title="' + escapeHtml$1(replaceEntities(tokens[idx].title)) + '"' : "";
    var target = options.linkTarget ? ' target="' + options.linkTarget + '"' : "";
    return '<a href="' + escapeHtml$1(tokens[idx].href) + '"' + title + target + ">";
  };
  rules.link_close = function() {
    return "</a>";
  };
  rules.image = function(tokens, idx, options) {
    var src = ' src="' + escapeHtml$1(tokens[idx].src) + '"';
    var title = tokens[idx].title ? ' title="' + escapeHtml$1(replaceEntities(tokens[idx].title)) + '"' : "";
    var alt = ' alt="' + (tokens[idx].alt ? escapeHtml$1(replaceEntities(unescapeMd(tokens[idx].alt))) : "") + '"';
    var suffix = options.xhtmlOut ? " /" : "";
    return "<img" + src + alt + title + suffix + ">";
  };
  rules.table_open = function() {
    return "<table>\n";
  };
  rules.table_close = function() {
    return "</table>\n";
  };
  rules.thead_open = function() {
    return "<thead>\n";
  };
  rules.thead_close = function() {
    return "</thead>\n";
  };
  rules.tbody_open = function() {
    return "<tbody>\n";
  };
  rules.tbody_close = function() {
    return "</tbody>\n";
  };
  rules.tr_open = function() {
    return "<tr>";
  };
  rules.tr_close = function() {
    return "</tr>\n";
  };
  rules.th_open = function(tokens, idx) {
    var token = tokens[idx];
    return "<th" + (token.align ? ' style="text-align:' + token.align + '"' : "") + ">";
  };
  rules.th_close = function() {
    return "</th>";
  };
  rules.td_open = function(tokens, idx) {
    var token = tokens[idx];
    return "<td" + (token.align ? ' style="text-align:' + token.align + '"' : "") + ">";
  };
  rules.td_close = function() {
    return "</td>";
  };
  rules.strong_open = function() {
    return "<strong>";
  };
  rules.strong_close = function() {
    return "</strong>";
  };
  rules.em_open = function() {
    return "<em>";
  };
  rules.em_close = function() {
    return "</em>";
  };
  rules.del_open = function() {
    return "<del>";
  };
  rules.del_close = function() {
    return "</del>";
  };
  rules.ins_open = function() {
    return "<ins>";
  };
  rules.ins_close = function() {
    return "</ins>";
  };
  rules.mark_open = function() {
    return "<mark>";
  };
  rules.mark_close = function() {
    return "</mark>";
  };
  rules.sub = function(tokens, idx) {
    return "<sub>" + escapeHtml$1(tokens[idx].content) + "</sub>";
  };
  rules.sup = function(tokens, idx) {
    return "<sup>" + escapeHtml$1(tokens[idx].content) + "</sup>";
  };
  rules.hardbreak = function(tokens, idx, options) {
    return options.xhtmlOut ? "<br />\n" : "<br>\n";
  };
  rules.softbreak = function(tokens, idx, options) {
    return options.breaks ? options.xhtmlOut ? "<br />\n" : "<br>\n" : "\n";
  };
  rules.text = function(tokens, idx) {
    return escapeHtml$1(tokens[idx].content);
  };
  rules.htmlblock = function(tokens, idx) {
    return tokens[idx].content;
  };
  rules.htmltag = function(tokens, idx) {
    return tokens[idx].content;
  };
  rules.abbr_open = function(tokens, idx) {
    return '<abbr title="' + escapeHtml$1(replaceEntities(tokens[idx].title)) + '">';
  };
  rules.abbr_close = function() {
    return "</abbr>";
  };
  rules.footnote_ref = function(tokens, idx) {
    var n = Number(tokens[idx].id + 1).toString();
    var id = "fnref" + n;
    if (tokens[idx].subId > 0) {
      id += ":" + tokens[idx].subId;
    }
    return '<sup class="footnote-ref"><a href="#fn' + n + '" id="' + id + '">[' + n + "]</a></sup>";
  };
  rules.footnote_block_open = function(tokens, idx, options) {
    var hr2 = options.xhtmlOut ? '<hr class="footnotes-sep" />\n' : '<hr class="footnotes-sep">\n';
    return hr2 + '<section class="footnotes">\n<ol class="footnotes-list">\n';
  };
  rules.footnote_block_close = function() {
    return "</ol>\n</section>\n";
  };
  rules.footnote_open = function(tokens, idx) {
    var id = Number(tokens[idx].id + 1).toString();
    return '<li id="fn' + id + '"  class="footnote-item">';
  };
  rules.footnote_close = function() {
    return "</li>\n";
  };
  rules.footnote_anchor = function(tokens, idx) {
    var n = Number(tokens[idx].id + 1).toString();
    var id = "fnref" + n;
    if (tokens[idx].subId > 0) {
      id += ":" + tokens[idx].subId;
    }
    return ' <a href="#' + id + '" class="footnote-backref">↩</a>';
  };
  rules.dl_open = function() {
    return "<dl>\n";
  };
  rules.dt_open = function() {
    return "<dt>";
  };
  rules.dd_open = function() {
    return "<dd>";
  };
  rules.dl_close = function() {
    return "</dl>\n";
  };
  rules.dt_close = function() {
    return "</dt>\n";
  };
  rules.dd_close = function() {
    return "</dd>\n";
  };
  function nextToken(tokens, idx) {
    if (++idx >= tokens.length - 2) {
      return idx;
    }
    if (tokens[idx].type === "paragraph_open" && tokens[idx].tight && (tokens[idx + 1].type === "inline" && tokens[idx + 1].content.length === 0) && (tokens[idx + 2].type === "paragraph_close" && tokens[idx + 2].tight)) {
      return nextToken(tokens, idx + 2);
    }
    return idx;
  }
  var getBreak = rules.getBreak = function getBreak2(tokens, idx) {
    idx = nextToken(tokens, idx);
    if (idx < tokens.length && tokens[idx].type === "list_item_close") {
      return "";
    }
    return "\n";
  };
  function Renderer() {
    this.rules = assign({}, rules);
    this.getBreak = rules.getBreak;
  }
  Renderer.prototype.renderInline = function(tokens, options, env) {
    var _rules2 = this.rules;
    var len = tokens.length, i2 = 0;
    var result = "";
    while (len--) {
      result += _rules2[tokens[i2].type](tokens, i2++, options, env, this);
    }
    return result;
  };
  Renderer.prototype.render = function(tokens, options, env) {
    var _rules2 = this.rules;
    var len = tokens.length, i2 = -1;
    var result = "";
    while (++i2 < len) {
      if (tokens[i2].type === "inline") {
        result += this.renderInline(tokens[i2].children, options, env);
      } else {
        result += _rules2[tokens[i2].type](tokens, i2, options, env, this);
      }
    }
    return result;
  };
  function Ruler() {
    this.__rules__ = [];
    this.__cache__ = null;
  }
  Ruler.prototype.__find__ = function(name2) {
    var len = this.__rules__.length;
    var i2 = -1;
    while (len--) {
      if (this.__rules__[++i2].name === name2) {
        return i2;
      }
    }
    return -1;
  };
  Ruler.prototype.__compile__ = function() {
    var self = this;
    var chains = [""];
    self.__rules__.forEach(function(rule) {
      if (!rule.enabled) {
        return;
      }
      rule.alt.forEach(function(altName) {
        if (chains.indexOf(altName) < 0) {
          chains.push(altName);
        }
      });
    });
    self.__cache__ = {};
    chains.forEach(function(chain) {
      self.__cache__[chain] = [];
      self.__rules__.forEach(function(rule) {
        if (!rule.enabled) {
          return;
        }
        if (chain && rule.alt.indexOf(chain) < 0) {
          return;
        }
        self.__cache__[chain].push(rule.fn);
      });
    });
  };
  Ruler.prototype.at = function(name2, fn, options) {
    var idx = this.__find__(name2);
    var opt = options || {};
    if (idx === -1) {
      throw new Error("Parser rule not found: " + name2);
    }
    this.__rules__[idx].fn = fn;
    this.__rules__[idx].alt = opt.alt || [];
    this.__cache__ = null;
  };
  Ruler.prototype.before = function(beforeName, ruleName, fn, options) {
    var idx = this.__find__(beforeName);
    var opt = options || {};
    if (idx === -1) {
      throw new Error("Parser rule not found: " + beforeName);
    }
    this.__rules__.splice(idx, 0, {
      name: ruleName,
      enabled: true,
      fn,
      alt: opt.alt || []
    });
    this.__cache__ = null;
  };
  Ruler.prototype.after = function(afterName, ruleName, fn, options) {
    var idx = this.__find__(afterName);
    var opt = options || {};
    if (idx === -1) {
      throw new Error("Parser rule not found: " + afterName);
    }
    this.__rules__.splice(idx + 1, 0, {
      name: ruleName,
      enabled: true,
      fn,
      alt: opt.alt || []
    });
    this.__cache__ = null;
  };
  Ruler.prototype.push = function(ruleName, fn, options) {
    var opt = options || {};
    this.__rules__.push({
      name: ruleName,
      enabled: true,
      fn,
      alt: opt.alt || []
    });
    this.__cache__ = null;
  };
  Ruler.prototype.enable = function(list2, strict) {
    list2 = !Array.isArray(list2) ? [list2] : list2;
    if (strict) {
      this.__rules__.forEach(function(rule) {
        rule.enabled = false;
      });
    }
    list2.forEach(function(name2) {
      var idx = this.__find__(name2);
      if (idx < 0) {
        throw new Error("Rules manager: invalid rule name " + name2);
      }
      this.__rules__[idx].enabled = true;
    }, this);
    this.__cache__ = null;
  };
  Ruler.prototype.disable = function(list2) {
    list2 = !Array.isArray(list2) ? [list2] : list2;
    list2.forEach(function(name2) {
      var idx = this.__find__(name2);
      if (idx < 0) {
        throw new Error("Rules manager: invalid rule name " + name2);
      }
      this.__rules__[idx].enabled = false;
    }, this);
    this.__cache__ = null;
  };
  Ruler.prototype.getRules = function(chainName) {
    if (this.__cache__ === null) {
      this.__compile__();
    }
    return this.__cache__[chainName] || [];
  };
  function block(state) {
    if (state.inlineMode) {
      state.tokens.push({
        type: "inline",
        content: state.src.replace(/\n/g, " ").trim(),
        level: 0,
        lines: [0, 1],
        children: []
      });
    } else {
      state.block.parse(state.src, state.options, state.env, state.tokens);
    }
  }
  function StateInline(src, parserInline, options, env, outTokens) {
    this.src = src;
    this.env = env;
    this.options = options;
    this.parser = parserInline;
    this.tokens = outTokens;
    this.pos = 0;
    this.posMax = this.src.length;
    this.level = 0;
    this.pending = "";
    this.pendingLevel = 0;
    this.cache = [];
    this.isInLabel = false;
    this.linkLevel = 0;
    this.linkContent = "";
    this.labelUnmatchedScopes = 0;
  }
  StateInline.prototype.pushPending = function() {
    this.tokens.push({
      type: "text",
      content: this.pending,
      level: this.pendingLevel
    });
    this.pending = "";
  };
  StateInline.prototype.push = function(token) {
    if (this.pending) {
      this.pushPending();
    }
    this.tokens.push(token);
    this.pendingLevel = this.level;
  };
  StateInline.prototype.cacheSet = function(key, val) {
    for (var i2 = this.cache.length; i2 <= key; i2++) {
      this.cache.push(0);
    }
    this.cache[key] = val;
  };
  StateInline.prototype.cacheGet = function(key) {
    return key < this.cache.length ? this.cache[key] : 0;
  };
  function parseLinkLabel(state, start) {
    var level, found, marker, labelEnd = -1, max = state.posMax, oldPos = state.pos, oldFlag = state.isInLabel;
    if (state.isInLabel) {
      return -1;
    }
    if (state.labelUnmatchedScopes) {
      state.labelUnmatchedScopes--;
      return -1;
    }
    state.pos = start + 1;
    state.isInLabel = true;
    level = 1;
    while (state.pos < max) {
      marker = state.src.charCodeAt(state.pos);
      if (marker === 91) {
        level++;
      } else if (marker === 93) {
        level--;
        if (level === 0) {
          found = true;
          break;
        }
      }
      state.parser.skipToken(state);
    }
    if (found) {
      labelEnd = state.pos;
      state.labelUnmatchedScopes = 0;
    } else {
      state.labelUnmatchedScopes = level - 1;
    }
    state.pos = oldPos;
    state.isInLabel = oldFlag;
    return labelEnd;
  }
  function parseAbbr(str2, parserInline, options, env) {
    var state, labelEnd, pos, max, label, title;
    if (str2.charCodeAt(0) !== 42) {
      return -1;
    }
    if (str2.charCodeAt(1) !== 91) {
      return -1;
    }
    if (str2.indexOf("]:") === -1) {
      return -1;
    }
    state = new StateInline(str2, parserInline, options, env, []);
    labelEnd = parseLinkLabel(state, 1);
    if (labelEnd < 0 || str2.charCodeAt(labelEnd + 1) !== 58) {
      return -1;
    }
    max = state.posMax;
    for (pos = labelEnd + 2; pos < max; pos++) {
      if (state.src.charCodeAt(pos) === 10) {
        break;
      }
    }
    label = str2.slice(2, labelEnd);
    title = str2.slice(labelEnd + 2, pos).trim();
    if (title.length === 0) {
      return -1;
    }
    if (!env.abbreviations) {
      env.abbreviations = {};
    }
    if (typeof env.abbreviations[":" + label] === "undefined") {
      env.abbreviations[":" + label] = title;
    }
    return pos;
  }
  function abbr(state) {
    var tokens = state.tokens, i2, l, content, pos;
    if (state.inlineMode) {
      return;
    }
    for (i2 = 1, l = tokens.length - 1; i2 < l; i2++) {
      if (tokens[i2 - 1].type === "paragraph_open" && tokens[i2].type === "inline" && tokens[i2 + 1].type === "paragraph_close") {
        content = tokens[i2].content;
        while (content.length) {
          pos = parseAbbr(content, state.inline, state.options, state.env);
          if (pos < 0) {
            break;
          }
          content = content.slice(pos).trim();
        }
        tokens[i2].content = content;
        if (!content.length) {
          tokens[i2 - 1].tight = true;
          tokens[i2 + 1].tight = true;
        }
      }
    }
  }
  function normalizeLink(url) {
    var normalized = replaceEntities(url);
    try {
      normalized = decodeURI(normalized);
    } catch (err) {
    }
    return encodeURI(normalized);
  }
  function parseLinkDestination(state, pos) {
    var code2, level, link, start = pos, max = state.posMax;
    if (state.src.charCodeAt(pos) === 60) {
      pos++;
      while (pos < max) {
        code2 = state.src.charCodeAt(pos);
        if (code2 === 10) {
          return false;
        }
        if (code2 === 62) {
          link = normalizeLink(unescapeMd(state.src.slice(start + 1, pos)));
          if (!state.parser.validateLink(link)) {
            return false;
          }
          state.pos = pos + 1;
          state.linkContent = link;
          return true;
        }
        if (code2 === 92 && pos + 1 < max) {
          pos += 2;
          continue;
        }
        pos++;
      }
      return false;
    }
    level = 0;
    while (pos < max) {
      code2 = state.src.charCodeAt(pos);
      if (code2 === 32) {
        break;
      }
      if (code2 < 32 || code2 === 127) {
        break;
      }
      if (code2 === 92 && pos + 1 < max) {
        pos += 2;
        continue;
      }
      if (code2 === 40) {
        level++;
        if (level > 1) {
          break;
        }
      }
      if (code2 === 41) {
        level--;
        if (level < 0) {
          break;
        }
      }
      pos++;
    }
    if (start === pos) {
      return false;
    }
    link = unescapeMd(state.src.slice(start, pos));
    if (!state.parser.validateLink(link)) {
      return false;
    }
    state.linkContent = link;
    state.pos = pos;
    return true;
  }
  function parseLinkTitle(state, pos) {
    var code2, start = pos, max = state.posMax, marker = state.src.charCodeAt(pos);
    if (marker !== 34 && marker !== 39 && marker !== 40) {
      return false;
    }
    pos++;
    if (marker === 40) {
      marker = 41;
    }
    while (pos < max) {
      code2 = state.src.charCodeAt(pos);
      if (code2 === marker) {
        state.pos = pos + 1;
        state.linkContent = unescapeMd(state.src.slice(start + 1, pos));
        return true;
      }
      if (code2 === 92 && pos + 1 < max) {
        pos += 2;
        continue;
      }
      pos++;
    }
    return false;
  }
  function normalizeReference(str2) {
    return str2.trim().replace(/\s+/g, " ").toUpperCase();
  }
  function parseReference(str2, parser, options, env) {
    var state, labelEnd, pos, max, code2, start, href, title, label;
    if (str2.charCodeAt(0) !== 91) {
      return -1;
    }
    if (str2.indexOf("]:") === -1) {
      return -1;
    }
    state = new StateInline(str2, parser, options, env, []);
    labelEnd = parseLinkLabel(state, 0);
    if (labelEnd < 0 || str2.charCodeAt(labelEnd + 1) !== 58) {
      return -1;
    }
    max = state.posMax;
    for (pos = labelEnd + 2; pos < max; pos++) {
      code2 = state.src.charCodeAt(pos);
      if (code2 !== 32 && code2 !== 10) {
        break;
      }
    }
    if (!parseLinkDestination(state, pos)) {
      return -1;
    }
    href = state.linkContent;
    pos = state.pos;
    start = pos;
    for (pos = pos + 1; pos < max; pos++) {
      code2 = state.src.charCodeAt(pos);
      if (code2 !== 32 && code2 !== 10) {
        break;
      }
    }
    if (pos < max && start !== pos && parseLinkTitle(state, pos)) {
      title = state.linkContent;
      pos = state.pos;
    } else {
      title = "";
      pos = start;
    }
    while (pos < max && state.src.charCodeAt(pos) === 32) {
      pos++;
    }
    if (pos < max && state.src.charCodeAt(pos) !== 10) {
      return -1;
    }
    label = normalizeReference(str2.slice(1, labelEnd));
    if (typeof env.references[label] === "undefined") {
      env.references[label] = { title, href };
    }
    return pos;
  }
  function references(state) {
    var tokens = state.tokens, i2, l, content, pos;
    state.env.references = state.env.references || {};
    if (state.inlineMode) {
      return;
    }
    for (i2 = 1, l = tokens.length - 1; i2 < l; i2++) {
      if (tokens[i2].type === "inline" && tokens[i2 - 1].type === "paragraph_open" && tokens[i2 + 1].type === "paragraph_close") {
        content = tokens[i2].content;
        while (content.length) {
          pos = parseReference(content, state.inline, state.options, state.env);
          if (pos < 0) {
            break;
          }
          content = content.slice(pos).trim();
        }
        tokens[i2].content = content;
        if (!content.length) {
          tokens[i2 - 1].tight = true;
          tokens[i2 + 1].tight = true;
        }
      }
    }
  }
  function inline(state) {
    var tokens = state.tokens, tok, i2, l;
    for (i2 = 0, l = tokens.length; i2 < l; i2++) {
      tok = tokens[i2];
      if (tok.type === "inline") {
        state.inline.parse(tok.content, state.options, state.env, tok.children);
      }
    }
  }
  function footnote_block(state) {
    var i2, l, j, t, lastParagraph, list2, tokens, current, currentLabel, level = 0, insideRef = false, refTokens = {};
    if (!state.env.footnotes) {
      return;
    }
    state.tokens = state.tokens.filter(function(tok) {
      if (tok.type === "footnote_reference_open") {
        insideRef = true;
        current = [];
        currentLabel = tok.label;
        return false;
      }
      if (tok.type === "footnote_reference_close") {
        insideRef = false;
        refTokens[":" + currentLabel] = current;
        return false;
      }
      if (insideRef) {
        current.push(tok);
      }
      return !insideRef;
    });
    if (!state.env.footnotes.list) {
      return;
    }
    list2 = state.env.footnotes.list;
    state.tokens.push({
      type: "footnote_block_open",
      level: level++
    });
    for (i2 = 0, l = list2.length; i2 < l; i2++) {
      state.tokens.push({
        type: "footnote_open",
        id: i2,
        level: level++
      });
      if (list2[i2].tokens) {
        tokens = [];
        tokens.push({
          type: "paragraph_open",
          tight: false,
          level: level++
        });
        tokens.push({
          type: "inline",
          content: "",
          level,
          children: list2[i2].tokens
        });
        tokens.push({
          type: "paragraph_close",
          tight: false,
          level: --level
        });
      } else if (list2[i2].label) {
        tokens = refTokens[":" + list2[i2].label];
      }
      state.tokens = state.tokens.concat(tokens);
      if (state.tokens[state.tokens.length - 1].type === "paragraph_close") {
        lastParagraph = state.tokens.pop();
      } else {
        lastParagraph = null;
      }
      t = list2[i2].count > 0 ? list2[i2].count : 1;
      for (j = 0; j < t; j++) {
        state.tokens.push({
          type: "footnote_anchor",
          id: i2,
          subId: j,
          level
        });
      }
      if (lastParagraph) {
        state.tokens.push(lastParagraph);
      }
      state.tokens.push({
        type: "footnote_close",
        level: --level
      });
    }
    state.tokens.push({
      type: "footnote_block_close",
      level: --level
    });
  }
  var PUNCT_CHARS = ` 
()[]'".,!?-`;
  function regEscape(s) {
    return s.replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g, "\\$1");
  }
  function abbr2(state) {
    var i2, j, l, tokens, token, text2, nodes, pos, level, reg, m, regText, blockTokens = state.tokens;
    if (!state.env.abbreviations) {
      return;
    }
    if (!state.env.abbrRegExp) {
      regText = "(^|[" + PUNCT_CHARS.split("").map(regEscape).join("") + "])(" + Object.keys(state.env.abbreviations).map(function(x) {
        return x.substr(1);
      }).sort(function(a, b) {
        return b.length - a.length;
      }).map(regEscape).join("|") + ")($|[" + PUNCT_CHARS.split("").map(regEscape).join("") + "])";
      state.env.abbrRegExp = new RegExp(regText, "g");
    }
    reg = state.env.abbrRegExp;
    for (j = 0, l = blockTokens.length; j < l; j++) {
      if (blockTokens[j].type !== "inline") {
        continue;
      }
      tokens = blockTokens[j].children;
      for (i2 = tokens.length - 1; i2 >= 0; i2--) {
        token = tokens[i2];
        if (token.type !== "text") {
          continue;
        }
        pos = 0;
        text2 = token.content;
        reg.lastIndex = 0;
        level = token.level;
        nodes = [];
        while (m = reg.exec(text2)) {
          if (reg.lastIndex > pos) {
            nodes.push({
              type: "text",
              content: text2.slice(pos, m.index + m[1].length),
              level
            });
          }
          nodes.push({
            type: "abbr_open",
            title: state.env.abbreviations[":" + m[2]],
            level: level++
          });
          nodes.push({
            type: "text",
            content: m[2],
            level
          });
          nodes.push({
            type: "abbr_close",
            level: --level
          });
          pos = reg.lastIndex - m[3].length;
        }
        if (!nodes.length) {
          continue;
        }
        if (pos < text2.length) {
          nodes.push({
            type: "text",
            content: text2.slice(pos),
            level
          });
        }
        blockTokens[j].children = tokens = [].concat(tokens.slice(0, i2), nodes, tokens.slice(i2 + 1));
      }
    }
  }
  var RARE_RE = /\+-|\.\.|\?\?\?\?|!!!!|,,|--/;
  var SCOPED_ABBR_RE = /\((c|tm|r|p)\)/ig;
  var SCOPED_ABBR = {
    "c": "©",
    "r": "®",
    "p": "§",
    "tm": "™"
  };
  function replaceScopedAbbr(str2) {
    if (str2.indexOf("(") < 0) {
      return str2;
    }
    return str2.replace(SCOPED_ABBR_RE, function(match, name2) {
      return SCOPED_ABBR[name2.toLowerCase()];
    });
  }
  function replace(state) {
    var i2, token, text2, inlineTokens, blkIdx;
    if (!state.options.typographer) {
      return;
    }
    for (blkIdx = state.tokens.length - 1; blkIdx >= 0; blkIdx--) {
      if (state.tokens[blkIdx].type !== "inline") {
        continue;
      }
      inlineTokens = state.tokens[blkIdx].children;
      for (i2 = inlineTokens.length - 1; i2 >= 0; i2--) {
        token = inlineTokens[i2];
        if (token.type === "text") {
          text2 = token.content;
          text2 = replaceScopedAbbr(text2);
          if (RARE_RE.test(text2)) {
            text2 = text2.replace(/\+-/g, "±").replace(/\.{2,}/g, "…").replace(/([?!])…/g, "$1..").replace(/([?!]){4,}/g, "$1$1$1").replace(/,{2,}/g, ",").replace(/(^|[^-])---([^-]|$)/mg, "$1—$2").replace(/(^|\s)--(\s|$)/mg, "$1–$2").replace(/(^|[^-\s])--([^-\s]|$)/mg, "$1–$2");
          }
          token.content = text2;
        }
      }
    }
  }
  var QUOTE_TEST_RE = /['"]/;
  var QUOTE_RE = /['"]/g;
  var PUNCT_RE = /[-\s()\[\]]/;
  var APOSTROPHE = "’";
  function isLetter(str2, pos) {
    if (pos < 0 || pos >= str2.length) {
      return false;
    }
    return !PUNCT_RE.test(str2[pos]);
  }
  function replaceAt(str2, index, ch) {
    return str2.substr(0, index) + ch + str2.substr(index + 1);
  }
  function smartquotes(state) {
    var i2, token, text2, t, pos, max, thisLevel, lastSpace, nextSpace, item, canOpen, canClose, j, isSingle, blkIdx, tokens, stack;
    if (!state.options.typographer) {
      return;
    }
    stack = [];
    for (blkIdx = state.tokens.length - 1; blkIdx >= 0; blkIdx--) {
      if (state.tokens[blkIdx].type !== "inline") {
        continue;
      }
      tokens = state.tokens[blkIdx].children;
      stack.length = 0;
      for (i2 = 0; i2 < tokens.length; i2++) {
        token = tokens[i2];
        if (token.type !== "text" || QUOTE_TEST_RE.test(token.text)) {
          continue;
        }
        thisLevel = tokens[i2].level;
        for (j = stack.length - 1; j >= 0; j--) {
          if (stack[j].level <= thisLevel) {
            break;
          }
        }
        stack.length = j + 1;
        text2 = token.content;
        pos = 0;
        max = text2.length;
        OUTER:
          while (pos < max) {
            QUOTE_RE.lastIndex = pos;
            t = QUOTE_RE.exec(text2);
            if (!t) {
              break;
            }
            lastSpace = !isLetter(text2, t.index - 1);
            pos = t.index + 1;
            isSingle = t[0] === "'";
            nextSpace = !isLetter(text2, pos);
            if (!nextSpace && !lastSpace) {
              if (isSingle) {
                token.content = replaceAt(token.content, t.index, APOSTROPHE);
              }
              continue;
            }
            canOpen = !nextSpace;
            canClose = !lastSpace;
            if (canClose) {
              for (j = stack.length - 1; j >= 0; j--) {
                item = stack[j];
                if (stack[j].level < thisLevel) {
                  break;
                }
                if (item.single === isSingle && stack[j].level === thisLevel) {
                  item = stack[j];
                  if (isSingle) {
                    tokens[item.token].content = replaceAt(tokens[item.token].content, item.pos, state.options.quotes[2]);
                    token.content = replaceAt(token.content, t.index, state.options.quotes[3]);
                  } else {
                    tokens[item.token].content = replaceAt(tokens[item.token].content, item.pos, state.options.quotes[0]);
                    token.content = replaceAt(token.content, t.index, state.options.quotes[1]);
                  }
                  stack.length = j;
                  continue OUTER;
                }
              }
            }
            if (canOpen) {
              stack.push({
                token: i2,
                pos: t.index,
                single: isSingle,
                level: thisLevel
              });
            } else if (canClose && isSingle) {
              token.content = replaceAt(token.content, t.index, APOSTROPHE);
            }
          }
      }
    }
  }
  var _rules = [
    ["block", block],
    ["abbr", abbr],
    ["references", references],
    ["inline", inline],
    ["footnote_tail", footnote_block],
    ["abbr2", abbr2],
    ["replacements", replace],
    ["smartquotes", smartquotes]
  ];
  function Core() {
    this.options = {};
    this.ruler = new Ruler();
    for (var i2 = 0; i2 < _rules.length; i2++) {
      this.ruler.push(_rules[i2][0], _rules[i2][1]);
    }
  }
  Core.prototype.process = function(state) {
    var i2, l, rules2;
    rules2 = this.ruler.getRules("");
    for (i2 = 0, l = rules2.length; i2 < l; i2++) {
      rules2[i2](state);
    }
  };
  function StateBlock(src, parser, options, env, tokens) {
    var ch, s, start, pos, len, indent, indent_found;
    this.src = src;
    this.parser = parser;
    this.options = options;
    this.env = env;
    this.tokens = tokens;
    this.bMarks = [];
    this.eMarks = [];
    this.tShift = [];
    this.blkIndent = 0;
    this.line = 0;
    this.lineMax = 0;
    this.tight = false;
    this.parentType = "root";
    this.ddIndent = -1;
    this.level = 0;
    this.result = "";
    s = this.src;
    indent = 0;
    indent_found = false;
    for (start = pos = indent = 0, len = s.length; pos < len; pos++) {
      ch = s.charCodeAt(pos);
      if (!indent_found) {
        if (ch === 32) {
          indent++;
          continue;
        } else {
          indent_found = true;
        }
      }
      if (ch === 10 || pos === len - 1) {
        if (ch !== 10) {
          pos++;
        }
        this.bMarks.push(start);
        this.eMarks.push(pos);
        this.tShift.push(indent);
        indent_found = false;
        indent = 0;
        start = pos + 1;
      }
    }
    this.bMarks.push(s.length);
    this.eMarks.push(s.length);
    this.tShift.push(0);
    this.lineMax = this.bMarks.length - 1;
  }
  StateBlock.prototype.isEmpty = function isEmpty(line) {
    return this.bMarks[line] + this.tShift[line] >= this.eMarks[line];
  };
  StateBlock.prototype.skipEmptyLines = function skipEmptyLines(from) {
    for (var max = this.lineMax; from < max; from++) {
      if (this.bMarks[from] + this.tShift[from] < this.eMarks[from]) {
        break;
      }
    }
    return from;
  };
  StateBlock.prototype.skipSpaces = function skipSpaces(pos) {
    for (var max = this.src.length; pos < max; pos++) {
      if (this.src.charCodeAt(pos) !== 32) {
        break;
      }
    }
    return pos;
  };
  StateBlock.prototype.skipChars = function skipChars(pos, code2) {
    for (var max = this.src.length; pos < max; pos++) {
      if (this.src.charCodeAt(pos) !== code2) {
        break;
      }
    }
    return pos;
  };
  StateBlock.prototype.skipCharsBack = function skipCharsBack(pos, code2, min) {
    if (pos <= min) {
      return pos;
    }
    while (pos > min) {
      if (code2 !== this.src.charCodeAt(--pos)) {
        return pos + 1;
      }
    }
    return pos;
  };
  StateBlock.prototype.getLines = function getLines(begin, end, indent, keepLastLF) {
    var i2, first, last, queue, shift, line = begin;
    if (begin >= end) {
      return "";
    }
    if (line + 1 === end) {
      first = this.bMarks[line] + Math.min(this.tShift[line], indent);
      last = keepLastLF ? this.eMarks[line] + 1 : this.eMarks[line];
      return this.src.slice(first, last);
    }
    queue = new Array(end - begin);
    for (i2 = 0; line < end; line++, i2++) {
      shift = this.tShift[line];
      if (shift > indent) {
        shift = indent;
      }
      if (shift < 0) {
        shift = 0;
      }
      first = this.bMarks[line] + shift;
      if (line + 1 < end || keepLastLF) {
        last = this.eMarks[line] + 1;
      } else {
        last = this.eMarks[line];
      }
      queue[i2] = this.src.slice(first, last);
    }
    return queue.join("");
  };
  function code(state, startLine, endLine) {
    var nextLine, last;
    if (state.tShift[startLine] - state.blkIndent < 4) {
      return false;
    }
    last = nextLine = startLine + 1;
    while (nextLine < endLine) {
      if (state.isEmpty(nextLine)) {
        nextLine++;
        continue;
      }
      if (state.tShift[nextLine] - state.blkIndent >= 4) {
        nextLine++;
        last = nextLine;
        continue;
      }
      break;
    }
    state.line = nextLine;
    state.tokens.push({
      type: "code",
      content: state.getLines(startLine, last, 4 + state.blkIndent, true),
      block: true,
      lines: [startLine, state.line],
      level: state.level
    });
    return true;
  }
  function fences(state, startLine, endLine, silent) {
    var marker, len, params, nextLine, mem, haveEndMarker = false, pos = state.bMarks[startLine] + state.tShift[startLine], max = state.eMarks[startLine];
    if (pos + 3 > max) {
      return false;
    }
    marker = state.src.charCodeAt(pos);
    if (marker !== 126 && marker !== 96) {
      return false;
    }
    mem = pos;
    pos = state.skipChars(pos, marker);
    len = pos - mem;
    if (len < 3) {
      return false;
    }
    params = state.src.slice(pos, max).trim();
    if (params.indexOf("`") >= 0) {
      return false;
    }
    if (silent) {
      return true;
    }
    nextLine = startLine;
    for (; ; ) {
      nextLine++;
      if (nextLine >= endLine) {
        break;
      }
      pos = mem = state.bMarks[nextLine] + state.tShift[nextLine];
      max = state.eMarks[nextLine];
      if (pos < max && state.tShift[nextLine] < state.blkIndent) {
        break;
      }
      if (state.src.charCodeAt(pos) !== marker) {
        continue;
      }
      if (state.tShift[nextLine] - state.blkIndent >= 4) {
        continue;
      }
      pos = state.skipChars(pos, marker);
      if (pos - mem < len) {
        continue;
      }
      pos = state.skipSpaces(pos);
      if (pos < max) {
        continue;
      }
      haveEndMarker = true;
      break;
    }
    len = state.tShift[startLine];
    state.line = nextLine + (haveEndMarker ? 1 : 0);
    state.tokens.push({
      type: "fence",
      params,
      content: state.getLines(startLine + 1, nextLine, len, true),
      lines: [startLine, state.line],
      level: state.level
    });
    return true;
  }
  function blockquote(state, startLine, endLine, silent) {
    var nextLine, lastLineEmpty, oldTShift, oldBMarks, oldIndent, oldParentType, lines, terminatorRules, i2, l, terminate, pos = state.bMarks[startLine] + state.tShift[startLine], max = state.eMarks[startLine];
    if (pos > max) {
      return false;
    }
    if (state.src.charCodeAt(pos++) !== 62) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    if (silent) {
      return true;
    }
    if (state.src.charCodeAt(pos) === 32) {
      pos++;
    }
    oldIndent = state.blkIndent;
    state.blkIndent = 0;
    oldBMarks = [state.bMarks[startLine]];
    state.bMarks[startLine] = pos;
    pos = pos < max ? state.skipSpaces(pos) : pos;
    lastLineEmpty = pos >= max;
    oldTShift = [state.tShift[startLine]];
    state.tShift[startLine] = pos - state.bMarks[startLine];
    terminatorRules = state.parser.ruler.getRules("blockquote");
    for (nextLine = startLine + 1; nextLine < endLine; nextLine++) {
      pos = state.bMarks[nextLine] + state.tShift[nextLine];
      max = state.eMarks[nextLine];
      if (pos >= max) {
        break;
      }
      if (state.src.charCodeAt(pos++) === 62) {
        if (state.src.charCodeAt(pos) === 32) {
          pos++;
        }
        oldBMarks.push(state.bMarks[nextLine]);
        state.bMarks[nextLine] = pos;
        pos = pos < max ? state.skipSpaces(pos) : pos;
        lastLineEmpty = pos >= max;
        oldTShift.push(state.tShift[nextLine]);
        state.tShift[nextLine] = pos - state.bMarks[nextLine];
        continue;
      }
      if (lastLineEmpty) {
        break;
      }
      terminate = false;
      for (i2 = 0, l = terminatorRules.length; i2 < l; i2++) {
        if (terminatorRules[i2](state, nextLine, endLine, true)) {
          terminate = true;
          break;
        }
      }
      if (terminate) {
        break;
      }
      oldBMarks.push(state.bMarks[nextLine]);
      oldTShift.push(state.tShift[nextLine]);
      state.tShift[nextLine] = -1337;
    }
    oldParentType = state.parentType;
    state.parentType = "blockquote";
    state.tokens.push({
      type: "blockquote_open",
      lines: lines = [startLine, 0],
      level: state.level++
    });
    state.parser.tokenize(state, startLine, nextLine);
    state.tokens.push({
      type: "blockquote_close",
      level: --state.level
    });
    state.parentType = oldParentType;
    lines[1] = state.line;
    for (i2 = 0; i2 < oldTShift.length; i2++) {
      state.bMarks[i2 + startLine] = oldBMarks[i2];
      state.tShift[i2 + startLine] = oldTShift[i2];
    }
    state.blkIndent = oldIndent;
    return true;
  }
  function hr(state, startLine, endLine, silent) {
    var marker, cnt, ch, pos = state.bMarks[startLine], max = state.eMarks[startLine];
    pos += state.tShift[startLine];
    if (pos > max) {
      return false;
    }
    marker = state.src.charCodeAt(pos++);
    if (marker !== 42 && marker !== 45 && marker !== 95) {
      return false;
    }
    cnt = 1;
    while (pos < max) {
      ch = state.src.charCodeAt(pos++);
      if (ch !== marker && ch !== 32) {
        return false;
      }
      if (ch === marker) {
        cnt++;
      }
    }
    if (cnt < 3) {
      return false;
    }
    if (silent) {
      return true;
    }
    state.line = startLine + 1;
    state.tokens.push({
      type: "hr",
      lines: [startLine, state.line],
      level: state.level
    });
    return true;
  }
  function skipBulletListMarker(state, startLine) {
    var marker, pos, max;
    pos = state.bMarks[startLine] + state.tShift[startLine];
    max = state.eMarks[startLine];
    if (pos >= max) {
      return -1;
    }
    marker = state.src.charCodeAt(pos++);
    if (marker !== 42 && marker !== 45 && marker !== 43) {
      return -1;
    }
    if (pos < max && state.src.charCodeAt(pos) !== 32) {
      return -1;
    }
    return pos;
  }
  function skipOrderedListMarker(state, startLine) {
    var ch, pos = state.bMarks[startLine] + state.tShift[startLine], max = state.eMarks[startLine];
    if (pos + 1 >= max) {
      return -1;
    }
    ch = state.src.charCodeAt(pos++);
    if (ch < 48 || ch > 57) {
      return -1;
    }
    for (; ; ) {
      if (pos >= max) {
        return -1;
      }
      ch = state.src.charCodeAt(pos++);
      if (ch >= 48 && ch <= 57) {
        continue;
      }
      if (ch === 41 || ch === 46) {
        break;
      }
      return -1;
    }
    if (pos < max && state.src.charCodeAt(pos) !== 32) {
      return -1;
    }
    return pos;
  }
  function markTightParagraphs(state, idx) {
    var i2, l, level = state.level + 2;
    for (i2 = idx + 2, l = state.tokens.length - 2; i2 < l; i2++) {
      if (state.tokens[i2].level === level && state.tokens[i2].type === "paragraph_open") {
        state.tokens[i2 + 2].tight = true;
        state.tokens[i2].tight = true;
        i2 += 2;
      }
    }
  }
  function list(state, startLine, endLine, silent) {
    var nextLine, indent, oldTShift, oldIndent, oldTight, oldParentType, start, posAfterMarker, max, indentAfterMarker, markerValue, markerCharCode, isOrdered, contentStart, listTokIdx, prevEmptyEnd, listLines, itemLines, tight = true, terminatorRules, i2, l, terminate;
    if ((posAfterMarker = skipOrderedListMarker(state, startLine)) >= 0) {
      isOrdered = true;
    } else if ((posAfterMarker = skipBulletListMarker(state, startLine)) >= 0) {
      isOrdered = false;
    } else {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    markerCharCode = state.src.charCodeAt(posAfterMarker - 1);
    if (silent) {
      return true;
    }
    listTokIdx = state.tokens.length;
    if (isOrdered) {
      start = state.bMarks[startLine] + state.tShift[startLine];
      markerValue = Number(state.src.substr(start, posAfterMarker - start - 1));
      state.tokens.push({
        type: "ordered_list_open",
        order: markerValue,
        lines: listLines = [startLine, 0],
        level: state.level++
      });
    } else {
      state.tokens.push({
        type: "bullet_list_open",
        lines: listLines = [startLine, 0],
        level: state.level++
      });
    }
    nextLine = startLine;
    prevEmptyEnd = false;
    terminatorRules = state.parser.ruler.getRules("list");
    while (nextLine < endLine) {
      contentStart = state.skipSpaces(posAfterMarker);
      max = state.eMarks[nextLine];
      if (contentStart >= max) {
        indentAfterMarker = 1;
      } else {
        indentAfterMarker = contentStart - posAfterMarker;
      }
      if (indentAfterMarker > 4) {
        indentAfterMarker = 1;
      }
      if (indentAfterMarker < 1) {
        indentAfterMarker = 1;
      }
      indent = posAfterMarker - state.bMarks[nextLine] + indentAfterMarker;
      state.tokens.push({
        type: "list_item_open",
        lines: itemLines = [startLine, 0],
        level: state.level++
      });
      oldIndent = state.blkIndent;
      oldTight = state.tight;
      oldTShift = state.tShift[startLine];
      oldParentType = state.parentType;
      state.tShift[startLine] = contentStart - state.bMarks[startLine];
      state.blkIndent = indent;
      state.tight = true;
      state.parentType = "list";
      state.parser.tokenize(state, startLine, endLine, true);
      if (!state.tight || prevEmptyEnd) {
        tight = false;
      }
      prevEmptyEnd = state.line - startLine > 1 && state.isEmpty(state.line - 1);
      state.blkIndent = oldIndent;
      state.tShift[startLine] = oldTShift;
      state.tight = oldTight;
      state.parentType = oldParentType;
      state.tokens.push({
        type: "list_item_close",
        level: --state.level
      });
      nextLine = startLine = state.line;
      itemLines[1] = nextLine;
      contentStart = state.bMarks[startLine];
      if (nextLine >= endLine) {
        break;
      }
      if (state.isEmpty(nextLine)) {
        break;
      }
      if (state.tShift[nextLine] < state.blkIndent) {
        break;
      }
      terminate = false;
      for (i2 = 0, l = terminatorRules.length; i2 < l; i2++) {
        if (terminatorRules[i2](state, nextLine, endLine, true)) {
          terminate = true;
          break;
        }
      }
      if (terminate) {
        break;
      }
      if (isOrdered) {
        posAfterMarker = skipOrderedListMarker(state, nextLine);
        if (posAfterMarker < 0) {
          break;
        }
      } else {
        posAfterMarker = skipBulletListMarker(state, nextLine);
        if (posAfterMarker < 0) {
          break;
        }
      }
      if (markerCharCode !== state.src.charCodeAt(posAfterMarker - 1)) {
        break;
      }
    }
    state.tokens.push({
      type: isOrdered ? "ordered_list_close" : "bullet_list_close",
      level: --state.level
    });
    listLines[1] = nextLine;
    state.line = nextLine;
    if (tight) {
      markTightParagraphs(state, listTokIdx);
    }
    return true;
  }
  function footnote(state, startLine, endLine, silent) {
    var oldBMark, oldTShift, oldParentType, pos, label, start = state.bMarks[startLine] + state.tShift[startLine], max = state.eMarks[startLine];
    if (start + 4 > max) {
      return false;
    }
    if (state.src.charCodeAt(start) !== 91) {
      return false;
    }
    if (state.src.charCodeAt(start + 1) !== 94) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    for (pos = start + 2; pos < max; pos++) {
      if (state.src.charCodeAt(pos) === 32) {
        return false;
      }
      if (state.src.charCodeAt(pos) === 93) {
        break;
      }
    }
    if (pos === start + 2) {
      return false;
    }
    if (pos + 1 >= max || state.src.charCodeAt(++pos) !== 58) {
      return false;
    }
    if (silent) {
      return true;
    }
    pos++;
    if (!state.env.footnotes) {
      state.env.footnotes = {};
    }
    if (!state.env.footnotes.refs) {
      state.env.footnotes.refs = {};
    }
    label = state.src.slice(start + 2, pos - 2);
    state.env.footnotes.refs[":" + label] = -1;
    state.tokens.push({
      type: "footnote_reference_open",
      label,
      level: state.level++
    });
    oldBMark = state.bMarks[startLine];
    oldTShift = state.tShift[startLine];
    oldParentType = state.parentType;
    state.tShift[startLine] = state.skipSpaces(pos) - pos;
    state.bMarks[startLine] = pos;
    state.blkIndent += 4;
    state.parentType = "footnote";
    if (state.tShift[startLine] < state.blkIndent) {
      state.tShift[startLine] += state.blkIndent;
      state.bMarks[startLine] -= state.blkIndent;
    }
    state.parser.tokenize(state, startLine, endLine, true);
    state.parentType = oldParentType;
    state.blkIndent -= 4;
    state.tShift[startLine] = oldTShift;
    state.bMarks[startLine] = oldBMark;
    state.tokens.push({
      type: "footnote_reference_close",
      level: --state.level
    });
    return true;
  }
  function heading(state, startLine, endLine, silent) {
    var ch, level, tmp, pos = state.bMarks[startLine] + state.tShift[startLine], max = state.eMarks[startLine];
    if (pos >= max) {
      return false;
    }
    ch = state.src.charCodeAt(pos);
    if (ch !== 35 || pos >= max) {
      return false;
    }
    level = 1;
    ch = state.src.charCodeAt(++pos);
    while (ch === 35 && pos < max && level <= 6) {
      level++;
      ch = state.src.charCodeAt(++pos);
    }
    if (level > 6 || pos < max && ch !== 32) {
      return false;
    }
    if (silent) {
      return true;
    }
    max = state.skipCharsBack(max, 32, pos);
    tmp = state.skipCharsBack(max, 35, pos);
    if (tmp > pos && state.src.charCodeAt(tmp - 1) === 32) {
      max = tmp;
    }
    state.line = startLine + 1;
    state.tokens.push({
      type: "heading_open",
      hLevel: level,
      lines: [startLine, state.line],
      level: state.level
    });
    if (pos < max) {
      state.tokens.push({
        type: "inline",
        content: state.src.slice(pos, max).trim(),
        level: state.level + 1,
        lines: [startLine, state.line],
        children: []
      });
    }
    state.tokens.push({ type: "heading_close", hLevel: level, level: state.level });
    return true;
  }
  function lheading(state, startLine, endLine) {
    var marker, pos, max, next = startLine + 1;
    if (next >= endLine) {
      return false;
    }
    if (state.tShift[next] < state.blkIndent) {
      return false;
    }
    if (state.tShift[next] - state.blkIndent > 3) {
      return false;
    }
    pos = state.bMarks[next] + state.tShift[next];
    max = state.eMarks[next];
    if (pos >= max) {
      return false;
    }
    marker = state.src.charCodeAt(pos);
    if (marker !== 45 && marker !== 61) {
      return false;
    }
    pos = state.skipChars(pos, marker);
    pos = state.skipSpaces(pos);
    if (pos < max) {
      return false;
    }
    pos = state.bMarks[startLine] + state.tShift[startLine];
    state.line = next + 1;
    state.tokens.push({
      type: "heading_open",
      hLevel: marker === 61 ? 1 : 2,
      lines: [startLine, state.line],
      level: state.level
    });
    state.tokens.push({
      type: "inline",
      content: state.src.slice(pos, state.eMarks[startLine]).trim(),
      level: state.level + 1,
      lines: [startLine, state.line - 1],
      children: []
    });
    state.tokens.push({
      type: "heading_close",
      hLevel: marker === 61 ? 1 : 2,
      level: state.level
    });
    return true;
  }
  var html_blocks = {};
  [
    "article",
    "aside",
    "button",
    "blockquote",
    "body",
    "canvas",
    "caption",
    "col",
    "colgroup",
    "dd",
    "div",
    "dl",
    "dt",
    "embed",
    "fieldset",
    "figcaption",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "header",
    "hgroup",
    "hr",
    "iframe",
    "li",
    "map",
    "object",
    "ol",
    "output",
    "p",
    "pre",
    "progress",
    "script",
    "section",
    "style",
    "table",
    "tbody",
    "td",
    "textarea",
    "tfoot",
    "th",
    "tr",
    "thead",
    "ul",
    "video"
  ].forEach(function(name2) {
    html_blocks[name2] = true;
  });
  var HTML_TAG_OPEN_RE = /^<([a-zA-Z]{1,15})[\s\/>]/;
  var HTML_TAG_CLOSE_RE = /^<\/([a-zA-Z]{1,15})[\s>]/;
  function isLetter$1(ch) {
    var lc = ch | 32;
    return lc >= 97 && lc <= 122;
  }
  function htmlblock(state, startLine, endLine, silent) {
    var ch, match, nextLine, pos = state.bMarks[startLine], max = state.eMarks[startLine], shift = state.tShift[startLine];
    pos += shift;
    if (!state.options.html) {
      return false;
    }
    if (shift > 3 || pos + 2 >= max) {
      return false;
    }
    if (state.src.charCodeAt(pos) !== 60) {
      return false;
    }
    ch = state.src.charCodeAt(pos + 1);
    if (ch === 33 || ch === 63) {
      if (silent) {
        return true;
      }
    } else if (ch === 47 || isLetter$1(ch)) {
      if (ch === 47) {
        match = state.src.slice(pos, max).match(HTML_TAG_CLOSE_RE);
        if (!match) {
          return false;
        }
      } else {
        match = state.src.slice(pos, max).match(HTML_TAG_OPEN_RE);
        if (!match) {
          return false;
        }
      }
      if (html_blocks[match[1].toLowerCase()] !== true) {
        return false;
      }
      if (silent) {
        return true;
      }
    } else {
      return false;
    }
    nextLine = startLine + 1;
    while (nextLine < state.lineMax && !state.isEmpty(nextLine)) {
      nextLine++;
    }
    state.line = nextLine;
    state.tokens.push({
      type: "htmlblock",
      level: state.level,
      lines: [startLine, state.line],
      content: state.getLines(startLine, nextLine, 0, true)
    });
    return true;
  }
  function getLine$1(state, line) {
    var pos = state.bMarks[line] + state.blkIndent, max = state.eMarks[line];
    return state.src.substr(pos, max - pos);
  }
  function table(state, startLine, endLine, silent) {
    var ch, lineText, pos, i2, nextLine, rows, cell, aligns, t, tableLines, tbodyLines;
    if (startLine + 2 > endLine) {
      return false;
    }
    nextLine = startLine + 1;
    if (state.tShift[nextLine] < state.blkIndent) {
      return false;
    }
    pos = state.bMarks[nextLine] + state.tShift[nextLine];
    if (pos >= state.eMarks[nextLine]) {
      return false;
    }
    ch = state.src.charCodeAt(pos);
    if (ch !== 124 && ch !== 45 && ch !== 58) {
      return false;
    }
    lineText = getLine$1(state, startLine + 1);
    if (!/^[-:| ]+$/.test(lineText)) {
      return false;
    }
    rows = lineText.split("|");
    if (rows <= 2) {
      return false;
    }
    aligns = [];
    for (i2 = 0; i2 < rows.length; i2++) {
      t = rows[i2].trim();
      if (!t) {
        if (i2 === 0 || i2 === rows.length - 1) {
          continue;
        } else {
          return false;
        }
      }
      if (!/^:?-+:?$/.test(t)) {
        return false;
      }
      if (t.charCodeAt(t.length - 1) === 58) {
        aligns.push(t.charCodeAt(0) === 58 ? "center" : "right");
      } else if (t.charCodeAt(0) === 58) {
        aligns.push("left");
      } else {
        aligns.push("");
      }
    }
    lineText = getLine$1(state, startLine).trim();
    if (lineText.indexOf("|") === -1) {
      return false;
    }
    rows = lineText.replace(/^\||\|$/g, "").split("|");
    if (aligns.length !== rows.length) {
      return false;
    }
    if (silent) {
      return true;
    }
    state.tokens.push({
      type: "table_open",
      lines: tableLines = [startLine, 0],
      level: state.level++
    });
    state.tokens.push({
      type: "thead_open",
      lines: [startLine, startLine + 1],
      level: state.level++
    });
    state.tokens.push({
      type: "tr_open",
      lines: [startLine, startLine + 1],
      level: state.level++
    });
    for (i2 = 0; i2 < rows.length; i2++) {
      state.tokens.push({
        type: "th_open",
        align: aligns[i2],
        lines: [startLine, startLine + 1],
        level: state.level++
      });
      state.tokens.push({
        type: "inline",
        content: rows[i2].trim(),
        lines: [startLine, startLine + 1],
        level: state.level,
        children: []
      });
      state.tokens.push({ type: "th_close", level: --state.level });
    }
    state.tokens.push({ type: "tr_close", level: --state.level });
    state.tokens.push({ type: "thead_close", level: --state.level });
    state.tokens.push({
      type: "tbody_open",
      lines: tbodyLines = [startLine + 2, 0],
      level: state.level++
    });
    for (nextLine = startLine + 2; nextLine < endLine; nextLine++) {
      if (state.tShift[nextLine] < state.blkIndent) {
        break;
      }
      lineText = getLine$1(state, nextLine).trim();
      if (lineText.indexOf("|") === -1) {
        break;
      }
      rows = lineText.replace(/^\||\|$/g, "").split("|");
      state.tokens.push({ type: "tr_open", level: state.level++ });
      for (i2 = 0; i2 < rows.length; i2++) {
        state.tokens.push({ type: "td_open", align: aligns[i2], level: state.level++ });
        cell = rows[i2].substring(
          rows[i2].charCodeAt(0) === 124 ? 1 : 0,
          rows[i2].charCodeAt(rows[i2].length - 1) === 124 ? rows[i2].length - 1 : rows[i2].length
        ).trim();
        state.tokens.push({
          type: "inline",
          content: cell,
          level: state.level,
          children: []
        });
        state.tokens.push({ type: "td_close", level: --state.level });
      }
      state.tokens.push({ type: "tr_close", level: --state.level });
    }
    state.tokens.push({ type: "tbody_close", level: --state.level });
    state.tokens.push({ type: "table_close", level: --state.level });
    tableLines[1] = tbodyLines[1] = nextLine;
    state.line = nextLine;
    return true;
  }
  function skipMarker(state, line) {
    var pos, marker, start = state.bMarks[line] + state.tShift[line], max = state.eMarks[line];
    if (start >= max) {
      return -1;
    }
    marker = state.src.charCodeAt(start++);
    if (marker !== 126 && marker !== 58) {
      return -1;
    }
    pos = state.skipSpaces(start);
    if (start === pos) {
      return -1;
    }
    if (pos >= max) {
      return -1;
    }
    return pos;
  }
  function markTightParagraphs$1(state, idx) {
    var i2, l, level = state.level + 2;
    for (i2 = idx + 2, l = state.tokens.length - 2; i2 < l; i2++) {
      if (state.tokens[i2].level === level && state.tokens[i2].type === "paragraph_open") {
        state.tokens[i2 + 2].tight = true;
        state.tokens[i2].tight = true;
        i2 += 2;
      }
    }
  }
  function deflist(state, startLine, endLine, silent) {
    var contentStart, ddLine, dtLine, itemLines, listLines, listTokIdx, nextLine, oldIndent, oldDDIndent, oldParentType, oldTShift, oldTight, prevEmptyEnd, tight;
    if (silent) {
      if (state.ddIndent < 0) {
        return false;
      }
      return skipMarker(state, startLine) >= 0;
    }
    nextLine = startLine + 1;
    if (state.isEmpty(nextLine)) {
      if (++nextLine > endLine) {
        return false;
      }
    }
    if (state.tShift[nextLine] < state.blkIndent) {
      return false;
    }
    contentStart = skipMarker(state, nextLine);
    if (contentStart < 0) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    listTokIdx = state.tokens.length;
    state.tokens.push({
      type: "dl_open",
      lines: listLines = [startLine, 0],
      level: state.level++
    });
    dtLine = startLine;
    ddLine = nextLine;
    OUTER:
      for (; ; ) {
        tight = true;
        prevEmptyEnd = false;
        state.tokens.push({
          type: "dt_open",
          lines: [dtLine, dtLine],
          level: state.level++
        });
        state.tokens.push({
          type: "inline",
          content: state.getLines(dtLine, dtLine + 1, state.blkIndent, false).trim(),
          level: state.level + 1,
          lines: [dtLine, dtLine],
          children: []
        });
        state.tokens.push({
          type: "dt_close",
          level: --state.level
        });
        for (; ; ) {
          state.tokens.push({
            type: "dd_open",
            lines: itemLines = [nextLine, 0],
            level: state.level++
          });
          oldTight = state.tight;
          oldDDIndent = state.ddIndent;
          oldIndent = state.blkIndent;
          oldTShift = state.tShift[ddLine];
          oldParentType = state.parentType;
          state.blkIndent = state.ddIndent = state.tShift[ddLine] + 2;
          state.tShift[ddLine] = contentStart - state.bMarks[ddLine];
          state.tight = true;
          state.parentType = "deflist";
          state.parser.tokenize(state, ddLine, endLine, true);
          if (!state.tight || prevEmptyEnd) {
            tight = false;
          }
          prevEmptyEnd = state.line - ddLine > 1 && state.isEmpty(state.line - 1);
          state.tShift[ddLine] = oldTShift;
          state.tight = oldTight;
          state.parentType = oldParentType;
          state.blkIndent = oldIndent;
          state.ddIndent = oldDDIndent;
          state.tokens.push({
            type: "dd_close",
            level: --state.level
          });
          itemLines[1] = nextLine = state.line;
          if (nextLine >= endLine) {
            break OUTER;
          }
          if (state.tShift[nextLine] < state.blkIndent) {
            break OUTER;
          }
          contentStart = skipMarker(state, nextLine);
          if (contentStart < 0) {
            break;
          }
          ddLine = nextLine;
        }
        if (nextLine >= endLine) {
          break;
        }
        dtLine = nextLine;
        if (state.isEmpty(dtLine)) {
          break;
        }
        if (state.tShift[dtLine] < state.blkIndent) {
          break;
        }
        ddLine = dtLine + 1;
        if (ddLine >= endLine) {
          break;
        }
        if (state.isEmpty(ddLine)) {
          ddLine++;
        }
        if (ddLine >= endLine) {
          break;
        }
        if (state.tShift[ddLine] < state.blkIndent) {
          break;
        }
        contentStart = skipMarker(state, ddLine);
        if (contentStart < 0) {
          break;
        }
      }
    state.tokens.push({
      type: "dl_close",
      level: --state.level
    });
    listLines[1] = nextLine;
    state.line = nextLine;
    if (tight) {
      markTightParagraphs$1(state, listTokIdx);
    }
    return true;
  }
  function paragraph(state, startLine) {
    var endLine, content, terminate, i2, l, nextLine = startLine + 1, terminatorRules;
    endLine = state.lineMax;
    if (nextLine < endLine && !state.isEmpty(nextLine)) {
      terminatorRules = state.parser.ruler.getRules("paragraph");
      for (; nextLine < endLine && !state.isEmpty(nextLine); nextLine++) {
        if (state.tShift[nextLine] - state.blkIndent > 3) {
          continue;
        }
        terminate = false;
        for (i2 = 0, l = terminatorRules.length; i2 < l; i2++) {
          if (terminatorRules[i2](state, nextLine, endLine, true)) {
            terminate = true;
            break;
          }
        }
        if (terminate) {
          break;
        }
      }
    }
    content = state.getLines(startLine, nextLine, state.blkIndent, false).trim();
    state.line = nextLine;
    if (content.length) {
      state.tokens.push({
        type: "paragraph_open",
        tight: false,
        lines: [startLine, state.line],
        level: state.level
      });
      state.tokens.push({
        type: "inline",
        content,
        level: state.level + 1,
        lines: [startLine, state.line],
        children: []
      });
      state.tokens.push({
        type: "paragraph_close",
        tight: false,
        level: state.level
      });
    }
    return true;
  }
  var _rules$1 = [
    ["code", code],
    ["fences", fences, ["paragraph", "blockquote", "list"]],
    ["blockquote", blockquote, ["paragraph", "blockquote", "list"]],
    ["hr", hr, ["paragraph", "blockquote", "list"]],
    ["list", list, ["paragraph", "blockquote"]],
    ["footnote", footnote, ["paragraph"]],
    ["heading", heading, ["paragraph", "blockquote"]],
    ["lheading", lheading],
    ["htmlblock", htmlblock, ["paragraph", "blockquote"]],
    ["table", table, ["paragraph"]],
    ["deflist", deflist, ["paragraph"]],
    ["paragraph", paragraph]
  ];
  function ParserBlock() {
    this.ruler = new Ruler();
    for (var i2 = 0; i2 < _rules$1.length; i2++) {
      this.ruler.push(_rules$1[i2][0], _rules$1[i2][1], {
        alt: (_rules$1[i2][2] || []).slice()
      });
    }
  }
  ParserBlock.prototype.tokenize = function(state, startLine, endLine) {
    var rules2 = this.ruler.getRules("");
    var len = rules2.length;
    var line = startLine;
    var hasEmptyLines = false;
    var ok, i2;
    while (line < endLine) {
      state.line = line = state.skipEmptyLines(line);
      if (line >= endLine) {
        break;
      }
      if (state.tShift[line] < state.blkIndent) {
        break;
      }
      for (i2 = 0; i2 < len; i2++) {
        ok = rules2[i2](state, line, endLine, false);
        if (ok) {
          break;
        }
      }
      state.tight = !hasEmptyLines;
      if (state.isEmpty(state.line - 1)) {
        hasEmptyLines = true;
      }
      line = state.line;
      if (line < endLine && state.isEmpty(line)) {
        hasEmptyLines = true;
        line++;
        if (line < endLine && state.parentType === "list" && state.isEmpty(line)) {
          break;
        }
        state.line = line;
      }
    }
  };
  var TABS_SCAN_RE = /[\n\t]/g;
  var NEWLINES_RE = /\r[\n\u0085]|[\u2424\u2028\u0085]/g;
  var SPACES_RE = /\u00a0/g;
  ParserBlock.prototype.parse = function(str2, options, env, outTokens) {
    var state, lineStart = 0, lastTabPos = 0;
    if (!str2) {
      return [];
    }
    str2 = str2.replace(SPACES_RE, " ");
    str2 = str2.replace(NEWLINES_RE, "\n");
    if (str2.indexOf("	") >= 0) {
      str2 = str2.replace(TABS_SCAN_RE, function(match, offset) {
        var result;
        if (str2.charCodeAt(offset) === 10) {
          lineStart = offset + 1;
          lastTabPos = 0;
          return match;
        }
        result = "    ".slice((offset - lineStart - lastTabPos) % 4);
        lastTabPos = offset - lineStart + 1;
        return result;
      });
    }
    state = new StateBlock(str2, this, options, env, outTokens);
    this.tokenize(state, state.line, state.lineMax);
  };
  function isTerminatorChar(ch) {
    switch (ch) {
      case 10:
      case 92:
      case 96:
      case 42:
      case 95:
      case 94:
      case 91:
      case 93:
      case 33:
      case 38:
      case 60:
      case 62:
      case 123:
      case 125:
      case 36:
      case 37:
      case 64:
      case 126:
      case 43:
      case 61:
      case 58:
        return true;
      default:
        return false;
    }
  }
  function text(state, silent) {
    var pos = state.pos;
    while (pos < state.posMax && !isTerminatorChar(state.src.charCodeAt(pos))) {
      pos++;
    }
    if (pos === state.pos) {
      return false;
    }
    if (!silent) {
      state.pending += state.src.slice(state.pos, pos);
    }
    state.pos = pos;
    return true;
  }
  function newline(state, silent) {
    var pmax, max, pos = state.pos;
    if (state.src.charCodeAt(pos) !== 10) {
      return false;
    }
    pmax = state.pending.length - 1;
    max = state.posMax;
    if (!silent) {
      if (pmax >= 0 && state.pending.charCodeAt(pmax) === 32) {
        if (pmax >= 1 && state.pending.charCodeAt(pmax - 1) === 32) {
          for (var i2 = pmax - 2; i2 >= 0; i2--) {
            if (state.pending.charCodeAt(i2) !== 32) {
              state.pending = state.pending.substring(0, i2 + 1);
              break;
            }
          }
          state.push({
            type: "hardbreak",
            level: state.level
          });
        } else {
          state.pending = state.pending.slice(0, -1);
          state.push({
            type: "softbreak",
            level: state.level
          });
        }
      } else {
        state.push({
          type: "softbreak",
          level: state.level
        });
      }
    }
    pos++;
    while (pos < max && state.src.charCodeAt(pos) === 32) {
      pos++;
    }
    state.pos = pos;
    return true;
  }
  var ESCAPED = [];
  for (var i$1 = 0; i$1 < 256; i$1++) {
    ESCAPED.push(0);
  }
  "\\!\"#$%&'()*+,./:;<=>?@[]^_`{|}~-".split("").forEach(function(ch) {
    ESCAPED[ch.charCodeAt(0)] = 1;
  });
  function escape(state, silent) {
    var ch, pos = state.pos, max = state.posMax;
    if (state.src.charCodeAt(pos) !== 92) {
      return false;
    }
    pos++;
    if (pos < max) {
      ch = state.src.charCodeAt(pos);
      if (ch < 256 && ESCAPED[ch] !== 0) {
        if (!silent) {
          state.pending += state.src[pos];
        }
        state.pos += 2;
        return true;
      }
      if (ch === 10) {
        if (!silent) {
          state.push({
            type: "hardbreak",
            level: state.level
          });
        }
        pos++;
        while (pos < max && state.src.charCodeAt(pos) === 32) {
          pos++;
        }
        state.pos = pos;
        return true;
      }
    }
    if (!silent) {
      state.pending += "\\";
    }
    state.pos++;
    return true;
  }
  function backticks(state, silent) {
    var start, max, marker, matchStart, matchEnd, pos = state.pos, ch = state.src.charCodeAt(pos);
    if (ch !== 96) {
      return false;
    }
    start = pos;
    pos++;
    max = state.posMax;
    while (pos < max && state.src.charCodeAt(pos) === 96) {
      pos++;
    }
    marker = state.src.slice(start, pos);
    matchStart = matchEnd = pos;
    while ((matchStart = state.src.indexOf("`", matchEnd)) !== -1) {
      matchEnd = matchStart + 1;
      while (matchEnd < max && state.src.charCodeAt(matchEnd) === 96) {
        matchEnd++;
      }
      if (matchEnd - matchStart === marker.length) {
        if (!silent) {
          state.push({
            type: "code",
            content: state.src.slice(pos, matchStart).replace(/[ \n]+/g, " ").trim(),
            block: false,
            level: state.level
          });
        }
        state.pos = matchEnd;
        return true;
      }
    }
    if (!silent) {
      state.pending += marker;
    }
    state.pos += marker.length;
    return true;
  }
  function del(state, silent) {
    var found, pos, stack, max = state.posMax, start = state.pos, lastChar, nextChar;
    if (state.src.charCodeAt(start) !== 126) {
      return false;
    }
    if (silent) {
      return false;
    }
    if (start + 4 >= max) {
      return false;
    }
    if (state.src.charCodeAt(start + 1) !== 126) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;
    nextChar = state.src.charCodeAt(start + 2);
    if (lastChar === 126) {
      return false;
    }
    if (nextChar === 126) {
      return false;
    }
    if (nextChar === 32 || nextChar === 10) {
      return false;
    }
    pos = start + 2;
    while (pos < max && state.src.charCodeAt(pos) === 126) {
      pos++;
    }
    if (pos > start + 3) {
      state.pos += pos - start;
      if (!silent) {
        state.pending += state.src.slice(start, pos);
      }
      return true;
    }
    state.pos = start + 2;
    stack = 1;
    while (state.pos + 1 < max) {
      if (state.src.charCodeAt(state.pos) === 126) {
        if (state.src.charCodeAt(state.pos + 1) === 126) {
          lastChar = state.src.charCodeAt(state.pos - 1);
          nextChar = state.pos + 2 < max ? state.src.charCodeAt(state.pos + 2) : -1;
          if (nextChar !== 126 && lastChar !== 126) {
            if (lastChar !== 32 && lastChar !== 10) {
              stack--;
            } else if (nextChar !== 32 && nextChar !== 10) {
              stack++;
            }
            if (stack <= 0) {
              found = true;
              break;
            }
          }
        }
      }
      state.parser.skipToken(state);
    }
    if (!found) {
      state.pos = start;
      return false;
    }
    state.posMax = state.pos;
    state.pos = start + 2;
    if (!silent) {
      state.push({ type: "del_open", level: state.level++ });
      state.parser.tokenize(state);
      state.push({ type: "del_close", level: --state.level });
    }
    state.pos = state.posMax + 2;
    state.posMax = max;
    return true;
  }
  function ins(state, silent) {
    var found, pos, stack, max = state.posMax, start = state.pos, lastChar, nextChar;
    if (state.src.charCodeAt(start) !== 43) {
      return false;
    }
    if (silent) {
      return false;
    }
    if (start + 4 >= max) {
      return false;
    }
    if (state.src.charCodeAt(start + 1) !== 43) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;
    nextChar = state.src.charCodeAt(start + 2);
    if (lastChar === 43) {
      return false;
    }
    if (nextChar === 43) {
      return false;
    }
    if (nextChar === 32 || nextChar === 10) {
      return false;
    }
    pos = start + 2;
    while (pos < max && state.src.charCodeAt(pos) === 43) {
      pos++;
    }
    if (pos !== start + 2) {
      state.pos += pos - start;
      if (!silent) {
        state.pending += state.src.slice(start, pos);
      }
      return true;
    }
    state.pos = start + 2;
    stack = 1;
    while (state.pos + 1 < max) {
      if (state.src.charCodeAt(state.pos) === 43) {
        if (state.src.charCodeAt(state.pos + 1) === 43) {
          lastChar = state.src.charCodeAt(state.pos - 1);
          nextChar = state.pos + 2 < max ? state.src.charCodeAt(state.pos + 2) : -1;
          if (nextChar !== 43 && lastChar !== 43) {
            if (lastChar !== 32 && lastChar !== 10) {
              stack--;
            } else if (nextChar !== 32 && nextChar !== 10) {
              stack++;
            }
            if (stack <= 0) {
              found = true;
              break;
            }
          }
        }
      }
      state.parser.skipToken(state);
    }
    if (!found) {
      state.pos = start;
      return false;
    }
    state.posMax = state.pos;
    state.pos = start + 2;
    if (!silent) {
      state.push({ type: "ins_open", level: state.level++ });
      state.parser.tokenize(state);
      state.push({ type: "ins_close", level: --state.level });
    }
    state.pos = state.posMax + 2;
    state.posMax = max;
    return true;
  }
  function mark(state, silent) {
    var found, pos, stack, max = state.posMax, start = state.pos, lastChar, nextChar;
    if (state.src.charCodeAt(start) !== 61) {
      return false;
    }
    if (silent) {
      return false;
    }
    if (start + 4 >= max) {
      return false;
    }
    if (state.src.charCodeAt(start + 1) !== 61) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;
    nextChar = state.src.charCodeAt(start + 2);
    if (lastChar === 61) {
      return false;
    }
    if (nextChar === 61) {
      return false;
    }
    if (nextChar === 32 || nextChar === 10) {
      return false;
    }
    pos = start + 2;
    while (pos < max && state.src.charCodeAt(pos) === 61) {
      pos++;
    }
    if (pos !== start + 2) {
      state.pos += pos - start;
      if (!silent) {
        state.pending += state.src.slice(start, pos);
      }
      return true;
    }
    state.pos = start + 2;
    stack = 1;
    while (state.pos + 1 < max) {
      if (state.src.charCodeAt(state.pos) === 61) {
        if (state.src.charCodeAt(state.pos + 1) === 61) {
          lastChar = state.src.charCodeAt(state.pos - 1);
          nextChar = state.pos + 2 < max ? state.src.charCodeAt(state.pos + 2) : -1;
          if (nextChar !== 61 && lastChar !== 61) {
            if (lastChar !== 32 && lastChar !== 10) {
              stack--;
            } else if (nextChar !== 32 && nextChar !== 10) {
              stack++;
            }
            if (stack <= 0) {
              found = true;
              break;
            }
          }
        }
      }
      state.parser.skipToken(state);
    }
    if (!found) {
      state.pos = start;
      return false;
    }
    state.posMax = state.pos;
    state.pos = start + 2;
    if (!silent) {
      state.push({ type: "mark_open", level: state.level++ });
      state.parser.tokenize(state);
      state.push({ type: "mark_close", level: --state.level });
    }
    state.pos = state.posMax + 2;
    state.posMax = max;
    return true;
  }
  function isAlphaNum(code2) {
    return code2 >= 48 && code2 <= 57 || code2 >= 65 && code2 <= 90 || code2 >= 97 && code2 <= 122;
  }
  function scanDelims(state, start) {
    var pos = start, lastChar, nextChar, count, can_open = true, can_close = true, max = state.posMax, marker = state.src.charCodeAt(start);
    lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;
    while (pos < max && state.src.charCodeAt(pos) === marker) {
      pos++;
    }
    if (pos >= max) {
      can_open = false;
    }
    count = pos - start;
    if (count >= 4) {
      can_open = can_close = false;
    } else {
      nextChar = pos < max ? state.src.charCodeAt(pos) : -1;
      if (nextChar === 32 || nextChar === 10) {
        can_open = false;
      }
      if (lastChar === 32 || lastChar === 10) {
        can_close = false;
      }
      if (marker === 95) {
        if (isAlphaNum(lastChar)) {
          can_open = false;
        }
        if (isAlphaNum(nextChar)) {
          can_close = false;
        }
      }
    }
    return {
      can_open,
      can_close,
      delims: count
    };
  }
  function emphasis(state, silent) {
    var startCount, count, found, oldCount, newCount, stack, res, max = state.posMax, start = state.pos, marker = state.src.charCodeAt(start);
    if (marker !== 95 && marker !== 42) {
      return false;
    }
    if (silent) {
      return false;
    }
    res = scanDelims(state, start);
    startCount = res.delims;
    if (!res.can_open) {
      state.pos += startCount;
      if (!silent) {
        state.pending += state.src.slice(start, state.pos);
      }
      return true;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    state.pos = start + startCount;
    stack = [startCount];
    while (state.pos < max) {
      if (state.src.charCodeAt(state.pos) === marker) {
        res = scanDelims(state, state.pos);
        count = res.delims;
        if (res.can_close) {
          oldCount = stack.pop();
          newCount = count;
          while (oldCount !== newCount) {
            if (newCount < oldCount) {
              stack.push(oldCount - newCount);
              break;
            }
            newCount -= oldCount;
            if (stack.length === 0) {
              break;
            }
            state.pos += oldCount;
            oldCount = stack.pop();
          }
          if (stack.length === 0) {
            startCount = oldCount;
            found = true;
            break;
          }
          state.pos += count;
          continue;
        }
        if (res.can_open) {
          stack.push(count);
        }
        state.pos += count;
        continue;
      }
      state.parser.skipToken(state);
    }
    if (!found) {
      state.pos = start;
      return false;
    }
    state.posMax = state.pos;
    state.pos = start + startCount;
    if (!silent) {
      if (startCount === 2 || startCount === 3) {
        state.push({ type: "strong_open", level: state.level++ });
      }
      if (startCount === 1 || startCount === 3) {
        state.push({ type: "em_open", level: state.level++ });
      }
      state.parser.tokenize(state);
      if (startCount === 1 || startCount === 3) {
        state.push({ type: "em_close", level: --state.level });
      }
      if (startCount === 2 || startCount === 3) {
        state.push({ type: "strong_close", level: --state.level });
      }
    }
    state.pos = state.posMax + startCount;
    state.posMax = max;
    return true;
  }
  var UNESCAPE_RE = /\\([ \\!"#$%&'()*+,.\/:;<=>?@[\]^_`{|}~-])/g;
  function sub(state, silent) {
    var found, content, max = state.posMax, start = state.pos;
    if (state.src.charCodeAt(start) !== 126) {
      return false;
    }
    if (silent) {
      return false;
    }
    if (start + 2 >= max) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    state.pos = start + 1;
    while (state.pos < max) {
      if (state.src.charCodeAt(state.pos) === 126) {
        found = true;
        break;
      }
      state.parser.skipToken(state);
    }
    if (!found || start + 1 === state.pos) {
      state.pos = start;
      return false;
    }
    content = state.src.slice(start + 1, state.pos);
    if (content.match(/(^|[^\\])(\\\\)*\s/)) {
      state.pos = start;
      return false;
    }
    state.posMax = state.pos;
    state.pos = start + 1;
    if (!silent) {
      state.push({
        type: "sub",
        level: state.level,
        content: content.replace(UNESCAPE_RE, "$1")
      });
    }
    state.pos = state.posMax + 1;
    state.posMax = max;
    return true;
  }
  var UNESCAPE_RE$1 = /\\([ \\!"#$%&'()*+,.\/:;<=>?@[\]^_`{|}~-])/g;
  function sup(state, silent) {
    var found, content, max = state.posMax, start = state.pos;
    if (state.src.charCodeAt(start) !== 94) {
      return false;
    }
    if (silent) {
      return false;
    }
    if (start + 2 >= max) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    state.pos = start + 1;
    while (state.pos < max) {
      if (state.src.charCodeAt(state.pos) === 94) {
        found = true;
        break;
      }
      state.parser.skipToken(state);
    }
    if (!found || start + 1 === state.pos) {
      state.pos = start;
      return false;
    }
    content = state.src.slice(start + 1, state.pos);
    if (content.match(/(^|[^\\])(\\\\)*\s/)) {
      state.pos = start;
      return false;
    }
    state.posMax = state.pos;
    state.pos = start + 1;
    if (!silent) {
      state.push({
        type: "sup",
        level: state.level,
        content: content.replace(UNESCAPE_RE$1, "$1")
      });
    }
    state.pos = state.posMax + 1;
    state.posMax = max;
    return true;
  }
  function links(state, silent) {
    var labelStart, labelEnd, label, href, title, pos, ref, code2, isImage = false, oldPos = state.pos, max = state.posMax, start = state.pos, marker = state.src.charCodeAt(start);
    if (marker === 33) {
      isImage = true;
      marker = state.src.charCodeAt(++start);
    }
    if (marker !== 91) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    labelStart = start + 1;
    labelEnd = parseLinkLabel(state, start);
    if (labelEnd < 0) {
      return false;
    }
    pos = labelEnd + 1;
    if (pos < max && state.src.charCodeAt(pos) === 40) {
      pos++;
      for (; pos < max; pos++) {
        code2 = state.src.charCodeAt(pos);
        if (code2 !== 32 && code2 !== 10) {
          break;
        }
      }
      if (pos >= max) {
        return false;
      }
      start = pos;
      if (parseLinkDestination(state, pos)) {
        href = state.linkContent;
        pos = state.pos;
      } else {
        href = "";
      }
      start = pos;
      for (; pos < max; pos++) {
        code2 = state.src.charCodeAt(pos);
        if (code2 !== 32 && code2 !== 10) {
          break;
        }
      }
      if (pos < max && start !== pos && parseLinkTitle(state, pos)) {
        title = state.linkContent;
        pos = state.pos;
        for (; pos < max; pos++) {
          code2 = state.src.charCodeAt(pos);
          if (code2 !== 32 && code2 !== 10) {
            break;
          }
        }
      } else {
        title = "";
      }
      if (pos >= max || state.src.charCodeAt(pos) !== 41) {
        state.pos = oldPos;
        return false;
      }
      pos++;
    } else {
      if (state.linkLevel > 0) {
        return false;
      }
      for (; pos < max; pos++) {
        code2 = state.src.charCodeAt(pos);
        if (code2 !== 32 && code2 !== 10) {
          break;
        }
      }
      if (pos < max && state.src.charCodeAt(pos) === 91) {
        start = pos + 1;
        pos = parseLinkLabel(state, pos);
        if (pos >= 0) {
          label = state.src.slice(start, pos++);
        } else {
          pos = start - 1;
        }
      }
      if (!label) {
        if (typeof label === "undefined") {
          pos = labelEnd + 1;
        }
        label = state.src.slice(labelStart, labelEnd);
      }
      ref = state.env.references[normalizeReference(label)];
      if (!ref) {
        state.pos = oldPos;
        return false;
      }
      href = ref.href;
      title = ref.title;
    }
    if (!silent) {
      state.pos = labelStart;
      state.posMax = labelEnd;
      if (isImage) {
        state.push({
          type: "image",
          src: href,
          title,
          alt: state.src.substr(labelStart, labelEnd - labelStart),
          level: state.level
        });
      } else {
        state.push({
          type: "link_open",
          href,
          title,
          level: state.level++
        });
        state.linkLevel++;
        state.parser.tokenize(state);
        state.linkLevel--;
        state.push({ type: "link_close", level: --state.level });
      }
    }
    state.pos = pos;
    state.posMax = max;
    return true;
  }
  function footnote_inline(state, silent) {
    var labelStart, labelEnd, footnoteId, oldLength, max = state.posMax, start = state.pos;
    if (start + 2 >= max) {
      return false;
    }
    if (state.src.charCodeAt(start) !== 94) {
      return false;
    }
    if (state.src.charCodeAt(start + 1) !== 91) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    labelStart = start + 2;
    labelEnd = parseLinkLabel(state, start + 1);
    if (labelEnd < 0) {
      return false;
    }
    if (!silent) {
      if (!state.env.footnotes) {
        state.env.footnotes = {};
      }
      if (!state.env.footnotes.list) {
        state.env.footnotes.list = [];
      }
      footnoteId = state.env.footnotes.list.length;
      state.pos = labelStart;
      state.posMax = labelEnd;
      state.push({
        type: "footnote_ref",
        id: footnoteId,
        level: state.level
      });
      state.linkLevel++;
      oldLength = state.tokens.length;
      state.parser.tokenize(state);
      state.env.footnotes.list[footnoteId] = { tokens: state.tokens.splice(oldLength) };
      state.linkLevel--;
    }
    state.pos = labelEnd + 1;
    state.posMax = max;
    return true;
  }
  function footnote_ref(state, silent) {
    var label, pos, footnoteId, footnoteSubId, max = state.posMax, start = state.pos;
    if (start + 3 > max) {
      return false;
    }
    if (!state.env.footnotes || !state.env.footnotes.refs) {
      return false;
    }
    if (state.src.charCodeAt(start) !== 91) {
      return false;
    }
    if (state.src.charCodeAt(start + 1) !== 94) {
      return false;
    }
    if (state.level >= state.options.maxNesting) {
      return false;
    }
    for (pos = start + 2; pos < max; pos++) {
      if (state.src.charCodeAt(pos) === 32) {
        return false;
      }
      if (state.src.charCodeAt(pos) === 10) {
        return false;
      }
      if (state.src.charCodeAt(pos) === 93) {
        break;
      }
    }
    if (pos === start + 2) {
      return false;
    }
    if (pos >= max) {
      return false;
    }
    pos++;
    label = state.src.slice(start + 2, pos - 1);
    if (typeof state.env.footnotes.refs[":" + label] === "undefined") {
      return false;
    }
    if (!silent) {
      if (!state.env.footnotes.list) {
        state.env.footnotes.list = [];
      }
      if (state.env.footnotes.refs[":" + label] < 0) {
        footnoteId = state.env.footnotes.list.length;
        state.env.footnotes.list[footnoteId] = { label, count: 0 };
        state.env.footnotes.refs[":" + label] = footnoteId;
      } else {
        footnoteId = state.env.footnotes.refs[":" + label];
      }
      footnoteSubId = state.env.footnotes.list[footnoteId].count;
      state.env.footnotes.list[footnoteId].count++;
      state.push({
        type: "footnote_ref",
        id: footnoteId,
        subId: footnoteSubId,
        level: state.level
      });
    }
    state.pos = pos;
    state.posMax = max;
    return true;
  }
  var url_schemas = [
    "coap",
    "doi",
    "javascript",
    "aaa",
    "aaas",
    "about",
    "acap",
    "cap",
    "cid",
    "crid",
    "data",
    "dav",
    "dict",
    "dns",
    "file",
    "ftp",
    "geo",
    "go",
    "gopher",
    "h323",
    "http",
    "https",
    "iax",
    "icap",
    "im",
    "imap",
    "info",
    "ipp",
    "iris",
    "iris.beep",
    "iris.xpc",
    "iris.xpcs",
    "iris.lwz",
    "ldap",
    "mailto",
    "mid",
    "msrp",
    "msrps",
    "mtqp",
    "mupdate",
    "news",
    "nfs",
    "ni",
    "nih",
    "nntp",
    "opaquelocktoken",
    "pop",
    "pres",
    "rtsp",
    "service",
    "session",
    "shttp",
    "sieve",
    "sip",
    "sips",
    "sms",
    "snmp",
    "soap.beep",
    "soap.beeps",
    "tag",
    "tel",
    "telnet",
    "tftp",
    "thismessage",
    "tn3270",
    "tip",
    "tv",
    "urn",
    "vemmi",
    "ws",
    "wss",
    "xcon",
    "xcon-userid",
    "xmlrpc.beep",
    "xmlrpc.beeps",
    "xmpp",
    "z39.50r",
    "z39.50s",
    "adiumxtra",
    "afp",
    "afs",
    "aim",
    "apt",
    "attachment",
    "aw",
    "beshare",
    "bitcoin",
    "bolo",
    "callto",
    "chrome",
    "chrome-extension",
    "com-eventbrite-attendee",
    "content",
    "cvs",
    "dlna-playsingle",
    "dlna-playcontainer",
    "dtn",
    "dvb",
    "ed2k",
    "facetime",
    "feed",
    "finger",
    "fish",
    "gg",
    "git",
    "gizmoproject",
    "gtalk",
    "hcp",
    "icon",
    "ipn",
    "irc",
    "irc6",
    "ircs",
    "itms",
    "jar",
    "jms",
    "keyparc",
    "lastfm",
    "ldaps",
    "magnet",
    "maps",
    "market",
    "message",
    "mms",
    "ms-help",
    "msnim",
    "mumble",
    "mvn",
    "notes",
    "oid",
    "palm",
    "paparazzi",
    "platform",
    "proxy",
    "psyc",
    "query",
    "res",
    "resource",
    "rmi",
    "rsync",
    "rtmp",
    "secondlife",
    "sftp",
    "sgn",
    "skype",
    "smb",
    "soldat",
    "spotify",
    "ssh",
    "steam",
    "svn",
    "teamspeak",
    "things",
    "udp",
    "unreal",
    "ut2004",
    "ventrilo",
    "view-source",
    "webcal",
    "wtai",
    "wyciwyg",
    "xfire",
    "xri",
    "ymsgr"
  ];
  var EMAIL_RE = /^<([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)>/;
  var AUTOLINK_RE = /^<([a-zA-Z.\-]{1,25}):([^<>\x00-\x20]*)>/;
  function autolink(state, silent) {
    var tail, linkMatch, emailMatch, url, fullUrl, pos = state.pos;
    if (state.src.charCodeAt(pos) !== 60) {
      return false;
    }
    tail = state.src.slice(pos);
    if (tail.indexOf(">") < 0) {
      return false;
    }
    linkMatch = tail.match(AUTOLINK_RE);
    if (linkMatch) {
      if (url_schemas.indexOf(linkMatch[1].toLowerCase()) < 0) {
        return false;
      }
      url = linkMatch[0].slice(1, -1);
      fullUrl = normalizeLink(url);
      if (!state.parser.validateLink(url)) {
        return false;
      }
      if (!silent) {
        state.push({
          type: "link_open",
          href: fullUrl,
          level: state.level
        });
        state.push({
          type: "text",
          content: url,
          level: state.level + 1
        });
        state.push({ type: "link_close", level: state.level });
      }
      state.pos += linkMatch[0].length;
      return true;
    }
    emailMatch = tail.match(EMAIL_RE);
    if (emailMatch) {
      url = emailMatch[0].slice(1, -1);
      fullUrl = normalizeLink("mailto:" + url);
      if (!state.parser.validateLink(fullUrl)) {
        return false;
      }
      if (!silent) {
        state.push({
          type: "link_open",
          href: fullUrl,
          level: state.level
        });
        state.push({
          type: "text",
          content: url,
          level: state.level + 1
        });
        state.push({ type: "link_close", level: state.level });
      }
      state.pos += emailMatch[0].length;
      return true;
    }
    return false;
  }
  function replace$1(regex, options) {
    regex = regex.source;
    options = options || "";
    return function self(name2, val) {
      if (!name2) {
        return new RegExp(regex, options);
      }
      val = val.source || val;
      regex = regex.replace(name2, val);
      return self;
    };
  }
  var attr_name = /[a-zA-Z_:][a-zA-Z0-9:._-]*/;
  var unquoted = /[^"'=<>`\x00-\x20]+/;
  var single_quoted = /'[^']*'/;
  var double_quoted = /"[^"]*"/;
  var attr_value = replace$1(/(?:unquoted|single_quoted|double_quoted)/)("unquoted", unquoted)("single_quoted", single_quoted)("double_quoted", double_quoted)();
  var attribute = replace$1(/(?:\s+attr_name(?:\s*=\s*attr_value)?)/)("attr_name", attr_name)("attr_value", attr_value)();
  var open_tag = replace$1(/<[A-Za-z][A-Za-z0-9]*attribute*\s*\/?>/)("attribute", attribute)();
  var close_tag = /<\/[A-Za-z][A-Za-z0-9]*\s*>/;
  var comment = /<!---->|<!--(?:-?[^>-])(?:-?[^-])*-->/;
  var processing = /<[?].*?[?]>/;
  var declaration = /<![A-Z]+\s+[^>]*>/;
  var cdata = /<!\[CDATA\[[\s\S]*?\]\]>/;
  var HTML_TAG_RE = replace$1(/^(?:open_tag|close_tag|comment|processing|declaration|cdata)/)("open_tag", open_tag)("close_tag", close_tag)("comment", comment)("processing", processing)("declaration", declaration)("cdata", cdata)();
  function isLetter$2(ch) {
    var lc = ch | 32;
    return lc >= 97 && lc <= 122;
  }
  function htmltag(state, silent) {
    var ch, match, max, pos = state.pos;
    if (!state.options.html) {
      return false;
    }
    max = state.posMax;
    if (state.src.charCodeAt(pos) !== 60 || pos + 2 >= max) {
      return false;
    }
    ch = state.src.charCodeAt(pos + 1);
    if (ch !== 33 && ch !== 63 && ch !== 47 && !isLetter$2(ch)) {
      return false;
    }
    match = state.src.slice(pos).match(HTML_TAG_RE);
    if (!match) {
      return false;
    }
    if (!silent) {
      state.push({
        type: "htmltag",
        content: state.src.slice(pos, pos + match[0].length),
        level: state.level
      });
    }
    state.pos += match[0].length;
    return true;
  }
  var DIGITAL_RE = /^&#((?:x[a-f0-9]{1,8}|[0-9]{1,8}));/i;
  var NAMED_RE = /^&([a-z][a-z0-9]{1,31});/i;
  function entity(state, silent) {
    var ch, code2, match, pos = state.pos, max = state.posMax;
    if (state.src.charCodeAt(pos) !== 38) {
      return false;
    }
    if (pos + 1 < max) {
      ch = state.src.charCodeAt(pos + 1);
      if (ch === 35) {
        match = state.src.slice(pos).match(DIGITAL_RE);
        if (match) {
          if (!silent) {
            code2 = match[1][0].toLowerCase() === "x" ? parseInt(match[1].slice(1), 16) : parseInt(match[1], 10);
            state.pending += isValidEntityCode(code2) ? fromCodePoint(code2) : fromCodePoint(65533);
          }
          state.pos += match[0].length;
          return true;
        }
      } else {
        match = state.src.slice(pos).match(NAMED_RE);
        if (match) {
          var decoded = decodeEntity(match[1]);
          if (match[1] !== decoded) {
            if (!silent) {
              state.pending += decoded;
            }
            state.pos += match[0].length;
            return true;
          }
        }
      }
    }
    if (!silent) {
      state.pending += "&";
    }
    state.pos++;
    return true;
  }
  var _rules$2 = [
    ["text", text],
    ["newline", newline],
    ["escape", escape],
    ["backticks", backticks],
    ["del", del],
    ["ins", ins],
    ["mark", mark],
    ["emphasis", emphasis],
    ["sub", sub],
    ["sup", sup],
    ["links", links],
    ["footnote_inline", footnote_inline],
    ["footnote_ref", footnote_ref],
    ["autolink", autolink],
    ["htmltag", htmltag],
    ["entity", entity]
  ];
  function ParserInline() {
    this.ruler = new Ruler();
    for (var i2 = 0; i2 < _rules$2.length; i2++) {
      this.ruler.push(_rules$2[i2][0], _rules$2[i2][1]);
    }
    this.validateLink = validateLink;
  }
  ParserInline.prototype.skipToken = function(state) {
    var rules2 = this.ruler.getRules("");
    var len = rules2.length;
    var pos = state.pos;
    var i2, cached_pos;
    if ((cached_pos = state.cacheGet(pos)) > 0) {
      state.pos = cached_pos;
      return;
    }
    for (i2 = 0; i2 < len; i2++) {
      if (rules2[i2](state, true)) {
        state.cacheSet(pos, state.pos);
        return;
      }
    }
    state.pos++;
    state.cacheSet(pos, state.pos);
  };
  ParserInline.prototype.tokenize = function(state) {
    var rules2 = this.ruler.getRules("");
    var len = rules2.length;
    var end = state.posMax;
    var ok, i2;
    while (state.pos < end) {
      for (i2 = 0; i2 < len; i2++) {
        ok = rules2[i2](state, false);
        if (ok) {
          break;
        }
      }
      if (ok) {
        if (state.pos >= end) {
          break;
        }
        continue;
      }
      state.pending += state.src[state.pos++];
    }
    if (state.pending) {
      state.pushPending();
    }
  };
  ParserInline.prototype.parse = function(str2, options, env, outTokens) {
    var state = new StateInline(str2, this, options, env, outTokens);
    this.tokenize(state);
  };
  function validateLink(url) {
    var BAD_PROTOCOLS = ["vbscript", "javascript", "file", "data"];
    var str2 = url.trim().toLowerCase();
    str2 = replaceEntities(str2);
    if (str2.indexOf(":") !== -1 && BAD_PROTOCOLS.indexOf(str2.split(":")[0]) !== -1) {
      return false;
    }
    return true;
  }
  var defaultConfig = {
    options: {
      html: false,
      // Enable HTML tags in source
      xhtmlOut: false,
      // Use '/' to close single tags (<br />)
      breaks: false,
      // Convert '\n' in paragraphs into <br>
      langPrefix: "language-",
      // CSS language prefix for fenced blocks
      linkTarget: "",
      // set target to open link in
      // Enable some language-neutral replacements + quotes beautification
      typographer: false,
      // Double + single quotes replacement pairs, when typographer enabled,
      // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
      quotes: "“”‘’",
      // Highlighter function. Should return escaped HTML,
      // or '' if input not changed
      //
      // function (/*str, lang*/) { return ''; }
      //
      highlight: null,
      maxNesting: 20
      // Internal protection, recursion limit
    },
    components: {
      core: {
        rules: [
          "block",
          "inline",
          "references",
          "replacements",
          "smartquotes",
          "references",
          "abbr2",
          "footnote_tail"
        ]
      },
      block: {
        rules: [
          "blockquote",
          "code",
          "fences",
          "footnote",
          "heading",
          "hr",
          "htmlblock",
          "lheading",
          "list",
          "paragraph",
          "table"
        ]
      },
      inline: {
        rules: [
          "autolink",
          "backticks",
          "del",
          "emphasis",
          "entity",
          "escape",
          "footnote_ref",
          "htmltag",
          "links",
          "newline",
          "text"
        ]
      }
    }
  };
  var fullConfig = {
    options: {
      html: false,
      // Enable HTML tags in source
      xhtmlOut: false,
      // Use '/' to close single tags (<br />)
      breaks: false,
      // Convert '\n' in paragraphs into <br>
      langPrefix: "language-",
      // CSS language prefix for fenced blocks
      linkTarget: "",
      // set target to open link in
      // Enable some language-neutral replacements + quotes beautification
      typographer: false,
      // Double + single quotes replacement pairs, when typographer enabled,
      // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
      quotes: "“”‘’",
      // Highlighter function. Should return escaped HTML,
      // or '' if input not changed
      //
      // function (/*str, lang*/) { return ''; }
      //
      highlight: null,
      maxNesting: 20
      // Internal protection, recursion limit
    },
    components: {
      // Don't restrict core/block/inline rules
      core: {},
      block: {},
      inline: {}
    }
  };
  var commonmarkConfig = {
    options: {
      html: true,
      // Enable HTML tags in source
      xhtmlOut: true,
      // Use '/' to close single tags (<br />)
      breaks: false,
      // Convert '\n' in paragraphs into <br>
      langPrefix: "language-",
      // CSS language prefix for fenced blocks
      linkTarget: "",
      // set target to open link in
      // Enable some language-neutral replacements + quotes beautification
      typographer: false,
      // Double + single quotes replacement pairs, when typographer enabled,
      // and smartquotes on. Set doubles to '«»' for Russian, '„“' for German.
      quotes: "“”‘’",
      // Highlighter function. Should return escaped HTML,
      // or '' if input not changed
      //
      // function (/*str, lang*/) { return ''; }
      //
      highlight: null,
      maxNesting: 20
      // Internal protection, recursion limit
    },
    components: {
      core: {
        rules: [
          "block",
          "inline",
          "references",
          "abbr2"
        ]
      },
      block: {
        rules: [
          "blockquote",
          "code",
          "fences",
          "heading",
          "hr",
          "htmlblock",
          "lheading",
          "list",
          "paragraph"
        ]
      },
      inline: {
        rules: [
          "autolink",
          "backticks",
          "emphasis",
          "entity",
          "escape",
          "htmltag",
          "links",
          "newline",
          "text"
        ]
      }
    }
  };
  var config$2 = {
    "default": defaultConfig,
    "full": fullConfig,
    "commonmark": commonmarkConfig
  };
  function StateCore(instance, str2, env) {
    this.src = str2;
    this.env = env;
    this.options = instance.options;
    this.tokens = [];
    this.inlineMode = false;
    this.inline = instance.inline;
    this.block = instance.block;
    this.renderer = instance.renderer;
    this.typographer = instance.typographer;
  }
  function Remarkable(preset, options) {
    if (typeof preset !== "string") {
      options = preset;
      preset = "default";
    }
    if (options && options.linkify != null) {
      console.warn(
        "linkify option is removed. Use linkify plugin instead:\n\nimport Remarkable from 'remarkable';\nimport linkify from 'remarkable/linkify';\nnew Remarkable().use(linkify)\n"
      );
    }
    this.inline = new ParserInline();
    this.block = new ParserBlock();
    this.core = new Core();
    this.renderer = new Renderer();
    this.ruler = new Ruler();
    this.options = {};
    this.configure(config$2[preset]);
    this.set(options || {});
  }
  Remarkable.prototype.set = function(options) {
    assign(this.options, options);
  };
  Remarkable.prototype.configure = function(presets) {
    var self = this;
    if (!presets) {
      throw new Error("Wrong `remarkable` preset, check name/content");
    }
    if (presets.options) {
      self.set(presets.options);
    }
    if (presets.components) {
      Object.keys(presets.components).forEach(function(name2) {
        if (presets.components[name2].rules) {
          self[name2].ruler.enable(presets.components[name2].rules, true);
        }
      });
    }
  };
  Remarkable.prototype.use = function(plugin2, options) {
    plugin2(this, options);
    return this;
  };
  Remarkable.prototype.parse = function(str2, env) {
    var state = new StateCore(this, str2, env);
    this.core.process(state);
    return state.tokens;
  };
  Remarkable.prototype.render = function(str2, env) {
    env = env || {};
    return this.renderer.render(this.parse(str2, env), this.options, env);
  };
  Remarkable.prototype.parseInline = function(str2, env) {
    var state = new StateCore(this, str2, env);
    state.inlineMode = true;
    this.core.process(state);
    return state.tokens;
  };
  Remarkable.prototype.renderInline = function(str2, env) {
    env = env || {};
    return this.renderer.render(this.parseInline(str2, env), this.options, env);
  };
  const testPath = "npm2url/dist/index.cjs";
  const defaultProviders = {
    jsdelivr: (path) => `https://cdn.jsdelivr.net/npm/${path}`,
    unpkg: (path) => `https://unpkg.com/${path}`
  };
  async function checkUrl(url, signal) {
    const res = await fetch(url, {
      signal
    });
    if (!res.ok) {
      throw res;
    }
    await res.text();
  }
  class UrlBuilder {
    constructor() {
      this.providers = { ...defaultProviders };
      this.provider = "jsdelivr";
    }
    /**
     * Get the fastest provider name.
     * If none of the providers returns a valid response within `timeout`, an error will be thrown.
     */
    async getFastestProvider(timeout = 5e3, path = testPath) {
      const controller = new AbortController();
      let timer = 0;
      try {
        return await new Promise((resolve, reject) => {
          Promise.all(
            Object.entries(this.providers).map(async ([name2, factory]) => {
              try {
                await checkUrl(factory(path), controller.signal);
                resolve(name2);
              } catch {
              }
            })
          ).then(() => reject(new Error("All providers failed")));
          timer = setTimeout(reject, timeout, new Error("Timed out"));
        });
      } finally {
        controller.abort();
        clearTimeout(timer);
      }
    }
    /**
     * Set the current provider to the fastest provider found by `getFastestProvider`.
     */
    async findFastestProvider(timeout, path) {
      this.provider = await this.getFastestProvider(timeout, path);
      return this.provider;
    }
    setProvider(name2, factory) {
      if (factory) {
        this.providers[name2] = factory;
      } else {
        delete this.providers[name2];
      }
    }
    getFullUrl(path, provider = this.provider) {
      if (path.includes("://")) {
        return path;
      }
      const factory = this.providers[provider];
      if (!factory) {
        throw new Error(`Provider ${provider} not found`);
      }
      return factory(path);
    }
  }
  class Hook {
    constructor() {
      this.listeners = [];
    }
    tap(fn) {
      this.listeners.push(fn);
      return () => this.revoke(fn);
    }
    revoke(fn) {
      const i2 = this.listeners.indexOf(fn);
      if (i2 >= 0)
        this.listeners.splice(i2, 1);
    }
    revokeAll() {
      this.listeners.splice(0);
    }
    call(...args) {
      for (const fn of this.listeners) {
        fn(...args);
      }
    }
  }
  const escapeChars = {
    "&": "&amp;",
    "<": "&lt;",
    '"': "&quot;"
  };
  function escapeHtml(html) {
    return html.replace(/[&<"]/g, (m) => escapeChars[m]);
  }
  function escapeScript(content) {
    return content.replace(/<(\/script>)/g, "\\x3c$2");
  }
  function htmlOpen(tagName, attrs) {
    const attrStr = attrs ? Object.entries(attrs).map(([key, value]) => {
      if (value == null || value === false)
        return;
      key = ` ${escapeHtml(key)}`;
      if (value === true)
        return key;
      return `${key}="${escapeHtml(value)}"`;
    }).filter(Boolean).join("") : "";
    return `<${tagName}${attrStr}>`;
  }
  function htmlClose(tagName) {
    return `</${tagName}>`;
  }
  function wrapHtml(tagName, content, attrs) {
    if (content == null)
      return htmlOpen(tagName, attrs);
    return htmlOpen(tagName, attrs) + (content || "") + htmlClose(tagName);
  }
  function buildCode(fn, args) {
    const params = args.map((arg) => {
      if (typeof arg === "function")
        return arg.toString();
      return JSON.stringify(arg ?? null);
    }).join(",");
    return `(${fn.toString()})(${params})`;
  }
  function persistJS(items, context) {
    return items.map((item) => {
      if (item.type === "script") {
        const { textContent, ...rest } = item.data;
        return wrapHtml(
          "script",
          textContent || "",
          rest
        );
      }
      if (item.type === "iife") {
        const { fn, getParams } = item.data;
        return wrapHtml(
          "script",
          escapeScript(buildCode(fn, (getParams == null ? void 0 : getParams(context)) || []))
        );
      }
      return "";
    });
  }
  function persistCSS(items) {
    return items.map((item) => {
      if (item.type === "stylesheet") {
        return wrapHtml("link", null, {
          rel: "stylesheet",
          ...item.data
        });
      }
      return wrapHtml("style", item.data);
    });
  }
  Math.random().toString(36).slice(2, 8);
  function noop() {
  }
  function wrapFunction(fn, wrapper) {
    return (...args) => wrapper(fn, ...args);
  }
  function defer() {
    const obj = {};
    obj.promise = new Promise((resolve, reject) => {
      obj.resolve = resolve;
      obj.reject = reject;
    });
    return obj;
  }
  function memoize(fn) {
    const cache = {};
    return function memoized(...args) {
      const key = `${args[0]}`;
      let data = cache[key];
      if (!data) {
        data = {
          value: fn(...args)
        };
        cache[key] = data;
      }
      return data.value;
    };
  }
  /*! @gera2ld/jsx-dom v2.2.2 | ISC License */
  const VTYPE_ELEMENT = 1;
  const VTYPE_FUNCTION = 2;
  const SVG_NS = "http://www.w3.org/2000/svg";
  const XLINK_NS = "http://www.w3.org/1999/xlink";
  const NS_ATTRS = {
    show: XLINK_NS,
    actuate: XLINK_NS,
    href: XLINK_NS
  };
  const isLeaf = (c) => typeof c === "string" || typeof c === "number";
  const isElement = (c) => (c == null ? void 0 : c.vtype) === VTYPE_ELEMENT;
  const isRenderFunction = (c) => (c == null ? void 0 : c.vtype) === VTYPE_FUNCTION;
  function h(type2, props, ...children) {
    props = Object.assign({}, props, {
      children: children.length === 1 ? children[0] : children
    });
    return jsx(type2, props);
  }
  function jsx(type2, props) {
    let vtype;
    if (typeof type2 === "string")
      vtype = VTYPE_ELEMENT;
    else if (typeof type2 === "function")
      vtype = VTYPE_FUNCTION;
    else
      throw new Error("Invalid VNode type");
    return {
      vtype,
      type: type2,
      props
    };
  }
  function Fragment(props) {
    return props.children;
  }
  const DEFAULT_ENV = {
    isSvg: false
  };
  function insertDom(parent, nodes) {
    if (!Array.isArray(nodes))
      nodes = [nodes];
    nodes = nodes.filter(Boolean);
    if (nodes.length)
      parent.append(...nodes);
  }
  function mountAttributes(domElement, props, env) {
    for (const key in props) {
      if (key === "key" || key === "children" || key === "ref")
        continue;
      if (key === "dangerouslySetInnerHTML") {
        domElement.innerHTML = props[key].__html;
      } else if (key === "innerHTML" || key === "textContent" || key === "innerText" || key === "value" && ["textarea", "select"].includes(domElement.tagName)) {
        const value = props[key];
        if (value != null)
          domElement[key] = value;
      } else if (key.startsWith("on")) {
        domElement[key.toLowerCase()] = props[key];
      } else {
        setDOMAttribute(domElement, key, props[key], env.isSvg);
      }
    }
  }
  const attrMap = {
    className: "class",
    labelFor: "for"
  };
  function setDOMAttribute(el, attr, value, isSVG) {
    attr = attrMap[attr] || attr;
    if (value === true) {
      el.setAttribute(attr, "");
    } else if (value === false) {
      el.removeAttribute(attr);
    } else {
      const namespace = isSVG ? NS_ATTRS[attr] : void 0;
      if (namespace !== void 0) {
        el.setAttributeNS(namespace, attr, value);
      } else {
        el.setAttribute(attr, value);
      }
    }
  }
  function flatten(arr) {
    return arr.reduce((prev, item) => prev.concat(item), []);
  }
  function mountChildren(children, env) {
    return Array.isArray(children) ? flatten(children.map((child) => mountChildren(child, env))) : mount(children, env);
  }
  function mount(vnode, env = DEFAULT_ENV) {
    if (vnode == null || typeof vnode === "boolean") {
      return null;
    }
    if (vnode instanceof Node) {
      return vnode;
    }
    if (isRenderFunction(vnode)) {
      const {
        type: type2,
        props
      } = vnode;
      if (type2 === Fragment) {
        const node = document.createDocumentFragment();
        if (props.children) {
          const children = mountChildren(props.children, env);
          insertDom(node, children);
        }
        return node;
      }
      const childVNode = type2(props);
      return mount(childVNode, env);
    }
    if (isLeaf(vnode)) {
      return document.createTextNode(`${vnode}`);
    }
    if (isElement(vnode)) {
      let node;
      const {
        type: type2,
        props
      } = vnode;
      if (!env.isSvg && type2 === "svg") {
        env = Object.assign({}, env, {
          isSvg: true
        });
      }
      if (!env.isSvg) {
        node = document.createElement(type2);
      } else {
        node = document.createElementNS(SVG_NS, type2);
      }
      mountAttributes(node, props, env);
      if (props.children) {
        let childEnv = env;
        if (env.isSvg && type2 === "foreignObject") {
          childEnv = Object.assign({}, childEnv, {
            isSvg: false
          });
        }
        const children = mountChildren(props.children, childEnv);
        if (children != null)
          insertDom(node, children);
      }
      const {
        ref
      } = props;
      if (typeof ref === "function")
        ref(node);
      return node;
    }
    throw new Error("mount: Invalid Vnode!");
  }
  function mountDom(vnode) {
    return mount(vnode);
  }
  function hm(...args) {
    return mountDom(h(...args));
  }
  const memoizedPreloadJS = memoize((url) => {
    document.head.append(
      hm("link", {
        rel: "preload",
        as: "script",
        href: url
      })
    );
  });
  const jsCache = {};
  async function loadJSItem(item, context) {
    var _a;
    const src = item.type === "script" && ((_a = item.data) == null ? void 0 : _a.src) || "";
    item.loaded || (item.loaded = jsCache[src]);
    if (!item.loaded) {
      const deferred = defer();
      item.loaded = deferred.promise;
      if (item.type === "script") {
        document.head.append(
          hm("script", {
            ...item.data,
            onLoad: () => deferred.resolve(),
            onError: deferred.reject
          })
        );
        if (!src) {
          deferred.resolve();
        } else {
          jsCache[src] = item.loaded;
        }
      }
      if (item.type === "iife") {
        const { fn, getParams } = item.data;
        fn(...(getParams == null ? void 0 : getParams(context)) || []);
        deferred.resolve();
      }
    }
    await item.loaded;
  }
  async function loadJS(items, context) {
    items.forEach((item) => {
      var _a;
      if (item.type === "script" && ((_a = item.data) == null ? void 0 : _a.src)) {
        memoizedPreloadJS(item.data.src);
      }
    });
    context = {
      getMarkmap: () => window.markmap,
      ...context
    };
    for (const item of items) {
      await loadJSItem(item, context);
    }
  }
  function buildJSItem(path) {
    return {
      type: "script",
      data: {
        src: path
      }
    };
  }
  function buildCSSItem(path) {
    return {
      type: "stylesheet",
      data: {
        href: path
      }
    };
  }
  function getDefaultExportFromCjs(x) {
    return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
  }
  const rkatex = (md, options) => {
    const backslash = "\\";
    const dollar = "$";
    const opts = options || {};
    const delimiter = opts.delimiter || dollar;
    if (delimiter.length !== 1) {
      throw new Error("invalid delimiter");
    }
    const katex = require$$0;
    const renderKatex = (source, displayMode) => katex.renderToString(
      source,
      {
        displayMode,
        throwOnError: false
      }
    );
    const parseBlockKatex = (state, startLine, endLine) => {
      let haveEndMarker = false;
      let pos = state.bMarks[startLine] + state.tShift[startLine];
      let max = state.eMarks[startLine];
      if (pos + 1 > max) {
        return false;
      }
      const marker = state.src.charAt(pos);
      if (marker !== delimiter) {
        return false;
      }
      let mem = pos;
      pos = state.skipChars(pos, marker);
      let len = pos - mem;
      if (len !== 2) {
        return false;
      }
      let nextLine = startLine;
      for (; ; ) {
        ++nextLine;
        if (nextLine >= endLine) {
          break;
        }
        pos = mem = state.bMarks[nextLine] + state.tShift[nextLine];
        max = state.eMarks[nextLine];
        if (pos < max && state.tShift[nextLine] < state.blkIndent) {
          break;
        }
        if (state.src.charAt(pos) !== delimiter) {
          continue;
        }
        if (state.tShift[nextLine] - state.blkIndent >= 4) {
          continue;
        }
        pos = state.skipChars(pos, marker);
        if (pos - mem < len) {
          continue;
        }
        pos = state.skipSpaces(pos);
        if (pos < max) {
          continue;
        }
        haveEndMarker = true;
        break;
      }
      len = state.tShift[startLine];
      state.line = nextLine + (haveEndMarker ? 1 : 0);
      const content = state.getLines(startLine + 1, nextLine, len, true).replace(/[ \n]+/g, " ").trim();
      state.tokens.push({
        type: "katex",
        params: null,
        content,
        lines: [startLine, state.line],
        level: state.level,
        block: true
      });
      return true;
    };
    const parseInlineKatex = (state, silent) => {
      const start = state.pos;
      const max = state.posMax;
      let pos = start;
      if (state.src.charAt(pos) !== delimiter) {
        return false;
      }
      ++pos;
      while (pos < max && state.src.charAt(pos) === delimiter) {
        ++pos;
      }
      const marker = state.src.slice(start, pos);
      if (marker.length > 2) {
        return false;
      }
      const spanStart = pos;
      let escapedDepth = 0;
      while (pos < max) {
        const char = state.src.charAt(pos);
        if (char === "{" && (pos == 0 || state.src.charAt(pos - 1) != backslash)) {
          escapedDepth += 1;
        } else if (char === "}" && (pos == 0 || state.src.charAt(pos - 1) != backslash)) {
          escapedDepth -= 1;
          if (escapedDepth < 0) {
            return false;
          }
        } else if (char === delimiter && escapedDepth === 0) {
          const matchStart = pos;
          let matchEnd = pos + 1;
          while (matchEnd < max && state.src.charAt(matchEnd) === delimiter) {
            ++matchEnd;
          }
          if (matchEnd - matchStart === marker.length) {
            if (!silent) {
              const content = state.src.slice(spanStart, matchStart).replace(/[ \n]+/g, " ").trim();
              state.push({ type: "katex", content, block: marker.length > 1, level: state.level });
            }
            state.pos = matchEnd;
            return true;
          }
        }
        pos += 1;
      }
      if (!silent) {
        state.pending += marker;
      }
      state.pos += marker.length;
      return true;
    };
    md.inline.ruler.push("katex", parseInlineKatex, options);
    md.block.ruler.push("katex", parseBlockKatex, options);
    md.renderer.rules.katex = (tokens, idx) => renderKatex(tokens[idx].content, tokens[idx].block);
    md.renderer.rules.katex.delimiter = delimiter;
  };
  var remarkableKatex = rkatex;
  const remarkableKatex$1 = /* @__PURE__ */ getDefaultExportFromCjs(remarkableKatex);
  const name$3 = "katex";
  const preloadScripts$1 = [
    `katex@${"0.16.8"}/dist/katex.min.js`
  ].map((path) => buildJSItem(path));
  const webfontloader = buildJSItem(
    `webfontloader@${"1.6.28"}/webfontloader.js`
  );
  webfontloader.data.defer = true;
  const styles$1 = [`katex@${"0.16.8"}/dist/katex.min.css`].map(
    (path) => buildCSSItem(path)
  );
  const config$1 = {
    versions: {
      katex: "0.16.8",
      webfontloader: "1.6.28"
    },
    preloadScripts: preloadScripts$1,
    scripts: [
      {
        type: "iife",
        data: {
          fn: (getMarkmap) => {
            window.WebFontConfig = {
              custom: {
                families: [
                  "KaTeX_AMS",
                  "KaTeX_Caligraphic:n4,n7",
                  "KaTeX_Fraktur:n4,n7",
                  "KaTeX_Main:n4,n7,i4,i7",
                  "KaTeX_Math:i4,i7",
                  "KaTeX_Script",
                  "KaTeX_SansSerif:n4,n7,i4",
                  "KaTeX_Size1",
                  "KaTeX_Size2",
                  "KaTeX_Size3",
                  "KaTeX_Size4",
                  "KaTeX_Typewriter"
                ]
              },
              active: () => {
                getMarkmap().refreshHook.call();
              }
            };
          },
          getParams({ getMarkmap }) {
            return [getMarkmap];
          }
        }
      },
      webfontloader
    ],
    styles: styles$1
  };
  function addDefaultVersions(paths, name2, version) {
    return paths.map((path) => {
      if (typeof path === "string" && !path.includes("://")) {
        if (!path.startsWith("npm:")) {
          path = `npm:${path}`;
        }
        const prefixLength = 4 + name2.length;
        if (path.startsWith(`npm:${name2}/`)) {
          path = `${path.slice(0, prefixLength)}@${version}${path.slice(
            prefixLength
          )}`;
        }
      }
      return path;
    });
  }
  function patchJSItem(urlBuilder, item) {
    if (item.type === "script" && item.data.src) {
      return {
        ...item,
        data: {
          ...item.data,
          src: urlBuilder.getFullUrl(item.data.src)
        }
      };
    }
    return item;
  }
  function patchCSSItem(urlBuilder, item) {
    if (item.type === "stylesheet" && item.data.href) {
      return {
        ...item,
        data: {
          ...item.data,
          href: urlBuilder.getFullUrl(item.data.href)
        }
      };
    }
    return item;
  }
  function createTransformHooks(transformer) {
    return {
      transformer,
      parser: new Hook(),
      beforeParse: new Hook(),
      afterParse: new Hook(),
      htmltag: new Hook(),
      retransform: new Hook()
    };
  }
  function definePlugin(plugin2) {
    return plugin2;
  }
  const plugin$1 = definePlugin({
    name: name$3,
    config: config$1,
    transform(transformHooks) {
      var _a, _b, _c, _d;
      let loading;
      const preloadScripts2 = ((_b = (_a = plugin$1.config) == null ? void 0 : _a.preloadScripts) == null ? void 0 : _b.map(
        (item) => patchJSItem(transformHooks.transformer.urlBuilder, item)
      )) || [];
      const autoload = () => {
        loading || (loading = loadJS(preloadScripts2));
        return loading;
      };
      const renderKatex = (source, displayMode) => {
        const { katex } = window;
        if (katex) {
          return katex.renderToString(source, {
            displayMode,
            throwOnError: false
          });
        }
        autoload().then(() => {
          transformHooks.retransform.call();
        });
        return source;
      };
      let enableFeature = noop;
      transformHooks.parser.tap((md) => {
        md.use(remarkableKatex$1);
        md.renderer.rules.katex = (tokens, idx) => {
          enableFeature();
          const result = renderKatex(tokens[idx].content, !!tokens[idx].block);
          return result;
        };
      });
      transformHooks.beforeParse.tap((_, context) => {
        enableFeature = () => {
          context.features[name$3] = true;
        };
      });
      transformHooks.afterParse.tap((_, context) => {
        var _a2;
        const markmap = (_a2 = context.frontmatter) == null ? void 0 : _a2.markmap;
        if (markmap) {
          ["extraJs", "extraCss"].forEach((key) => {
            var _a3, _b2;
            const value = markmap[key];
            if (value) {
              markmap[key] = addDefaultVersions(
                value,
                name$3,
                ((_b2 = (_a3 = plugin$1.config) == null ? void 0 : _a3.versions) == null ? void 0 : _b2.katex) || ""
              );
            }
          });
        }
      });
      return {
        styles: (_c = plugin$1.config) == null ? void 0 : _c.styles,
        scripts: (_d = plugin$1.config) == null ? void 0 : _d.scripts
      };
    }
  });
  /*! js-yaml 4.1.0 https://github.com/nodeca/js-yaml @license MIT */
  function isNothing(subject) {
    return typeof subject === "undefined" || subject === null;
  }
  function isObject(subject) {
    return typeof subject === "object" && subject !== null;
  }
  function toArray(sequence) {
    if (Array.isArray(sequence))
      return sequence;
    else if (isNothing(sequence))
      return [];
    return [sequence];
  }
  function extend(target, source) {
    var index, length, key, sourceKeys;
    if (source) {
      sourceKeys = Object.keys(source);
      for (index = 0, length = sourceKeys.length; index < length; index += 1) {
        key = sourceKeys[index];
        target[key] = source[key];
      }
    }
    return target;
  }
  function repeat(string, count) {
    var result = "", cycle;
    for (cycle = 0; cycle < count; cycle += 1) {
      result += string;
    }
    return result;
  }
  function isNegativeZero(number) {
    return number === 0 && Number.NEGATIVE_INFINITY === 1 / number;
  }
  var isNothing_1 = isNothing;
  var isObject_1 = isObject;
  var toArray_1 = toArray;
  var repeat_1 = repeat;
  var isNegativeZero_1 = isNegativeZero;
  var extend_1 = extend;
  var common = {
    isNothing: isNothing_1,
    isObject: isObject_1,
    toArray: toArray_1,
    repeat: repeat_1,
    isNegativeZero: isNegativeZero_1,
    extend: extend_1
  };
  function formatError(exception2, compact) {
    var where = "", message = exception2.reason || "(unknown reason)";
    if (!exception2.mark)
      return message;
    if (exception2.mark.name) {
      where += 'in "' + exception2.mark.name + '" ';
    }
    where += "(" + (exception2.mark.line + 1) + ":" + (exception2.mark.column + 1) + ")";
    if (!compact && exception2.mark.snippet) {
      where += "\n\n" + exception2.mark.snippet;
    }
    return message + " " + where;
  }
  function YAMLException$1(reason, mark2) {
    Error.call(this);
    this.name = "YAMLException";
    this.reason = reason;
    this.mark = mark2;
    this.message = formatError(this, false);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error().stack || "";
    }
  }
  YAMLException$1.prototype = Object.create(Error.prototype);
  YAMLException$1.prototype.constructor = YAMLException$1;
  YAMLException$1.prototype.toString = function toString(compact) {
    return this.name + ": " + formatError(this, compact);
  };
  var exception = YAMLException$1;
  function getLine(buffer, lineStart, lineEnd, position, maxLineLength) {
    var head = "";
    var tail = "";
    var maxHalfLength = Math.floor(maxLineLength / 2) - 1;
    if (position - lineStart > maxHalfLength) {
      head = " ... ";
      lineStart = position - maxHalfLength + head.length;
    }
    if (lineEnd - position > maxHalfLength) {
      tail = " ...";
      lineEnd = position + maxHalfLength - tail.length;
    }
    return {
      str: head + buffer.slice(lineStart, lineEnd).replace(/\t/g, "→") + tail,
      pos: position - lineStart + head.length
      // relative position
    };
  }
  function padStart(string, max) {
    return common.repeat(" ", max - string.length) + string;
  }
  function makeSnippet(mark2, options) {
    options = Object.create(options || null);
    if (!mark2.buffer)
      return null;
    if (!options.maxLength)
      options.maxLength = 79;
    if (typeof options.indent !== "number")
      options.indent = 1;
    if (typeof options.linesBefore !== "number")
      options.linesBefore = 3;
    if (typeof options.linesAfter !== "number")
      options.linesAfter = 2;
    var re = /\r?\n|\r|\0/g;
    var lineStarts = [0];
    var lineEnds = [];
    var match;
    var foundLineNo = -1;
    while (match = re.exec(mark2.buffer)) {
      lineEnds.push(match.index);
      lineStarts.push(match.index + match[0].length);
      if (mark2.position <= match.index && foundLineNo < 0) {
        foundLineNo = lineStarts.length - 2;
      }
    }
    if (foundLineNo < 0)
      foundLineNo = lineStarts.length - 1;
    var result = "", i2, line;
    var lineNoLength = Math.min(mark2.line + options.linesAfter, lineEnds.length).toString().length;
    var maxLineLength = options.maxLength - (options.indent + lineNoLength + 3);
    for (i2 = 1; i2 <= options.linesBefore; i2++) {
      if (foundLineNo - i2 < 0)
        break;
      line = getLine(
        mark2.buffer,
        lineStarts[foundLineNo - i2],
        lineEnds[foundLineNo - i2],
        mark2.position - (lineStarts[foundLineNo] - lineStarts[foundLineNo - i2]),
        maxLineLength
      );
      result = common.repeat(" ", options.indent) + padStart((mark2.line - i2 + 1).toString(), lineNoLength) + " | " + line.str + "\n" + result;
    }
    line = getLine(mark2.buffer, lineStarts[foundLineNo], lineEnds[foundLineNo], mark2.position, maxLineLength);
    result += common.repeat(" ", options.indent) + padStart((mark2.line + 1).toString(), lineNoLength) + " | " + line.str + "\n";
    result += common.repeat("-", options.indent + lineNoLength + 3 + line.pos) + "^\n";
    for (i2 = 1; i2 <= options.linesAfter; i2++) {
      if (foundLineNo + i2 >= lineEnds.length)
        break;
      line = getLine(
        mark2.buffer,
        lineStarts[foundLineNo + i2],
        lineEnds[foundLineNo + i2],
        mark2.position - (lineStarts[foundLineNo] - lineStarts[foundLineNo + i2]),
        maxLineLength
      );
      result += common.repeat(" ", options.indent) + padStart((mark2.line + i2 + 1).toString(), lineNoLength) + " | " + line.str + "\n";
    }
    return result.replace(/\n$/, "");
  }
  var snippet = makeSnippet;
  var TYPE_CONSTRUCTOR_OPTIONS = [
    "kind",
    "multi",
    "resolve",
    "construct",
    "instanceOf",
    "predicate",
    "represent",
    "representName",
    "defaultStyle",
    "styleAliases"
  ];
  var YAML_NODE_KINDS = [
    "scalar",
    "sequence",
    "mapping"
  ];
  function compileStyleAliases(map2) {
    var result = {};
    if (map2 !== null) {
      Object.keys(map2).forEach(function(style) {
        map2[style].forEach(function(alias) {
          result[String(alias)] = style;
        });
      });
    }
    return result;
  }
  function Type$1(tag, options) {
    options = options || {};
    Object.keys(options).forEach(function(name2) {
      if (TYPE_CONSTRUCTOR_OPTIONS.indexOf(name2) === -1) {
        throw new exception('Unknown option "' + name2 + '" is met in definition of "' + tag + '" YAML type.');
      }
    });
    this.options = options;
    this.tag = tag;
    this.kind = options["kind"] || null;
    this.resolve = options["resolve"] || function() {
      return true;
    };
    this.construct = options["construct"] || function(data) {
      return data;
    };
    this.instanceOf = options["instanceOf"] || null;
    this.predicate = options["predicate"] || null;
    this.represent = options["represent"] || null;
    this.representName = options["representName"] || null;
    this.defaultStyle = options["defaultStyle"] || null;
    this.multi = options["multi"] || false;
    this.styleAliases = compileStyleAliases(options["styleAliases"] || null);
    if (YAML_NODE_KINDS.indexOf(this.kind) === -1) {
      throw new exception('Unknown kind "' + this.kind + '" is specified for "' + tag + '" YAML type.');
    }
  }
  var type = Type$1;
  function compileList(schema2, name2) {
    var result = [];
    schema2[name2].forEach(function(currentType) {
      var newIndex = result.length;
      result.forEach(function(previousType, previousIndex) {
        if (previousType.tag === currentType.tag && previousType.kind === currentType.kind && previousType.multi === currentType.multi) {
          newIndex = previousIndex;
        }
      });
      result[newIndex] = currentType;
    });
    return result;
  }
  function compileMap() {
    var result = {
      scalar: {},
      sequence: {},
      mapping: {},
      fallback: {},
      multi: {
        scalar: [],
        sequence: [],
        mapping: [],
        fallback: []
      }
    }, index, length;
    function collectType(type2) {
      if (type2.multi) {
        result.multi[type2.kind].push(type2);
        result.multi["fallback"].push(type2);
      } else {
        result[type2.kind][type2.tag] = result["fallback"][type2.tag] = type2;
      }
    }
    for (index = 0, length = arguments.length; index < length; index += 1) {
      arguments[index].forEach(collectType);
    }
    return result;
  }
  function Schema$1(definition) {
    return this.extend(definition);
  }
  Schema$1.prototype.extend = function extend2(definition) {
    var implicit = [];
    var explicit = [];
    if (definition instanceof type) {
      explicit.push(definition);
    } else if (Array.isArray(definition)) {
      explicit = explicit.concat(definition);
    } else if (definition && (Array.isArray(definition.implicit) || Array.isArray(definition.explicit))) {
      if (definition.implicit)
        implicit = implicit.concat(definition.implicit);
      if (definition.explicit)
        explicit = explicit.concat(definition.explicit);
    } else {
      throw new exception("Schema.extend argument should be a Type, [ Type ], or a schema definition ({ implicit: [...], explicit: [...] })");
    }
    implicit.forEach(function(type$1) {
      if (!(type$1 instanceof type)) {
        throw new exception("Specified list of YAML types (or a single Type object) contains a non-Type object.");
      }
      if (type$1.loadKind && type$1.loadKind !== "scalar") {
        throw new exception("There is a non-scalar type in the implicit list of a schema. Implicit resolving of such types is not supported.");
      }
      if (type$1.multi) {
        throw new exception("There is a multi type in the implicit list of a schema. Multi tags can only be listed as explicit.");
      }
    });
    explicit.forEach(function(type$1) {
      if (!(type$1 instanceof type)) {
        throw new exception("Specified list of YAML types (or a single Type object) contains a non-Type object.");
      }
    });
    var result = Object.create(Schema$1.prototype);
    result.implicit = (this.implicit || []).concat(implicit);
    result.explicit = (this.explicit || []).concat(explicit);
    result.compiledImplicit = compileList(result, "implicit");
    result.compiledExplicit = compileList(result, "explicit");
    result.compiledTypeMap = compileMap(result.compiledImplicit, result.compiledExplicit);
    return result;
  };
  var schema = Schema$1;
  var str = new type("tag:yaml.org,2002:str", {
    kind: "scalar",
    construct: function(data) {
      return data !== null ? data : "";
    }
  });
  var seq = new type("tag:yaml.org,2002:seq", {
    kind: "sequence",
    construct: function(data) {
      return data !== null ? data : [];
    }
  });
  var map = new type("tag:yaml.org,2002:map", {
    kind: "mapping",
    construct: function(data) {
      return data !== null ? data : {};
    }
  });
  var failsafe = new schema({
    explicit: [
      str,
      seq,
      map
    ]
  });
  function resolveYamlNull(data) {
    if (data === null)
      return true;
    var max = data.length;
    return max === 1 && data === "~" || max === 4 && (data === "null" || data === "Null" || data === "NULL");
  }
  function constructYamlNull() {
    return null;
  }
  function isNull(object) {
    return object === null;
  }
  var _null = new type("tag:yaml.org,2002:null", {
    kind: "scalar",
    resolve: resolveYamlNull,
    construct: constructYamlNull,
    predicate: isNull,
    represent: {
      canonical: function() {
        return "~";
      },
      lowercase: function() {
        return "null";
      },
      uppercase: function() {
        return "NULL";
      },
      camelcase: function() {
        return "Null";
      },
      empty: function() {
        return "";
      }
    },
    defaultStyle: "lowercase"
  });
  function resolveYamlBoolean(data) {
    if (data === null)
      return false;
    var max = data.length;
    return max === 4 && (data === "true" || data === "True" || data === "TRUE") || max === 5 && (data === "false" || data === "False" || data === "FALSE");
  }
  function constructYamlBoolean(data) {
    return data === "true" || data === "True" || data === "TRUE";
  }
  function isBoolean(object) {
    return Object.prototype.toString.call(object) === "[object Boolean]";
  }
  var bool = new type("tag:yaml.org,2002:bool", {
    kind: "scalar",
    resolve: resolveYamlBoolean,
    construct: constructYamlBoolean,
    predicate: isBoolean,
    represent: {
      lowercase: function(object) {
        return object ? "true" : "false";
      },
      uppercase: function(object) {
        return object ? "TRUE" : "FALSE";
      },
      camelcase: function(object) {
        return object ? "True" : "False";
      }
    },
    defaultStyle: "lowercase"
  });
  function isHexCode(c) {
    return 48 <= c && c <= 57 || 65 <= c && c <= 70 || 97 <= c && c <= 102;
  }
  function isOctCode(c) {
    return 48 <= c && c <= 55;
  }
  function isDecCode(c) {
    return 48 <= c && c <= 57;
  }
  function resolveYamlInteger(data) {
    if (data === null)
      return false;
    var max = data.length, index = 0, hasDigits = false, ch;
    if (!max)
      return false;
    ch = data[index];
    if (ch === "-" || ch === "+") {
      ch = data[++index];
    }
    if (ch === "0") {
      if (index + 1 === max)
        return true;
      ch = data[++index];
      if (ch === "b") {
        index++;
        for (; index < max; index++) {
          ch = data[index];
          if (ch === "_")
            continue;
          if (ch !== "0" && ch !== "1")
            return false;
          hasDigits = true;
        }
        return hasDigits && ch !== "_";
      }
      if (ch === "x") {
        index++;
        for (; index < max; index++) {
          ch = data[index];
          if (ch === "_")
            continue;
          if (!isHexCode(data.charCodeAt(index)))
            return false;
          hasDigits = true;
        }
        return hasDigits && ch !== "_";
      }
      if (ch === "o") {
        index++;
        for (; index < max; index++) {
          ch = data[index];
          if (ch === "_")
            continue;
          if (!isOctCode(data.charCodeAt(index)))
            return false;
          hasDigits = true;
        }
        return hasDigits && ch !== "_";
      }
    }
    if (ch === "_")
      return false;
    for (; index < max; index++) {
      ch = data[index];
      if (ch === "_")
        continue;
      if (!isDecCode(data.charCodeAt(index))) {
        return false;
      }
      hasDigits = true;
    }
    if (!hasDigits || ch === "_")
      return false;
    return true;
  }
  function constructYamlInteger(data) {
    var value = data, sign = 1, ch;
    if (value.indexOf("_") !== -1) {
      value = value.replace(/_/g, "");
    }
    ch = value[0];
    if (ch === "-" || ch === "+") {
      if (ch === "-")
        sign = -1;
      value = value.slice(1);
      ch = value[0];
    }
    if (value === "0")
      return 0;
    if (ch === "0") {
      if (value[1] === "b")
        return sign * parseInt(value.slice(2), 2);
      if (value[1] === "x")
        return sign * parseInt(value.slice(2), 16);
      if (value[1] === "o")
        return sign * parseInt(value.slice(2), 8);
    }
    return sign * parseInt(value, 10);
  }
  function isInteger(object) {
    return Object.prototype.toString.call(object) === "[object Number]" && (object % 1 === 0 && !common.isNegativeZero(object));
  }
  var int = new type("tag:yaml.org,2002:int", {
    kind: "scalar",
    resolve: resolveYamlInteger,
    construct: constructYamlInteger,
    predicate: isInteger,
    represent: {
      binary: function(obj) {
        return obj >= 0 ? "0b" + obj.toString(2) : "-0b" + obj.toString(2).slice(1);
      },
      octal: function(obj) {
        return obj >= 0 ? "0o" + obj.toString(8) : "-0o" + obj.toString(8).slice(1);
      },
      decimal: function(obj) {
        return obj.toString(10);
      },
      /* eslint-disable max-len */
      hexadecimal: function(obj) {
        return obj >= 0 ? "0x" + obj.toString(16).toUpperCase() : "-0x" + obj.toString(16).toUpperCase().slice(1);
      }
    },
    defaultStyle: "decimal",
    styleAliases: {
      binary: [2, "bin"],
      octal: [8, "oct"],
      decimal: [10, "dec"],
      hexadecimal: [16, "hex"]
    }
  });
  var YAML_FLOAT_PATTERN = new RegExp(
    // 2.5e4, 2.5 and integers
    "^(?:[-+]?(?:[0-9][0-9_]*)(?:\\.[0-9_]*)?(?:[eE][-+]?[0-9]+)?|\\.[0-9_]+(?:[eE][-+]?[0-9]+)?|[-+]?\\.(?:inf|Inf|INF)|\\.(?:nan|NaN|NAN))$"
  );
  function resolveYamlFloat(data) {
    if (data === null)
      return false;
    if (!YAML_FLOAT_PATTERN.test(data) || // Quick hack to not allow integers end with `_`
    // Probably should update regexp & check speed
    data[data.length - 1] === "_") {
      return false;
    }
    return true;
  }
  function constructYamlFloat(data) {
    var value, sign;
    value = data.replace(/_/g, "").toLowerCase();
    sign = value[0] === "-" ? -1 : 1;
    if ("+-".indexOf(value[0]) >= 0) {
      value = value.slice(1);
    }
    if (value === ".inf") {
      return sign === 1 ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY;
    } else if (value === ".nan") {
      return NaN;
    }
    return sign * parseFloat(value, 10);
  }
  var SCIENTIFIC_WITHOUT_DOT = /^[-+]?[0-9]+e/;
  function representYamlFloat(object, style) {
    var res;
    if (isNaN(object)) {
      switch (style) {
        case "lowercase":
          return ".nan";
        case "uppercase":
          return ".NAN";
        case "camelcase":
          return ".NaN";
      }
    } else if (Number.POSITIVE_INFINITY === object) {
      switch (style) {
        case "lowercase":
          return ".inf";
        case "uppercase":
          return ".INF";
        case "camelcase":
          return ".Inf";
      }
    } else if (Number.NEGATIVE_INFINITY === object) {
      switch (style) {
        case "lowercase":
          return "-.inf";
        case "uppercase":
          return "-.INF";
        case "camelcase":
          return "-.Inf";
      }
    } else if (common.isNegativeZero(object)) {
      return "-0.0";
    }
    res = object.toString(10);
    return SCIENTIFIC_WITHOUT_DOT.test(res) ? res.replace("e", ".e") : res;
  }
  function isFloat(object) {
    return Object.prototype.toString.call(object) === "[object Number]" && (object % 1 !== 0 || common.isNegativeZero(object));
  }
  var float = new type("tag:yaml.org,2002:float", {
    kind: "scalar",
    resolve: resolveYamlFloat,
    construct: constructYamlFloat,
    predicate: isFloat,
    represent: representYamlFloat,
    defaultStyle: "lowercase"
  });
  var json = failsafe.extend({
    implicit: [
      _null,
      bool,
      int,
      float
    ]
  });
  var core = json;
  var YAML_DATE_REGEXP = new RegExp(
    "^([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])$"
  );
  var YAML_TIMESTAMP_REGEXP = new RegExp(
    "^([0-9][0-9][0-9][0-9])-([0-9][0-9]?)-([0-9][0-9]?)(?:[Tt]|[ \\t]+)([0-9][0-9]?):([0-9][0-9]):([0-9][0-9])(?:\\.([0-9]*))?(?:[ \\t]*(Z|([-+])([0-9][0-9]?)(?::([0-9][0-9]))?))?$"
  );
  function resolveYamlTimestamp(data) {
    if (data === null)
      return false;
    if (YAML_DATE_REGEXP.exec(data) !== null)
      return true;
    if (YAML_TIMESTAMP_REGEXP.exec(data) !== null)
      return true;
    return false;
  }
  function constructYamlTimestamp(data) {
    var match, year, month, day, hour, minute, second, fraction = 0, delta = null, tz_hour, tz_minute, date;
    match = YAML_DATE_REGEXP.exec(data);
    if (match === null)
      match = YAML_TIMESTAMP_REGEXP.exec(data);
    if (match === null)
      throw new Error("Date resolve error");
    year = +match[1];
    month = +match[2] - 1;
    day = +match[3];
    if (!match[4]) {
      return new Date(Date.UTC(year, month, day));
    }
    hour = +match[4];
    minute = +match[5];
    second = +match[6];
    if (match[7]) {
      fraction = match[7].slice(0, 3);
      while (fraction.length < 3) {
        fraction += "0";
      }
      fraction = +fraction;
    }
    if (match[9]) {
      tz_hour = +match[10];
      tz_minute = +(match[11] || 0);
      delta = (tz_hour * 60 + tz_minute) * 6e4;
      if (match[9] === "-")
        delta = -delta;
    }
    date = new Date(Date.UTC(year, month, day, hour, minute, second, fraction));
    if (delta)
      date.setTime(date.getTime() - delta);
    return date;
  }
  function representYamlTimestamp(object) {
    return object.toISOString();
  }
  var timestamp = new type("tag:yaml.org,2002:timestamp", {
    kind: "scalar",
    resolve: resolveYamlTimestamp,
    construct: constructYamlTimestamp,
    instanceOf: Date,
    represent: representYamlTimestamp
  });
  function resolveYamlMerge(data) {
    return data === "<<" || data === null;
  }
  var merge = new type("tag:yaml.org,2002:merge", {
    kind: "scalar",
    resolve: resolveYamlMerge
  });
  var BASE64_MAP = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=\n\r";
  function resolveYamlBinary(data) {
    if (data === null)
      return false;
    var code2, idx, bitlen = 0, max = data.length, map2 = BASE64_MAP;
    for (idx = 0; idx < max; idx++) {
      code2 = map2.indexOf(data.charAt(idx));
      if (code2 > 64)
        continue;
      if (code2 < 0)
        return false;
      bitlen += 6;
    }
    return bitlen % 8 === 0;
  }
  function constructYamlBinary(data) {
    var idx, tailbits, input = data.replace(/[\r\n=]/g, ""), max = input.length, map2 = BASE64_MAP, bits = 0, result = [];
    for (idx = 0; idx < max; idx++) {
      if (idx % 4 === 0 && idx) {
        result.push(bits >> 16 & 255);
        result.push(bits >> 8 & 255);
        result.push(bits & 255);
      }
      bits = bits << 6 | map2.indexOf(input.charAt(idx));
    }
    tailbits = max % 4 * 6;
    if (tailbits === 0) {
      result.push(bits >> 16 & 255);
      result.push(bits >> 8 & 255);
      result.push(bits & 255);
    } else if (tailbits === 18) {
      result.push(bits >> 10 & 255);
      result.push(bits >> 2 & 255);
    } else if (tailbits === 12) {
      result.push(bits >> 4 & 255);
    }
    return new Uint8Array(result);
  }
  function representYamlBinary(object) {
    var result = "", bits = 0, idx, tail, max = object.length, map2 = BASE64_MAP;
    for (idx = 0; idx < max; idx++) {
      if (idx % 3 === 0 && idx) {
        result += map2[bits >> 18 & 63];
        result += map2[bits >> 12 & 63];
        result += map2[bits >> 6 & 63];
        result += map2[bits & 63];
      }
      bits = (bits << 8) + object[idx];
    }
    tail = max % 3;
    if (tail === 0) {
      result += map2[bits >> 18 & 63];
      result += map2[bits >> 12 & 63];
      result += map2[bits >> 6 & 63];
      result += map2[bits & 63];
    } else if (tail === 2) {
      result += map2[bits >> 10 & 63];
      result += map2[bits >> 4 & 63];
      result += map2[bits << 2 & 63];
      result += map2[64];
    } else if (tail === 1) {
      result += map2[bits >> 2 & 63];
      result += map2[bits << 4 & 63];
      result += map2[64];
      result += map2[64];
    }
    return result;
  }
  function isBinary(obj) {
    return Object.prototype.toString.call(obj) === "[object Uint8Array]";
  }
  var binary = new type("tag:yaml.org,2002:binary", {
    kind: "scalar",
    resolve: resolveYamlBinary,
    construct: constructYamlBinary,
    predicate: isBinary,
    represent: representYamlBinary
  });
  var _hasOwnProperty$3 = Object.prototype.hasOwnProperty;
  var _toString$2 = Object.prototype.toString;
  function resolveYamlOmap(data) {
    if (data === null)
      return true;
    var objectKeys = [], index, length, pair, pairKey, pairHasKey, object = data;
    for (index = 0, length = object.length; index < length; index += 1) {
      pair = object[index];
      pairHasKey = false;
      if (_toString$2.call(pair) !== "[object Object]")
        return false;
      for (pairKey in pair) {
        if (_hasOwnProperty$3.call(pair, pairKey)) {
          if (!pairHasKey)
            pairHasKey = true;
          else
            return false;
        }
      }
      if (!pairHasKey)
        return false;
      if (objectKeys.indexOf(pairKey) === -1)
        objectKeys.push(pairKey);
      else
        return false;
    }
    return true;
  }
  function constructYamlOmap(data) {
    return data !== null ? data : [];
  }
  var omap = new type("tag:yaml.org,2002:omap", {
    kind: "sequence",
    resolve: resolveYamlOmap,
    construct: constructYamlOmap
  });
  var _toString$1 = Object.prototype.toString;
  function resolveYamlPairs(data) {
    if (data === null)
      return true;
    var index, length, pair, keys, result, object = data;
    result = new Array(object.length);
    for (index = 0, length = object.length; index < length; index += 1) {
      pair = object[index];
      if (_toString$1.call(pair) !== "[object Object]")
        return false;
      keys = Object.keys(pair);
      if (keys.length !== 1)
        return false;
      result[index] = [keys[0], pair[keys[0]]];
    }
    return true;
  }
  function constructYamlPairs(data) {
    if (data === null)
      return [];
    var index, length, pair, keys, result, object = data;
    result = new Array(object.length);
    for (index = 0, length = object.length; index < length; index += 1) {
      pair = object[index];
      keys = Object.keys(pair);
      result[index] = [keys[0], pair[keys[0]]];
    }
    return result;
  }
  var pairs = new type("tag:yaml.org,2002:pairs", {
    kind: "sequence",
    resolve: resolveYamlPairs,
    construct: constructYamlPairs
  });
  var _hasOwnProperty$2 = Object.prototype.hasOwnProperty;
  function resolveYamlSet(data) {
    if (data === null)
      return true;
    var key, object = data;
    for (key in object) {
      if (_hasOwnProperty$2.call(object, key)) {
        if (object[key] !== null)
          return false;
      }
    }
    return true;
  }
  function constructYamlSet(data) {
    return data !== null ? data : {};
  }
  var set = new type("tag:yaml.org,2002:set", {
    kind: "mapping",
    resolve: resolveYamlSet,
    construct: constructYamlSet
  });
  var _default = core.extend({
    implicit: [
      timestamp,
      merge
    ],
    explicit: [
      binary,
      omap,
      pairs,
      set
    ]
  });
  var _hasOwnProperty$1 = Object.prototype.hasOwnProperty;
  var CONTEXT_FLOW_IN = 1;
  var CONTEXT_FLOW_OUT = 2;
  var CONTEXT_BLOCK_IN = 3;
  var CONTEXT_BLOCK_OUT = 4;
  var CHOMPING_CLIP = 1;
  var CHOMPING_STRIP = 2;
  var CHOMPING_KEEP = 3;
  var PATTERN_NON_PRINTABLE = /[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x84\x86-\x9F\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]/;
  var PATTERN_NON_ASCII_LINE_BREAKS = /[\x85\u2028\u2029]/;
  var PATTERN_FLOW_INDICATORS = /[,\[\]\{\}]/;
  var PATTERN_TAG_HANDLE = /^(?:!|!!|![a-z\-]+!)$/i;
  var PATTERN_TAG_URI = /^(?:!|[^,\[\]\{\}])(?:%[0-9a-f]{2}|[0-9a-z\-#;\/\?:@&=\+\$,_\.!~\*'\(\)\[\]])*$/i;
  function _class(obj) {
    return Object.prototype.toString.call(obj);
  }
  function is_EOL(c) {
    return c === 10 || c === 13;
  }
  function is_WHITE_SPACE(c) {
    return c === 9 || c === 32;
  }
  function is_WS_OR_EOL(c) {
    return c === 9 || c === 32 || c === 10 || c === 13;
  }
  function is_FLOW_INDICATOR(c) {
    return c === 44 || c === 91 || c === 93 || c === 123 || c === 125;
  }
  function fromHexCode(c) {
    var lc;
    if (48 <= c && c <= 57) {
      return c - 48;
    }
    lc = c | 32;
    if (97 <= lc && lc <= 102) {
      return lc - 97 + 10;
    }
    return -1;
  }
  function escapedHexLen(c) {
    if (c === 120) {
      return 2;
    }
    if (c === 117) {
      return 4;
    }
    if (c === 85) {
      return 8;
    }
    return 0;
  }
  function fromDecimalCode(c) {
    if (48 <= c && c <= 57) {
      return c - 48;
    }
    return -1;
  }
  function simpleEscapeSequence(c) {
    return c === 48 ? "\0" : c === 97 ? "\x07" : c === 98 ? "\b" : c === 116 ? "	" : c === 9 ? "	" : c === 110 ? "\n" : c === 118 ? "\v" : c === 102 ? "\f" : c === 114 ? "\r" : c === 101 ? "\x1B" : c === 32 ? " " : c === 34 ? '"' : c === 47 ? "/" : c === 92 ? "\\" : c === 78 ? "" : c === 95 ? " " : c === 76 ? "\u2028" : c === 80 ? "\u2029" : "";
  }
  function charFromCodepoint(c) {
    if (c <= 65535) {
      return String.fromCharCode(c);
    }
    return String.fromCharCode(
      (c - 65536 >> 10) + 55296,
      (c - 65536 & 1023) + 56320
    );
  }
  var simpleEscapeCheck = new Array(256);
  var simpleEscapeMap = new Array(256);
  for (var i = 0; i < 256; i++) {
    simpleEscapeCheck[i] = simpleEscapeSequence(i) ? 1 : 0;
    simpleEscapeMap[i] = simpleEscapeSequence(i);
  }
  function State$1(input, options) {
    this.input = input;
    this.filename = options["filename"] || null;
    this.schema = options["schema"] || _default;
    this.onWarning = options["onWarning"] || null;
    this.legacy = options["legacy"] || false;
    this.json = options["json"] || false;
    this.listener = options["listener"] || null;
    this.implicitTypes = this.schema.compiledImplicit;
    this.typeMap = this.schema.compiledTypeMap;
    this.length = input.length;
    this.position = 0;
    this.line = 0;
    this.lineStart = 0;
    this.lineIndent = 0;
    this.firstTabInLine = -1;
    this.documents = [];
  }
  function generateError(state, message) {
    var mark2 = {
      name: state.filename,
      buffer: state.input.slice(0, -1),
      // omit trailing \0
      position: state.position,
      line: state.line,
      column: state.position - state.lineStart
    };
    mark2.snippet = snippet(mark2);
    return new exception(message, mark2);
  }
  function throwError(state, message) {
    throw generateError(state, message);
  }
  function throwWarning(state, message) {
    if (state.onWarning) {
      state.onWarning.call(null, generateError(state, message));
    }
  }
  var directiveHandlers = {
    YAML: function handleYamlDirective(state, name2, args) {
      var match, major, minor;
      if (state.version !== null) {
        throwError(state, "duplication of %YAML directive");
      }
      if (args.length !== 1) {
        throwError(state, "YAML directive accepts exactly one argument");
      }
      match = /^([0-9]+)\.([0-9]+)$/.exec(args[0]);
      if (match === null) {
        throwError(state, "ill-formed argument of the YAML directive");
      }
      major = parseInt(match[1], 10);
      minor = parseInt(match[2], 10);
      if (major !== 1) {
        throwError(state, "unacceptable YAML version of the document");
      }
      state.version = args[0];
      state.checkLineBreaks = minor < 2;
      if (minor !== 1 && minor !== 2) {
        throwWarning(state, "unsupported YAML version of the document");
      }
    },
    TAG: function handleTagDirective(state, name2, args) {
      var handle, prefix;
      if (args.length !== 2) {
        throwError(state, "TAG directive accepts exactly two arguments");
      }
      handle = args[0];
      prefix = args[1];
      if (!PATTERN_TAG_HANDLE.test(handle)) {
        throwError(state, "ill-formed tag handle (first argument) of the TAG directive");
      }
      if (_hasOwnProperty$1.call(state.tagMap, handle)) {
        throwError(state, 'there is a previously declared suffix for "' + handle + '" tag handle');
      }
      if (!PATTERN_TAG_URI.test(prefix)) {
        throwError(state, "ill-formed tag prefix (second argument) of the TAG directive");
      }
      try {
        prefix = decodeURIComponent(prefix);
      } catch (err) {
        throwError(state, "tag prefix is malformed: " + prefix);
      }
      state.tagMap[handle] = prefix;
    }
  };
  function captureSegment(state, start, end, checkJson) {
    var _position, _length, _character, _result;
    if (start < end) {
      _result = state.input.slice(start, end);
      if (checkJson) {
        for (_position = 0, _length = _result.length; _position < _length; _position += 1) {
          _character = _result.charCodeAt(_position);
          if (!(_character === 9 || 32 <= _character && _character <= 1114111)) {
            throwError(state, "expected valid JSON character");
          }
        }
      } else if (PATTERN_NON_PRINTABLE.test(_result)) {
        throwError(state, "the stream contains non-printable characters");
      }
      state.result += _result;
    }
  }
  function mergeMappings(state, destination, source, overridableKeys) {
    var sourceKeys, key, index, quantity;
    if (!common.isObject(source)) {
      throwError(state, "cannot merge mappings; the provided source object is unacceptable");
    }
    sourceKeys = Object.keys(source);
    for (index = 0, quantity = sourceKeys.length; index < quantity; index += 1) {
      key = sourceKeys[index];
      if (!_hasOwnProperty$1.call(destination, key)) {
        destination[key] = source[key];
        overridableKeys[key] = true;
      }
    }
  }
  function storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, valueNode, startLine, startLineStart, startPos) {
    var index, quantity;
    if (Array.isArray(keyNode)) {
      keyNode = Array.prototype.slice.call(keyNode);
      for (index = 0, quantity = keyNode.length; index < quantity; index += 1) {
        if (Array.isArray(keyNode[index])) {
          throwError(state, "nested arrays are not supported inside keys");
        }
        if (typeof keyNode === "object" && _class(keyNode[index]) === "[object Object]") {
          keyNode[index] = "[object Object]";
        }
      }
    }
    if (typeof keyNode === "object" && _class(keyNode) === "[object Object]") {
      keyNode = "[object Object]";
    }
    keyNode = String(keyNode);
    if (_result === null) {
      _result = {};
    }
    if (keyTag === "tag:yaml.org,2002:merge") {
      if (Array.isArray(valueNode)) {
        for (index = 0, quantity = valueNode.length; index < quantity; index += 1) {
          mergeMappings(state, _result, valueNode[index], overridableKeys);
        }
      } else {
        mergeMappings(state, _result, valueNode, overridableKeys);
      }
    } else {
      if (!state.json && !_hasOwnProperty$1.call(overridableKeys, keyNode) && _hasOwnProperty$1.call(_result, keyNode)) {
        state.line = startLine || state.line;
        state.lineStart = startLineStart || state.lineStart;
        state.position = startPos || state.position;
        throwError(state, "duplicated mapping key");
      }
      if (keyNode === "__proto__") {
        Object.defineProperty(_result, keyNode, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: valueNode
        });
      } else {
        _result[keyNode] = valueNode;
      }
      delete overridableKeys[keyNode];
    }
    return _result;
  }
  function readLineBreak(state) {
    var ch;
    ch = state.input.charCodeAt(state.position);
    if (ch === 10) {
      state.position++;
    } else if (ch === 13) {
      state.position++;
      if (state.input.charCodeAt(state.position) === 10) {
        state.position++;
      }
    } else {
      throwError(state, "a line break is expected");
    }
    state.line += 1;
    state.lineStart = state.position;
    state.firstTabInLine = -1;
  }
  function skipSeparationSpace(state, allowComments, checkIndent) {
    var lineBreaks = 0, ch = state.input.charCodeAt(state.position);
    while (ch !== 0) {
      while (is_WHITE_SPACE(ch)) {
        if (ch === 9 && state.firstTabInLine === -1) {
          state.firstTabInLine = state.position;
        }
        ch = state.input.charCodeAt(++state.position);
      }
      if (allowComments && ch === 35) {
        do {
          ch = state.input.charCodeAt(++state.position);
        } while (ch !== 10 && ch !== 13 && ch !== 0);
      }
      if (is_EOL(ch)) {
        readLineBreak(state);
        ch = state.input.charCodeAt(state.position);
        lineBreaks++;
        state.lineIndent = 0;
        while (ch === 32) {
          state.lineIndent++;
          ch = state.input.charCodeAt(++state.position);
        }
      } else {
        break;
      }
    }
    if (checkIndent !== -1 && lineBreaks !== 0 && state.lineIndent < checkIndent) {
      throwWarning(state, "deficient indentation");
    }
    return lineBreaks;
  }
  function testDocumentSeparator(state) {
    var _position = state.position, ch;
    ch = state.input.charCodeAt(_position);
    if ((ch === 45 || ch === 46) && ch === state.input.charCodeAt(_position + 1) && ch === state.input.charCodeAt(_position + 2)) {
      _position += 3;
      ch = state.input.charCodeAt(_position);
      if (ch === 0 || is_WS_OR_EOL(ch)) {
        return true;
      }
    }
    return false;
  }
  function writeFoldedLines(state, count) {
    if (count === 1) {
      state.result += " ";
    } else if (count > 1) {
      state.result += common.repeat("\n", count - 1);
    }
  }
  function readPlainScalar(state, nodeIndent, withinFlowCollection) {
    var preceding, following, captureStart, captureEnd, hasPendingContent, _line, _lineStart, _lineIndent, _kind = state.kind, _result = state.result, ch;
    ch = state.input.charCodeAt(state.position);
    if (is_WS_OR_EOL(ch) || is_FLOW_INDICATOR(ch) || ch === 35 || ch === 38 || ch === 42 || ch === 33 || ch === 124 || ch === 62 || ch === 39 || ch === 34 || ch === 37 || ch === 64 || ch === 96) {
      return false;
    }
    if (ch === 63 || ch === 45) {
      following = state.input.charCodeAt(state.position + 1);
      if (is_WS_OR_EOL(following) || withinFlowCollection && is_FLOW_INDICATOR(following)) {
        return false;
      }
    }
    state.kind = "scalar";
    state.result = "";
    captureStart = captureEnd = state.position;
    hasPendingContent = false;
    while (ch !== 0) {
      if (ch === 58) {
        following = state.input.charCodeAt(state.position + 1);
        if (is_WS_OR_EOL(following) || withinFlowCollection && is_FLOW_INDICATOR(following)) {
          break;
        }
      } else if (ch === 35) {
        preceding = state.input.charCodeAt(state.position - 1);
        if (is_WS_OR_EOL(preceding)) {
          break;
        }
      } else if (state.position === state.lineStart && testDocumentSeparator(state) || withinFlowCollection && is_FLOW_INDICATOR(ch)) {
        break;
      } else if (is_EOL(ch)) {
        _line = state.line;
        _lineStart = state.lineStart;
        _lineIndent = state.lineIndent;
        skipSeparationSpace(state, false, -1);
        if (state.lineIndent >= nodeIndent) {
          hasPendingContent = true;
          ch = state.input.charCodeAt(state.position);
          continue;
        } else {
          state.position = captureEnd;
          state.line = _line;
          state.lineStart = _lineStart;
          state.lineIndent = _lineIndent;
          break;
        }
      }
      if (hasPendingContent) {
        captureSegment(state, captureStart, captureEnd, false);
        writeFoldedLines(state, state.line - _line);
        captureStart = captureEnd = state.position;
        hasPendingContent = false;
      }
      if (!is_WHITE_SPACE(ch)) {
        captureEnd = state.position + 1;
      }
      ch = state.input.charCodeAt(++state.position);
    }
    captureSegment(state, captureStart, captureEnd, false);
    if (state.result) {
      return true;
    }
    state.kind = _kind;
    state.result = _result;
    return false;
  }
  function readSingleQuotedScalar(state, nodeIndent) {
    var ch, captureStart, captureEnd;
    ch = state.input.charCodeAt(state.position);
    if (ch !== 39) {
      return false;
    }
    state.kind = "scalar";
    state.result = "";
    state.position++;
    captureStart = captureEnd = state.position;
    while ((ch = state.input.charCodeAt(state.position)) !== 0) {
      if (ch === 39) {
        captureSegment(state, captureStart, state.position, true);
        ch = state.input.charCodeAt(++state.position);
        if (ch === 39) {
          captureStart = state.position;
          state.position++;
          captureEnd = state.position;
        } else {
          return true;
        }
      } else if (is_EOL(ch)) {
        captureSegment(state, captureStart, captureEnd, true);
        writeFoldedLines(state, skipSeparationSpace(state, false, nodeIndent));
        captureStart = captureEnd = state.position;
      } else if (state.position === state.lineStart && testDocumentSeparator(state)) {
        throwError(state, "unexpected end of the document within a single quoted scalar");
      } else {
        state.position++;
        captureEnd = state.position;
      }
    }
    throwError(state, "unexpected end of the stream within a single quoted scalar");
  }
  function readDoubleQuotedScalar(state, nodeIndent) {
    var captureStart, captureEnd, hexLength, hexResult, tmp, ch;
    ch = state.input.charCodeAt(state.position);
    if (ch !== 34) {
      return false;
    }
    state.kind = "scalar";
    state.result = "";
    state.position++;
    captureStart = captureEnd = state.position;
    while ((ch = state.input.charCodeAt(state.position)) !== 0) {
      if (ch === 34) {
        captureSegment(state, captureStart, state.position, true);
        state.position++;
        return true;
      } else if (ch === 92) {
        captureSegment(state, captureStart, state.position, true);
        ch = state.input.charCodeAt(++state.position);
        if (is_EOL(ch)) {
          skipSeparationSpace(state, false, nodeIndent);
        } else if (ch < 256 && simpleEscapeCheck[ch]) {
          state.result += simpleEscapeMap[ch];
          state.position++;
        } else if ((tmp = escapedHexLen(ch)) > 0) {
          hexLength = tmp;
          hexResult = 0;
          for (; hexLength > 0; hexLength--) {
            ch = state.input.charCodeAt(++state.position);
            if ((tmp = fromHexCode(ch)) >= 0) {
              hexResult = (hexResult << 4) + tmp;
            } else {
              throwError(state, "expected hexadecimal character");
            }
          }
          state.result += charFromCodepoint(hexResult);
          state.position++;
        } else {
          throwError(state, "unknown escape sequence");
        }
        captureStart = captureEnd = state.position;
      } else if (is_EOL(ch)) {
        captureSegment(state, captureStart, captureEnd, true);
        writeFoldedLines(state, skipSeparationSpace(state, false, nodeIndent));
        captureStart = captureEnd = state.position;
      } else if (state.position === state.lineStart && testDocumentSeparator(state)) {
        throwError(state, "unexpected end of the document within a double quoted scalar");
      } else {
        state.position++;
        captureEnd = state.position;
      }
    }
    throwError(state, "unexpected end of the stream within a double quoted scalar");
  }
  function readFlowCollection(state, nodeIndent) {
    var readNext = true, _line, _lineStart, _pos, _tag = state.tag, _result, _anchor = state.anchor, following, terminator, isPair, isExplicitPair, isMapping, overridableKeys = /* @__PURE__ */ Object.create(null), keyNode, keyTag, valueNode, ch;
    ch = state.input.charCodeAt(state.position);
    if (ch === 91) {
      terminator = 93;
      isMapping = false;
      _result = [];
    } else if (ch === 123) {
      terminator = 125;
      isMapping = true;
      _result = {};
    } else {
      return false;
    }
    if (state.anchor !== null) {
      state.anchorMap[state.anchor] = _result;
    }
    ch = state.input.charCodeAt(++state.position);
    while (ch !== 0) {
      skipSeparationSpace(state, true, nodeIndent);
      ch = state.input.charCodeAt(state.position);
      if (ch === terminator) {
        state.position++;
        state.tag = _tag;
        state.anchor = _anchor;
        state.kind = isMapping ? "mapping" : "sequence";
        state.result = _result;
        return true;
      } else if (!readNext) {
        throwError(state, "missed comma between flow collection entries");
      } else if (ch === 44) {
        throwError(state, "expected the node content, but found ','");
      }
      keyTag = keyNode = valueNode = null;
      isPair = isExplicitPair = false;
      if (ch === 63) {
        following = state.input.charCodeAt(state.position + 1);
        if (is_WS_OR_EOL(following)) {
          isPair = isExplicitPair = true;
          state.position++;
          skipSeparationSpace(state, true, nodeIndent);
        }
      }
      _line = state.line;
      _lineStart = state.lineStart;
      _pos = state.position;
      composeNode(state, nodeIndent, CONTEXT_FLOW_IN, false, true);
      keyTag = state.tag;
      keyNode = state.result;
      skipSeparationSpace(state, true, nodeIndent);
      ch = state.input.charCodeAt(state.position);
      if ((isExplicitPair || state.line === _line) && ch === 58) {
        isPair = true;
        ch = state.input.charCodeAt(++state.position);
        skipSeparationSpace(state, true, nodeIndent);
        composeNode(state, nodeIndent, CONTEXT_FLOW_IN, false, true);
        valueNode = state.result;
      }
      if (isMapping) {
        storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, valueNode, _line, _lineStart, _pos);
      } else if (isPair) {
        _result.push(storeMappingPair(state, null, overridableKeys, keyTag, keyNode, valueNode, _line, _lineStart, _pos));
      } else {
        _result.push(keyNode);
      }
      skipSeparationSpace(state, true, nodeIndent);
      ch = state.input.charCodeAt(state.position);
      if (ch === 44) {
        readNext = true;
        ch = state.input.charCodeAt(++state.position);
      } else {
        readNext = false;
      }
    }
    throwError(state, "unexpected end of the stream within a flow collection");
  }
  function readBlockScalar(state, nodeIndent) {
    var captureStart, folding, chomping = CHOMPING_CLIP, didReadContent = false, detectedIndent = false, textIndent = nodeIndent, emptyLines = 0, atMoreIndented = false, tmp, ch;
    ch = state.input.charCodeAt(state.position);
    if (ch === 124) {
      folding = false;
    } else if (ch === 62) {
      folding = true;
    } else {
      return false;
    }
    state.kind = "scalar";
    state.result = "";
    while (ch !== 0) {
      ch = state.input.charCodeAt(++state.position);
      if (ch === 43 || ch === 45) {
        if (CHOMPING_CLIP === chomping) {
          chomping = ch === 43 ? CHOMPING_KEEP : CHOMPING_STRIP;
        } else {
          throwError(state, "repeat of a chomping mode identifier");
        }
      } else if ((tmp = fromDecimalCode(ch)) >= 0) {
        if (tmp === 0) {
          throwError(state, "bad explicit indentation width of a block scalar; it cannot be less than one");
        } else if (!detectedIndent) {
          textIndent = nodeIndent + tmp - 1;
          detectedIndent = true;
        } else {
          throwError(state, "repeat of an indentation width identifier");
        }
      } else {
        break;
      }
    }
    if (is_WHITE_SPACE(ch)) {
      do {
        ch = state.input.charCodeAt(++state.position);
      } while (is_WHITE_SPACE(ch));
      if (ch === 35) {
        do {
          ch = state.input.charCodeAt(++state.position);
        } while (!is_EOL(ch) && ch !== 0);
      }
    }
    while (ch !== 0) {
      readLineBreak(state);
      state.lineIndent = 0;
      ch = state.input.charCodeAt(state.position);
      while ((!detectedIndent || state.lineIndent < textIndent) && ch === 32) {
        state.lineIndent++;
        ch = state.input.charCodeAt(++state.position);
      }
      if (!detectedIndent && state.lineIndent > textIndent) {
        textIndent = state.lineIndent;
      }
      if (is_EOL(ch)) {
        emptyLines++;
        continue;
      }
      if (state.lineIndent < textIndent) {
        if (chomping === CHOMPING_KEEP) {
          state.result += common.repeat("\n", didReadContent ? 1 + emptyLines : emptyLines);
        } else if (chomping === CHOMPING_CLIP) {
          if (didReadContent) {
            state.result += "\n";
          }
        }
        break;
      }
      if (folding) {
        if (is_WHITE_SPACE(ch)) {
          atMoreIndented = true;
          state.result += common.repeat("\n", didReadContent ? 1 + emptyLines : emptyLines);
        } else if (atMoreIndented) {
          atMoreIndented = false;
          state.result += common.repeat("\n", emptyLines + 1);
        } else if (emptyLines === 0) {
          if (didReadContent) {
            state.result += " ";
          }
        } else {
          state.result += common.repeat("\n", emptyLines);
        }
      } else {
        state.result += common.repeat("\n", didReadContent ? 1 + emptyLines : emptyLines);
      }
      didReadContent = true;
      detectedIndent = true;
      emptyLines = 0;
      captureStart = state.position;
      while (!is_EOL(ch) && ch !== 0) {
        ch = state.input.charCodeAt(++state.position);
      }
      captureSegment(state, captureStart, state.position, false);
    }
    return true;
  }
  function readBlockSequence(state, nodeIndent) {
    var _line, _tag = state.tag, _anchor = state.anchor, _result = [], following, detected = false, ch;
    if (state.firstTabInLine !== -1)
      return false;
    if (state.anchor !== null) {
      state.anchorMap[state.anchor] = _result;
    }
    ch = state.input.charCodeAt(state.position);
    while (ch !== 0) {
      if (state.firstTabInLine !== -1) {
        state.position = state.firstTabInLine;
        throwError(state, "tab characters must not be used in indentation");
      }
      if (ch !== 45) {
        break;
      }
      following = state.input.charCodeAt(state.position + 1);
      if (!is_WS_OR_EOL(following)) {
        break;
      }
      detected = true;
      state.position++;
      if (skipSeparationSpace(state, true, -1)) {
        if (state.lineIndent <= nodeIndent) {
          _result.push(null);
          ch = state.input.charCodeAt(state.position);
          continue;
        }
      }
      _line = state.line;
      composeNode(state, nodeIndent, CONTEXT_BLOCK_IN, false, true);
      _result.push(state.result);
      skipSeparationSpace(state, true, -1);
      ch = state.input.charCodeAt(state.position);
      if ((state.line === _line || state.lineIndent > nodeIndent) && ch !== 0) {
        throwError(state, "bad indentation of a sequence entry");
      } else if (state.lineIndent < nodeIndent) {
        break;
      }
    }
    if (detected) {
      state.tag = _tag;
      state.anchor = _anchor;
      state.kind = "sequence";
      state.result = _result;
      return true;
    }
    return false;
  }
  function readBlockMapping(state, nodeIndent, flowIndent) {
    var following, allowCompact, _line, _keyLine, _keyLineStart, _keyPos, _tag = state.tag, _anchor = state.anchor, _result = {}, overridableKeys = /* @__PURE__ */ Object.create(null), keyTag = null, keyNode = null, valueNode = null, atExplicitKey = false, detected = false, ch;
    if (state.firstTabInLine !== -1)
      return false;
    if (state.anchor !== null) {
      state.anchorMap[state.anchor] = _result;
    }
    ch = state.input.charCodeAt(state.position);
    while (ch !== 0) {
      if (!atExplicitKey && state.firstTabInLine !== -1) {
        state.position = state.firstTabInLine;
        throwError(state, "tab characters must not be used in indentation");
      }
      following = state.input.charCodeAt(state.position + 1);
      _line = state.line;
      if ((ch === 63 || ch === 58) && is_WS_OR_EOL(following)) {
        if (ch === 63) {
          if (atExplicitKey) {
            storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, null, _keyLine, _keyLineStart, _keyPos);
            keyTag = keyNode = valueNode = null;
          }
          detected = true;
          atExplicitKey = true;
          allowCompact = true;
        } else if (atExplicitKey) {
          atExplicitKey = false;
          allowCompact = true;
        } else {
          throwError(state, "incomplete explicit mapping pair; a key node is missed; or followed by a non-tabulated empty line");
        }
        state.position += 1;
        ch = following;
      } else {
        _keyLine = state.line;
        _keyLineStart = state.lineStart;
        _keyPos = state.position;
        if (!composeNode(state, flowIndent, CONTEXT_FLOW_OUT, false, true)) {
          break;
        }
        if (state.line === _line) {
          ch = state.input.charCodeAt(state.position);
          while (is_WHITE_SPACE(ch)) {
            ch = state.input.charCodeAt(++state.position);
          }
          if (ch === 58) {
            ch = state.input.charCodeAt(++state.position);
            if (!is_WS_OR_EOL(ch)) {
              throwError(state, "a whitespace character is expected after the key-value separator within a block mapping");
            }
            if (atExplicitKey) {
              storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, null, _keyLine, _keyLineStart, _keyPos);
              keyTag = keyNode = valueNode = null;
            }
            detected = true;
            atExplicitKey = false;
            allowCompact = false;
            keyTag = state.tag;
            keyNode = state.result;
          } else if (detected) {
            throwError(state, "can not read an implicit mapping pair; a colon is missed");
          } else {
            state.tag = _tag;
            state.anchor = _anchor;
            return true;
          }
        } else if (detected) {
          throwError(state, "can not read a block mapping entry; a multiline key may not be an implicit key");
        } else {
          state.tag = _tag;
          state.anchor = _anchor;
          return true;
        }
      }
      if (state.line === _line || state.lineIndent > nodeIndent) {
        if (atExplicitKey) {
          _keyLine = state.line;
          _keyLineStart = state.lineStart;
          _keyPos = state.position;
        }
        if (composeNode(state, nodeIndent, CONTEXT_BLOCK_OUT, true, allowCompact)) {
          if (atExplicitKey) {
            keyNode = state.result;
          } else {
            valueNode = state.result;
          }
        }
        if (!atExplicitKey) {
          storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, valueNode, _keyLine, _keyLineStart, _keyPos);
          keyTag = keyNode = valueNode = null;
        }
        skipSeparationSpace(state, true, -1);
        ch = state.input.charCodeAt(state.position);
      }
      if ((state.line === _line || state.lineIndent > nodeIndent) && ch !== 0) {
        throwError(state, "bad indentation of a mapping entry");
      } else if (state.lineIndent < nodeIndent) {
        break;
      }
    }
    if (atExplicitKey) {
      storeMappingPair(state, _result, overridableKeys, keyTag, keyNode, null, _keyLine, _keyLineStart, _keyPos);
    }
    if (detected) {
      state.tag = _tag;
      state.anchor = _anchor;
      state.kind = "mapping";
      state.result = _result;
    }
    return detected;
  }
  function readTagProperty(state) {
    var _position, isVerbatim = false, isNamed = false, tagHandle, tagName, ch;
    ch = state.input.charCodeAt(state.position);
    if (ch !== 33)
      return false;
    if (state.tag !== null) {
      throwError(state, "duplication of a tag property");
    }
    ch = state.input.charCodeAt(++state.position);
    if (ch === 60) {
      isVerbatim = true;
      ch = state.input.charCodeAt(++state.position);
    } else if (ch === 33) {
      isNamed = true;
      tagHandle = "!!";
      ch = state.input.charCodeAt(++state.position);
    } else {
      tagHandle = "!";
    }
    _position = state.position;
    if (isVerbatim) {
      do {
        ch = state.input.charCodeAt(++state.position);
      } while (ch !== 0 && ch !== 62);
      if (state.position < state.length) {
        tagName = state.input.slice(_position, state.position);
        ch = state.input.charCodeAt(++state.position);
      } else {
        throwError(state, "unexpected end of the stream within a verbatim tag");
      }
    } else {
      while (ch !== 0 && !is_WS_OR_EOL(ch)) {
        if (ch === 33) {
          if (!isNamed) {
            tagHandle = state.input.slice(_position - 1, state.position + 1);
            if (!PATTERN_TAG_HANDLE.test(tagHandle)) {
              throwError(state, "named tag handle cannot contain such characters");
            }
            isNamed = true;
            _position = state.position + 1;
          } else {
            throwError(state, "tag suffix cannot contain exclamation marks");
          }
        }
        ch = state.input.charCodeAt(++state.position);
      }
      tagName = state.input.slice(_position, state.position);
      if (PATTERN_FLOW_INDICATORS.test(tagName)) {
        throwError(state, "tag suffix cannot contain flow indicator characters");
      }
    }
    if (tagName && !PATTERN_TAG_URI.test(tagName)) {
      throwError(state, "tag name cannot contain such characters: " + tagName);
    }
    try {
      tagName = decodeURIComponent(tagName);
    } catch (err) {
      throwError(state, "tag name is malformed: " + tagName);
    }
    if (isVerbatim) {
      state.tag = tagName;
    } else if (_hasOwnProperty$1.call(state.tagMap, tagHandle)) {
      state.tag = state.tagMap[tagHandle] + tagName;
    } else if (tagHandle === "!") {
      state.tag = "!" + tagName;
    } else if (tagHandle === "!!") {
      state.tag = "tag:yaml.org,2002:" + tagName;
    } else {
      throwError(state, 'undeclared tag handle "' + tagHandle + '"');
    }
    return true;
  }
  function readAnchorProperty(state) {
    var _position, ch;
    ch = state.input.charCodeAt(state.position);
    if (ch !== 38)
      return false;
    if (state.anchor !== null) {
      throwError(state, "duplication of an anchor property");
    }
    ch = state.input.charCodeAt(++state.position);
    _position = state.position;
    while (ch !== 0 && !is_WS_OR_EOL(ch) && !is_FLOW_INDICATOR(ch)) {
      ch = state.input.charCodeAt(++state.position);
    }
    if (state.position === _position) {
      throwError(state, "name of an anchor node must contain at least one character");
    }
    state.anchor = state.input.slice(_position, state.position);
    return true;
  }
  function readAlias(state) {
    var _position, alias, ch;
    ch = state.input.charCodeAt(state.position);
    if (ch !== 42)
      return false;
    ch = state.input.charCodeAt(++state.position);
    _position = state.position;
    while (ch !== 0 && !is_WS_OR_EOL(ch) && !is_FLOW_INDICATOR(ch)) {
      ch = state.input.charCodeAt(++state.position);
    }
    if (state.position === _position) {
      throwError(state, "name of an alias node must contain at least one character");
    }
    alias = state.input.slice(_position, state.position);
    if (!_hasOwnProperty$1.call(state.anchorMap, alias)) {
      throwError(state, 'unidentified alias "' + alias + '"');
    }
    state.result = state.anchorMap[alias];
    skipSeparationSpace(state, true, -1);
    return true;
  }
  function composeNode(state, parentIndent, nodeContext, allowToSeek, allowCompact) {
    var allowBlockStyles, allowBlockScalars, allowBlockCollections, indentStatus = 1, atNewLine = false, hasContent = false, typeIndex, typeQuantity, typeList, type2, flowIndent, blockIndent;
    if (state.listener !== null) {
      state.listener("open", state);
    }
    state.tag = null;
    state.anchor = null;
    state.kind = null;
    state.result = null;
    allowBlockStyles = allowBlockScalars = allowBlockCollections = CONTEXT_BLOCK_OUT === nodeContext || CONTEXT_BLOCK_IN === nodeContext;
    if (allowToSeek) {
      if (skipSeparationSpace(state, true, -1)) {
        atNewLine = true;
        if (state.lineIndent > parentIndent) {
          indentStatus = 1;
        } else if (state.lineIndent === parentIndent) {
          indentStatus = 0;
        } else if (state.lineIndent < parentIndent) {
          indentStatus = -1;
        }
      }
    }
    if (indentStatus === 1) {
      while (readTagProperty(state) || readAnchorProperty(state)) {
        if (skipSeparationSpace(state, true, -1)) {
          atNewLine = true;
          allowBlockCollections = allowBlockStyles;
          if (state.lineIndent > parentIndent) {
            indentStatus = 1;
          } else if (state.lineIndent === parentIndent) {
            indentStatus = 0;
          } else if (state.lineIndent < parentIndent) {
            indentStatus = -1;
          }
        } else {
          allowBlockCollections = false;
        }
      }
    }
    if (allowBlockCollections) {
      allowBlockCollections = atNewLine || allowCompact;
    }
    if (indentStatus === 1 || CONTEXT_BLOCK_OUT === nodeContext) {
      if (CONTEXT_FLOW_IN === nodeContext || CONTEXT_FLOW_OUT === nodeContext) {
        flowIndent = parentIndent;
      } else {
        flowIndent = parentIndent + 1;
      }
      blockIndent = state.position - state.lineStart;
      if (indentStatus === 1) {
        if (allowBlockCollections && (readBlockSequence(state, blockIndent) || readBlockMapping(state, blockIndent, flowIndent)) || readFlowCollection(state, flowIndent)) {
          hasContent = true;
        } else {
          if (allowBlockScalars && readBlockScalar(state, flowIndent) || readSingleQuotedScalar(state, flowIndent) || readDoubleQuotedScalar(state, flowIndent)) {
            hasContent = true;
          } else if (readAlias(state)) {
            hasContent = true;
            if (state.tag !== null || state.anchor !== null) {
              throwError(state, "alias node should not have any properties");
            }
          } else if (readPlainScalar(state, flowIndent, CONTEXT_FLOW_IN === nodeContext)) {
            hasContent = true;
            if (state.tag === null) {
              state.tag = "?";
            }
          }
          if (state.anchor !== null) {
            state.anchorMap[state.anchor] = state.result;
          }
        }
      } else if (indentStatus === 0) {
        hasContent = allowBlockCollections && readBlockSequence(state, blockIndent);
      }
    }
    if (state.tag === null) {
      if (state.anchor !== null) {
        state.anchorMap[state.anchor] = state.result;
      }
    } else if (state.tag === "?") {
      if (state.result !== null && state.kind !== "scalar") {
        throwError(state, 'unacceptable node kind for !<?> tag; it should be "scalar", not "' + state.kind + '"');
      }
      for (typeIndex = 0, typeQuantity = state.implicitTypes.length; typeIndex < typeQuantity; typeIndex += 1) {
        type2 = state.implicitTypes[typeIndex];
        if (type2.resolve(state.result)) {
          state.result = type2.construct(state.result);
          state.tag = type2.tag;
          if (state.anchor !== null) {
            state.anchorMap[state.anchor] = state.result;
          }
          break;
        }
      }
    } else if (state.tag !== "!") {
      if (_hasOwnProperty$1.call(state.typeMap[state.kind || "fallback"], state.tag)) {
        type2 = state.typeMap[state.kind || "fallback"][state.tag];
      } else {
        type2 = null;
        typeList = state.typeMap.multi[state.kind || "fallback"];
        for (typeIndex = 0, typeQuantity = typeList.length; typeIndex < typeQuantity; typeIndex += 1) {
          if (state.tag.slice(0, typeList[typeIndex].tag.length) === typeList[typeIndex].tag) {
            type2 = typeList[typeIndex];
            break;
          }
        }
      }
      if (!type2) {
        throwError(state, "unknown tag !<" + state.tag + ">");
      }
      if (state.result !== null && type2.kind !== state.kind) {
        throwError(state, "unacceptable node kind for !<" + state.tag + '> tag; it should be "' + type2.kind + '", not "' + state.kind + '"');
      }
      if (!type2.resolve(state.result, state.tag)) {
        throwError(state, "cannot resolve a node with !<" + state.tag + "> explicit tag");
      } else {
        state.result = type2.construct(state.result, state.tag);
        if (state.anchor !== null) {
          state.anchorMap[state.anchor] = state.result;
        }
      }
    }
    if (state.listener !== null) {
      state.listener("close", state);
    }
    return state.tag !== null || state.anchor !== null || hasContent;
  }
  function readDocument(state) {
    var documentStart = state.position, _position, directiveName, directiveArgs, hasDirectives = false, ch;
    state.version = null;
    state.checkLineBreaks = state.legacy;
    state.tagMap = /* @__PURE__ */ Object.create(null);
    state.anchorMap = /* @__PURE__ */ Object.create(null);
    while ((ch = state.input.charCodeAt(state.position)) !== 0) {
      skipSeparationSpace(state, true, -1);
      ch = state.input.charCodeAt(state.position);
      if (state.lineIndent > 0 || ch !== 37) {
        break;
      }
      hasDirectives = true;
      ch = state.input.charCodeAt(++state.position);
      _position = state.position;
      while (ch !== 0 && !is_WS_OR_EOL(ch)) {
        ch = state.input.charCodeAt(++state.position);
      }
      directiveName = state.input.slice(_position, state.position);
      directiveArgs = [];
      if (directiveName.length < 1) {
        throwError(state, "directive name must not be less than one character in length");
      }
      while (ch !== 0) {
        while (is_WHITE_SPACE(ch)) {
          ch = state.input.charCodeAt(++state.position);
        }
        if (ch === 35) {
          do {
            ch = state.input.charCodeAt(++state.position);
          } while (ch !== 0 && !is_EOL(ch));
          break;
        }
        if (is_EOL(ch))
          break;
        _position = state.position;
        while (ch !== 0 && !is_WS_OR_EOL(ch)) {
          ch = state.input.charCodeAt(++state.position);
        }
        directiveArgs.push(state.input.slice(_position, state.position));
      }
      if (ch !== 0)
        readLineBreak(state);
      if (_hasOwnProperty$1.call(directiveHandlers, directiveName)) {
        directiveHandlers[directiveName](state, directiveName, directiveArgs);
      } else {
        throwWarning(state, 'unknown document directive "' + directiveName + '"');
      }
    }
    skipSeparationSpace(state, true, -1);
    if (state.lineIndent === 0 && state.input.charCodeAt(state.position) === 45 && state.input.charCodeAt(state.position + 1) === 45 && state.input.charCodeAt(state.position + 2) === 45) {
      state.position += 3;
      skipSeparationSpace(state, true, -1);
    } else if (hasDirectives) {
      throwError(state, "directives end mark is expected");
    }
    composeNode(state, state.lineIndent - 1, CONTEXT_BLOCK_OUT, false, true);
    skipSeparationSpace(state, true, -1);
    if (state.checkLineBreaks && PATTERN_NON_ASCII_LINE_BREAKS.test(state.input.slice(documentStart, state.position))) {
      throwWarning(state, "non-ASCII line breaks are interpreted as content");
    }
    state.documents.push(state.result);
    if (state.position === state.lineStart && testDocumentSeparator(state)) {
      if (state.input.charCodeAt(state.position) === 46) {
        state.position += 3;
        skipSeparationSpace(state, true, -1);
      }
      return;
    }
    if (state.position < state.length - 1) {
      throwError(state, "end of the stream or a document separator is expected");
    } else {
      return;
    }
  }
  function loadDocuments(input, options) {
    input = String(input);
    options = options || {};
    if (input.length !== 0) {
      if (input.charCodeAt(input.length - 1) !== 10 && input.charCodeAt(input.length - 1) !== 13) {
        input += "\n";
      }
      if (input.charCodeAt(0) === 65279) {
        input = input.slice(1);
      }
    }
    var state = new State$1(input, options);
    var nullpos = input.indexOf("\0");
    if (nullpos !== -1) {
      state.position = nullpos;
      throwError(state, "null byte is not allowed in input");
    }
    state.input += "\0";
    while (state.input.charCodeAt(state.position) === 32) {
      state.lineIndent += 1;
      state.position += 1;
    }
    while (state.position < state.length - 1) {
      readDocument(state);
    }
    return state.documents;
  }
  function loadAll$1(input, iterator, options) {
    if (iterator !== null && typeof iterator === "object" && typeof options === "undefined") {
      options = iterator;
      iterator = null;
    }
    var documents = loadDocuments(input, options);
    if (typeof iterator !== "function") {
      return documents;
    }
    for (var index = 0, length = documents.length; index < length; index += 1) {
      iterator(documents[index]);
    }
  }
  function load$1(input, options) {
    var documents = loadDocuments(input, options);
    if (documents.length === 0) {
      return void 0;
    } else if (documents.length === 1) {
      return documents[0];
    }
    throw new exception("expected a single document in the stream, but found more");
  }
  var loadAll_1 = loadAll$1;
  var load_1 = load$1;
  var loader = {
    loadAll: loadAll_1,
    load: load_1
  };
  var _toString = Object.prototype.toString;
  var _hasOwnProperty = Object.prototype.hasOwnProperty;
  var CHAR_BOM = 65279;
  var CHAR_TAB = 9;
  var CHAR_LINE_FEED = 10;
  var CHAR_CARRIAGE_RETURN = 13;
  var CHAR_SPACE = 32;
  var CHAR_EXCLAMATION = 33;
  var CHAR_DOUBLE_QUOTE = 34;
  var CHAR_SHARP = 35;
  var CHAR_PERCENT = 37;
  var CHAR_AMPERSAND = 38;
  var CHAR_SINGLE_QUOTE = 39;
  var CHAR_ASTERISK = 42;
  var CHAR_COMMA = 44;
  var CHAR_MINUS = 45;
  var CHAR_COLON = 58;
  var CHAR_EQUALS = 61;
  var CHAR_GREATER_THAN = 62;
  var CHAR_QUESTION = 63;
  var CHAR_COMMERCIAL_AT = 64;
  var CHAR_LEFT_SQUARE_BRACKET = 91;
  var CHAR_RIGHT_SQUARE_BRACKET = 93;
  var CHAR_GRAVE_ACCENT = 96;
  var CHAR_LEFT_CURLY_BRACKET = 123;
  var CHAR_VERTICAL_LINE = 124;
  var CHAR_RIGHT_CURLY_BRACKET = 125;
  var ESCAPE_SEQUENCES = {};
  ESCAPE_SEQUENCES[0] = "\\0";
  ESCAPE_SEQUENCES[7] = "\\a";
  ESCAPE_SEQUENCES[8] = "\\b";
  ESCAPE_SEQUENCES[9] = "\\t";
  ESCAPE_SEQUENCES[10] = "\\n";
  ESCAPE_SEQUENCES[11] = "\\v";
  ESCAPE_SEQUENCES[12] = "\\f";
  ESCAPE_SEQUENCES[13] = "\\r";
  ESCAPE_SEQUENCES[27] = "\\e";
  ESCAPE_SEQUENCES[34] = '\\"';
  ESCAPE_SEQUENCES[92] = "\\\\";
  ESCAPE_SEQUENCES[133] = "\\N";
  ESCAPE_SEQUENCES[160] = "\\_";
  ESCAPE_SEQUENCES[8232] = "\\L";
  ESCAPE_SEQUENCES[8233] = "\\P";
  var DEPRECATED_BOOLEANS_SYNTAX = [
    "y",
    "Y",
    "yes",
    "Yes",
    "YES",
    "on",
    "On",
    "ON",
    "n",
    "N",
    "no",
    "No",
    "NO",
    "off",
    "Off",
    "OFF"
  ];
  var DEPRECATED_BASE60_SYNTAX = /^[-+]?[0-9_]+(?::[0-9_]+)+(?:\.[0-9_]*)?$/;
  function compileStyleMap(schema2, map2) {
    var result, keys, index, length, tag, style, type2;
    if (map2 === null)
      return {};
    result = {};
    keys = Object.keys(map2);
    for (index = 0, length = keys.length; index < length; index += 1) {
      tag = keys[index];
      style = String(map2[tag]);
      if (tag.slice(0, 2) === "!!") {
        tag = "tag:yaml.org,2002:" + tag.slice(2);
      }
      type2 = schema2.compiledTypeMap["fallback"][tag];
      if (type2 && _hasOwnProperty.call(type2.styleAliases, style)) {
        style = type2.styleAliases[style];
      }
      result[tag] = style;
    }
    return result;
  }
  function encodeHex(character) {
    var string, handle, length;
    string = character.toString(16).toUpperCase();
    if (character <= 255) {
      handle = "x";
      length = 2;
    } else if (character <= 65535) {
      handle = "u";
      length = 4;
    } else if (character <= 4294967295) {
      handle = "U";
      length = 8;
    } else {
      throw new exception("code point within a string may not be greater than 0xFFFFFFFF");
    }
    return "\\" + handle + common.repeat("0", length - string.length) + string;
  }
  var QUOTING_TYPE_SINGLE = 1, QUOTING_TYPE_DOUBLE = 2;
  function State(options) {
    this.schema = options["schema"] || _default;
    this.indent = Math.max(1, options["indent"] || 2);
    this.noArrayIndent = options["noArrayIndent"] || false;
    this.skipInvalid = options["skipInvalid"] || false;
    this.flowLevel = common.isNothing(options["flowLevel"]) ? -1 : options["flowLevel"];
    this.styleMap = compileStyleMap(this.schema, options["styles"] || null);
    this.sortKeys = options["sortKeys"] || false;
    this.lineWidth = options["lineWidth"] || 80;
    this.noRefs = options["noRefs"] || false;
    this.noCompatMode = options["noCompatMode"] || false;
    this.condenseFlow = options["condenseFlow"] || false;
    this.quotingType = options["quotingType"] === '"' ? QUOTING_TYPE_DOUBLE : QUOTING_TYPE_SINGLE;
    this.forceQuotes = options["forceQuotes"] || false;
    this.replacer = typeof options["replacer"] === "function" ? options["replacer"] : null;
    this.implicitTypes = this.schema.compiledImplicit;
    this.explicitTypes = this.schema.compiledExplicit;
    this.tag = null;
    this.result = "";
    this.duplicates = [];
    this.usedDuplicates = null;
  }
  function indentString(string, spaces) {
    var ind = common.repeat(" ", spaces), position = 0, next = -1, result = "", line, length = string.length;
    while (position < length) {
      next = string.indexOf("\n", position);
      if (next === -1) {
        line = string.slice(position);
        position = length;
      } else {
        line = string.slice(position, next + 1);
        position = next + 1;
      }
      if (line.length && line !== "\n")
        result += ind;
      result += line;
    }
    return result;
  }
  function generateNextLine(state, level) {
    return "\n" + common.repeat(" ", state.indent * level);
  }
  function testImplicitResolving(state, str2) {
    var index, length, type2;
    for (index = 0, length = state.implicitTypes.length; index < length; index += 1) {
      type2 = state.implicitTypes[index];
      if (type2.resolve(str2)) {
        return true;
      }
    }
    return false;
  }
  function isWhitespace(c) {
    return c === CHAR_SPACE || c === CHAR_TAB;
  }
  function isPrintable(c) {
    return 32 <= c && c <= 126 || 161 <= c && c <= 55295 && c !== 8232 && c !== 8233 || 57344 <= c && c <= 65533 && c !== CHAR_BOM || 65536 <= c && c <= 1114111;
  }
  function isNsCharOrWhitespace(c) {
    return isPrintable(c) && c !== CHAR_BOM && c !== CHAR_CARRIAGE_RETURN && c !== CHAR_LINE_FEED;
  }
  function isPlainSafe(c, prev, inblock) {
    var cIsNsCharOrWhitespace = isNsCharOrWhitespace(c);
    var cIsNsChar = cIsNsCharOrWhitespace && !isWhitespace(c);
    return (
      // ns-plain-safe
      (inblock ? (
        // c = flow-in
        cIsNsCharOrWhitespace
      ) : cIsNsCharOrWhitespace && c !== CHAR_COMMA && c !== CHAR_LEFT_SQUARE_BRACKET && c !== CHAR_RIGHT_SQUARE_BRACKET && c !== CHAR_LEFT_CURLY_BRACKET && c !== CHAR_RIGHT_CURLY_BRACKET) && c !== CHAR_SHARP && !(prev === CHAR_COLON && !cIsNsChar) || isNsCharOrWhitespace(prev) && !isWhitespace(prev) && c === CHAR_SHARP || prev === CHAR_COLON && cIsNsChar
    );
  }
  function isPlainSafeFirst(c) {
    return isPrintable(c) && c !== CHAR_BOM && !isWhitespace(c) && c !== CHAR_MINUS && c !== CHAR_QUESTION && c !== CHAR_COLON && c !== CHAR_COMMA && c !== CHAR_LEFT_SQUARE_BRACKET && c !== CHAR_RIGHT_SQUARE_BRACKET && c !== CHAR_LEFT_CURLY_BRACKET && c !== CHAR_RIGHT_CURLY_BRACKET && c !== CHAR_SHARP && c !== CHAR_AMPERSAND && c !== CHAR_ASTERISK && c !== CHAR_EXCLAMATION && c !== CHAR_VERTICAL_LINE && c !== CHAR_EQUALS && c !== CHAR_GREATER_THAN && c !== CHAR_SINGLE_QUOTE && c !== CHAR_DOUBLE_QUOTE && c !== CHAR_PERCENT && c !== CHAR_COMMERCIAL_AT && c !== CHAR_GRAVE_ACCENT;
  }
  function isPlainSafeLast(c) {
    return !isWhitespace(c) && c !== CHAR_COLON;
  }
  function codePointAt(string, pos) {
    var first = string.charCodeAt(pos), second;
    if (first >= 55296 && first <= 56319 && pos + 1 < string.length) {
      second = string.charCodeAt(pos + 1);
      if (second >= 56320 && second <= 57343) {
        return (first - 55296) * 1024 + second - 56320 + 65536;
      }
    }
    return first;
  }
  function needIndentIndicator(string) {
    var leadingSpaceRe = /^\n* /;
    return leadingSpaceRe.test(string);
  }
  var STYLE_PLAIN = 1, STYLE_SINGLE = 2, STYLE_LITERAL = 3, STYLE_FOLDED = 4, STYLE_DOUBLE = 5;
  function chooseScalarStyle(string, singleLineOnly, indentPerLevel, lineWidth, testAmbiguousType, quotingType, forceQuotes, inblock) {
    var i2;
    var char = 0;
    var prevChar = null;
    var hasLineBreak = false;
    var hasFoldableLine = false;
    var shouldTrackWidth = lineWidth !== -1;
    var previousLineBreak = -1;
    var plain = isPlainSafeFirst(codePointAt(string, 0)) && isPlainSafeLast(codePointAt(string, string.length - 1));
    if (singleLineOnly || forceQuotes) {
      for (i2 = 0; i2 < string.length; char >= 65536 ? i2 += 2 : i2++) {
        char = codePointAt(string, i2);
        if (!isPrintable(char)) {
          return STYLE_DOUBLE;
        }
        plain = plain && isPlainSafe(char, prevChar, inblock);
        prevChar = char;
      }
    } else {
      for (i2 = 0; i2 < string.length; char >= 65536 ? i2 += 2 : i2++) {
        char = codePointAt(string, i2);
        if (char === CHAR_LINE_FEED) {
          hasLineBreak = true;
          if (shouldTrackWidth) {
            hasFoldableLine = hasFoldableLine || // Foldable line = too long, and not more-indented.
            i2 - previousLineBreak - 1 > lineWidth && string[previousLineBreak + 1] !== " ";
            previousLineBreak = i2;
          }
        } else if (!isPrintable(char)) {
          return STYLE_DOUBLE;
        }
        plain = plain && isPlainSafe(char, prevChar, inblock);
        prevChar = char;
      }
      hasFoldableLine = hasFoldableLine || shouldTrackWidth && (i2 - previousLineBreak - 1 > lineWidth && string[previousLineBreak + 1] !== " ");
    }
    if (!hasLineBreak && !hasFoldableLine) {
      if (plain && !forceQuotes && !testAmbiguousType(string)) {
        return STYLE_PLAIN;
      }
      return quotingType === QUOTING_TYPE_DOUBLE ? STYLE_DOUBLE : STYLE_SINGLE;
    }
    if (indentPerLevel > 9 && needIndentIndicator(string)) {
      return STYLE_DOUBLE;
    }
    if (!forceQuotes) {
      return hasFoldableLine ? STYLE_FOLDED : STYLE_LITERAL;
    }
    return quotingType === QUOTING_TYPE_DOUBLE ? STYLE_DOUBLE : STYLE_SINGLE;
  }
  function writeScalar(state, string, level, iskey, inblock) {
    state.dump = function() {
      if (string.length === 0) {
        return state.quotingType === QUOTING_TYPE_DOUBLE ? '""' : "''";
      }
      if (!state.noCompatMode) {
        if (DEPRECATED_BOOLEANS_SYNTAX.indexOf(string) !== -1 || DEPRECATED_BASE60_SYNTAX.test(string)) {
          return state.quotingType === QUOTING_TYPE_DOUBLE ? '"' + string + '"' : "'" + string + "'";
        }
      }
      var indent = state.indent * Math.max(1, level);
      var lineWidth = state.lineWidth === -1 ? -1 : Math.max(Math.min(state.lineWidth, 40), state.lineWidth - indent);
      var singleLineOnly = iskey || state.flowLevel > -1 && level >= state.flowLevel;
      function testAmbiguity(string2) {
        return testImplicitResolving(state, string2);
      }
      switch (chooseScalarStyle(
        string,
        singleLineOnly,
        state.indent,
        lineWidth,
        testAmbiguity,
        state.quotingType,
        state.forceQuotes && !iskey,
        inblock
      )) {
        case STYLE_PLAIN:
          return string;
        case STYLE_SINGLE:
          return "'" + string.replace(/'/g, "''") + "'";
        case STYLE_LITERAL:
          return "|" + blockHeader(string, state.indent) + dropEndingNewline(indentString(string, indent));
        case STYLE_FOLDED:
          return ">" + blockHeader(string, state.indent) + dropEndingNewline(indentString(foldString(string, lineWidth), indent));
        case STYLE_DOUBLE:
          return '"' + escapeString(string) + '"';
        default:
          throw new exception("impossible error: invalid scalar style");
      }
    }();
  }
  function blockHeader(string, indentPerLevel) {
    var indentIndicator = needIndentIndicator(string) ? String(indentPerLevel) : "";
    var clip = string[string.length - 1] === "\n";
    var keep = clip && (string[string.length - 2] === "\n" || string === "\n");
    var chomp = keep ? "+" : clip ? "" : "-";
    return indentIndicator + chomp + "\n";
  }
  function dropEndingNewline(string) {
    return string[string.length - 1] === "\n" ? string.slice(0, -1) : string;
  }
  function foldString(string, width) {
    var lineRe = /(\n+)([^\n]*)/g;
    var result = function() {
      var nextLF = string.indexOf("\n");
      nextLF = nextLF !== -1 ? nextLF : string.length;
      lineRe.lastIndex = nextLF;
      return foldLine(string.slice(0, nextLF), width);
    }();
    var prevMoreIndented = string[0] === "\n" || string[0] === " ";
    var moreIndented;
    var match;
    while (match = lineRe.exec(string)) {
      var prefix = match[1], line = match[2];
      moreIndented = line[0] === " ";
      result += prefix + (!prevMoreIndented && !moreIndented && line !== "" ? "\n" : "") + foldLine(line, width);
      prevMoreIndented = moreIndented;
    }
    return result;
  }
  function foldLine(line, width) {
    if (line === "" || line[0] === " ")
      return line;
    var breakRe = / [^ ]/g;
    var match;
    var start = 0, end, curr = 0, next = 0;
    var result = "";
    while (match = breakRe.exec(line)) {
      next = match.index;
      if (next - start > width) {
        end = curr > start ? curr : next;
        result += "\n" + line.slice(start, end);
        start = end + 1;
      }
      curr = next;
    }
    result += "\n";
    if (line.length - start > width && curr > start) {
      result += line.slice(start, curr) + "\n" + line.slice(curr + 1);
    } else {
      result += line.slice(start);
    }
    return result.slice(1);
  }
  function escapeString(string) {
    var result = "";
    var char = 0;
    var escapeSeq;
    for (var i2 = 0; i2 < string.length; char >= 65536 ? i2 += 2 : i2++) {
      char = codePointAt(string, i2);
      escapeSeq = ESCAPE_SEQUENCES[char];
      if (!escapeSeq && isPrintable(char)) {
        result += string[i2];
        if (char >= 65536)
          result += string[i2 + 1];
      } else {
        result += escapeSeq || encodeHex(char);
      }
    }
    return result;
  }
  function writeFlowSequence(state, level, object) {
    var _result = "", _tag = state.tag, index, length, value;
    for (index = 0, length = object.length; index < length; index += 1) {
      value = object[index];
      if (state.replacer) {
        value = state.replacer.call(object, String(index), value);
      }
      if (writeNode(state, level, value, false, false) || typeof value === "undefined" && writeNode(state, level, null, false, false)) {
        if (_result !== "")
          _result += "," + (!state.condenseFlow ? " " : "");
        _result += state.dump;
      }
    }
    state.tag = _tag;
    state.dump = "[" + _result + "]";
  }
  function writeBlockSequence(state, level, object, compact) {
    var _result = "", _tag = state.tag, index, length, value;
    for (index = 0, length = object.length; index < length; index += 1) {
      value = object[index];
      if (state.replacer) {
        value = state.replacer.call(object, String(index), value);
      }
      if (writeNode(state, level + 1, value, true, true, false, true) || typeof value === "undefined" && writeNode(state, level + 1, null, true, true, false, true)) {
        if (!compact || _result !== "") {
          _result += generateNextLine(state, level);
        }
        if (state.dump && CHAR_LINE_FEED === state.dump.charCodeAt(0)) {
          _result += "-";
        } else {
          _result += "- ";
        }
        _result += state.dump;
      }
    }
    state.tag = _tag;
    state.dump = _result || "[]";
  }
  function writeFlowMapping(state, level, object) {
    var _result = "", _tag = state.tag, objectKeyList = Object.keys(object), index, length, objectKey, objectValue, pairBuffer;
    for (index = 0, length = objectKeyList.length; index < length; index += 1) {
      pairBuffer = "";
      if (_result !== "")
        pairBuffer += ", ";
      if (state.condenseFlow)
        pairBuffer += '"';
      objectKey = objectKeyList[index];
      objectValue = object[objectKey];
      if (state.replacer) {
        objectValue = state.replacer.call(object, objectKey, objectValue);
      }
      if (!writeNode(state, level, objectKey, false, false)) {
        continue;
      }
      if (state.dump.length > 1024)
        pairBuffer += "? ";
      pairBuffer += state.dump + (state.condenseFlow ? '"' : "") + ":" + (state.condenseFlow ? "" : " ");
      if (!writeNode(state, level, objectValue, false, false)) {
        continue;
      }
      pairBuffer += state.dump;
      _result += pairBuffer;
    }
    state.tag = _tag;
    state.dump = "{" + _result + "}";
  }
  function writeBlockMapping(state, level, object, compact) {
    var _result = "", _tag = state.tag, objectKeyList = Object.keys(object), index, length, objectKey, objectValue, explicitPair, pairBuffer;
    if (state.sortKeys === true) {
      objectKeyList.sort();
    } else if (typeof state.sortKeys === "function") {
      objectKeyList.sort(state.sortKeys);
    } else if (state.sortKeys) {
      throw new exception("sortKeys must be a boolean or a function");
    }
    for (index = 0, length = objectKeyList.length; index < length; index += 1) {
      pairBuffer = "";
      if (!compact || _result !== "") {
        pairBuffer += generateNextLine(state, level);
      }
      objectKey = objectKeyList[index];
      objectValue = object[objectKey];
      if (state.replacer) {
        objectValue = state.replacer.call(object, objectKey, objectValue);
      }
      if (!writeNode(state, level + 1, objectKey, true, true, true)) {
        continue;
      }
      explicitPair = state.tag !== null && state.tag !== "?" || state.dump && state.dump.length > 1024;
      if (explicitPair) {
        if (state.dump && CHAR_LINE_FEED === state.dump.charCodeAt(0)) {
          pairBuffer += "?";
        } else {
          pairBuffer += "? ";
        }
      }
      pairBuffer += state.dump;
      if (explicitPair) {
        pairBuffer += generateNextLine(state, level);
      }
      if (!writeNode(state, level + 1, objectValue, true, explicitPair)) {
        continue;
      }
      if (state.dump && CHAR_LINE_FEED === state.dump.charCodeAt(0)) {
        pairBuffer += ":";
      } else {
        pairBuffer += ": ";
      }
      pairBuffer += state.dump;
      _result += pairBuffer;
    }
    state.tag = _tag;
    state.dump = _result || "{}";
  }
  function detectType(state, object, explicit) {
    var _result, typeList, index, length, type2, style;
    typeList = explicit ? state.explicitTypes : state.implicitTypes;
    for (index = 0, length = typeList.length; index < length; index += 1) {
      type2 = typeList[index];
      if ((type2.instanceOf || type2.predicate) && (!type2.instanceOf || typeof object === "object" && object instanceof type2.instanceOf) && (!type2.predicate || type2.predicate(object))) {
        if (explicit) {
          if (type2.multi && type2.representName) {
            state.tag = type2.representName(object);
          } else {
            state.tag = type2.tag;
          }
        } else {
          state.tag = "?";
        }
        if (type2.represent) {
          style = state.styleMap[type2.tag] || type2.defaultStyle;
          if (_toString.call(type2.represent) === "[object Function]") {
            _result = type2.represent(object, style);
          } else if (_hasOwnProperty.call(type2.represent, style)) {
            _result = type2.represent[style](object, style);
          } else {
            throw new exception("!<" + type2.tag + '> tag resolver accepts not "' + style + '" style');
          }
          state.dump = _result;
        }
        return true;
      }
    }
    return false;
  }
  function writeNode(state, level, object, block2, compact, iskey, isblockseq) {
    state.tag = null;
    state.dump = object;
    if (!detectType(state, object, false)) {
      detectType(state, object, true);
    }
    var type2 = _toString.call(state.dump);
    var inblock = block2;
    var tagStr;
    if (block2) {
      block2 = state.flowLevel < 0 || state.flowLevel > level;
    }
    var objectOrArray = type2 === "[object Object]" || type2 === "[object Array]", duplicateIndex, duplicate;
    if (objectOrArray) {
      duplicateIndex = state.duplicates.indexOf(object);
      duplicate = duplicateIndex !== -1;
    }
    if (state.tag !== null && state.tag !== "?" || duplicate || state.indent !== 2 && level > 0) {
      compact = false;
    }
    if (duplicate && state.usedDuplicates[duplicateIndex]) {
      state.dump = "*ref_" + duplicateIndex;
    } else {
      if (objectOrArray && duplicate && !state.usedDuplicates[duplicateIndex]) {
        state.usedDuplicates[duplicateIndex] = true;
      }
      if (type2 === "[object Object]") {
        if (block2 && Object.keys(state.dump).length !== 0) {
          writeBlockMapping(state, level, state.dump, compact);
          if (duplicate) {
            state.dump = "&ref_" + duplicateIndex + state.dump;
          }
        } else {
          writeFlowMapping(state, level, state.dump);
          if (duplicate) {
            state.dump = "&ref_" + duplicateIndex + " " + state.dump;
          }
        }
      } else if (type2 === "[object Array]") {
        if (block2 && state.dump.length !== 0) {
          if (state.noArrayIndent && !isblockseq && level > 0) {
            writeBlockSequence(state, level - 1, state.dump, compact);
          } else {
            writeBlockSequence(state, level, state.dump, compact);
          }
          if (duplicate) {
            state.dump = "&ref_" + duplicateIndex + state.dump;
          }
        } else {
          writeFlowSequence(state, level, state.dump);
          if (duplicate) {
            state.dump = "&ref_" + duplicateIndex + " " + state.dump;
          }
        }
      } else if (type2 === "[object String]") {
        if (state.tag !== "?") {
          writeScalar(state, state.dump, level, iskey, inblock);
        }
      } else if (type2 === "[object Undefined]") {
        return false;
      } else {
        if (state.skipInvalid)
          return false;
        throw new exception("unacceptable kind of an object to dump " + type2);
      }
      if (state.tag !== null && state.tag !== "?") {
        tagStr = encodeURI(
          state.tag[0] === "!" ? state.tag.slice(1) : state.tag
        ).replace(/!/g, "%21");
        if (state.tag[0] === "!") {
          tagStr = "!" + tagStr;
        } else if (tagStr.slice(0, 18) === "tag:yaml.org,2002:") {
          tagStr = "!!" + tagStr.slice(18);
        } else {
          tagStr = "!<" + tagStr + ">";
        }
        state.dump = tagStr + " " + state.dump;
      }
    }
    return true;
  }
  function getDuplicateReferences(object, state) {
    var objects = [], duplicatesIndexes = [], index, length;
    inspectNode(object, objects, duplicatesIndexes);
    for (index = 0, length = duplicatesIndexes.length; index < length; index += 1) {
      state.duplicates.push(objects[duplicatesIndexes[index]]);
    }
    state.usedDuplicates = new Array(length);
  }
  function inspectNode(object, objects, duplicatesIndexes) {
    var objectKeyList, index, length;
    if (object !== null && typeof object === "object") {
      index = objects.indexOf(object);
      if (index !== -1) {
        if (duplicatesIndexes.indexOf(index) === -1) {
          duplicatesIndexes.push(index);
        }
      } else {
        objects.push(object);
        if (Array.isArray(object)) {
          for (index = 0, length = object.length; index < length; index += 1) {
            inspectNode(object[index], objects, duplicatesIndexes);
          }
        } else {
          objectKeyList = Object.keys(object);
          for (index = 0, length = objectKeyList.length; index < length; index += 1) {
            inspectNode(object[objectKeyList[index]], objects, duplicatesIndexes);
          }
        }
      }
    }
  }
  function dump$1(input, options) {
    options = options || {};
    var state = new State(options);
    if (!state.noRefs)
      getDuplicateReferences(input, state);
    var value = input;
    if (state.replacer) {
      value = state.replacer.call({ "": value }, "", value);
    }
    if (writeNode(state, 0, value, true, true))
      return state.dump + "\n";
    return "";
  }
  var dump_1 = dump$1;
  var dumper = {
    dump: dump_1
  };
  function renamed(from, to) {
    return function() {
      throw new Error("Function yaml." + from + " is removed in js-yaml 4. Use yaml." + to + " instead, which is now safe by default.");
    };
  }
  var Type = type;
  var Schema = schema;
  var FAILSAFE_SCHEMA = failsafe;
  var JSON_SCHEMA = json;
  var CORE_SCHEMA = core;
  var DEFAULT_SCHEMA = _default;
  var load = loader.load;
  var loadAll = loader.loadAll;
  var dump = dumper.dump;
  var YAMLException = exception;
  var types = {
    binary,
    float,
    map,
    null: _null,
    pairs,
    set,
    timestamp,
    bool,
    int,
    merge,
    omap,
    seq,
    str
  };
  var safeLoad = renamed("safeLoad", "load");
  var safeLoadAll = renamed("safeLoadAll", "loadAll");
  var safeDump = renamed("safeDump", "dump");
  var jsYaml = {
    Type,
    Schema,
    FAILSAFE_SCHEMA,
    JSON_SCHEMA,
    CORE_SCHEMA,
    DEFAULT_SCHEMA,
    load,
    loadAll,
    dump,
    YAMLException,
    types,
    safeLoad,
    safeLoadAll,
    safeDump
  };
  const name$2 = "frontmatter";
  const frontmatter = definePlugin({
    name: name$2,
    transform(transformHooks) {
      transformHooks.beforeParse.tap((md, context) => {
        const { content } = context;
        if (!/^---\r?\n/.test(content))
          return;
        const match = /\n---\r?\n/.exec(content);
        if (!match)
          return;
        const raw = content.slice(4, match.index);
        let frontmatter2;
        try {
          frontmatter2 = jsYaml.load(raw);
          if (frontmatter2 == null ? void 0 : frontmatter2.markmap) {
            frontmatter2.markmap = normalizeMarkmapJsonOptions(
              frontmatter2.markmap
            );
          }
        } catch {
          return;
        }
        context.frontmatter = frontmatter2;
        context.content = content.slice(match.index + match[0].length);
        context.contentLineOffset = content.slice(0, match.index).split("\n").length + 1;
      });
      return {};
    }
  });
  function normalizeMarkmapJsonOptions(options) {
    if (!options)
      return;
    ["color", "extraJs", "extraCss"].forEach((key) => {
      if (options[key] != null)
        options[key] = normalizeStringArray(options[key]);
    });
    ["duration", "maxWidth", "initialExpandLevel"].forEach((key) => {
      if (options[key] != null)
        options[key] = normalizeNumber(options[key]);
    });
    return options;
  }
  function normalizeStringArray(value) {
    let result;
    if (typeof value === "string")
      result = [value];
    else if (Array.isArray(value))
      result = value.filter((item) => item && typeof item === "string");
    return (result == null ? void 0 : result.length) ? result : void 0;
  }
  function normalizeNumber(value) {
    if (isNaN(+value))
      return;
    return +value;
  }
  const name$1 = "npmUrl";
  const npmUrl = definePlugin({
    name: name$1,
    transform(transformHooks) {
      transformHooks.afterParse.tap((_, context) => {
        const { frontmatter: frontmatter2 } = context;
        const markmap = frontmatter2 == null ? void 0 : frontmatter2.markmap;
        if (markmap) {
          ["extraJs", "extraCss"].forEach((key) => {
            const value = markmap[key];
            if (value) {
              markmap[key] = value.map((path) => {
                if (path.startsWith("npm:")) {
                  return transformHooks.transformer.urlBuilder.getFullUrl(
                    path.slice(4)
                  );
                }
                return path;
              });
            }
          });
        }
      });
      return {};
    }
  });
  const name = "hljs";
  const preloadScripts = [
    `@highlightjs/cdn-assets@${"11.8.0"}/highlight.min.js`
  ].map((path) => buildJSItem(path));
  const styles = [
    `@highlightjs/cdn-assets@${"11.8.0"}/styles/default.min.css`
    // `highlight.js@${process.env.HLJS_VERSION}/styles/default.css`,
  ].map((path) => buildCSSItem(path));
  const config = {
    versions: {
      hljs: "11.8.0"
    },
    preloadScripts,
    styles
  };
  const plugin = definePlugin({
    name,
    config,
    transform(transformHooks) {
      var _a, _b, _c;
      let loading;
      const preloadScripts2 = ((_b = (_a = plugin.config) == null ? void 0 : _a.preloadScripts) == null ? void 0 : _b.map(
        (item) => patchJSItem(transformHooks.transformer.urlBuilder, item)
      )) || [];
      const autoload = () => {
        loading || (loading = loadJS(preloadScripts2));
        return loading;
      };
      let enableFeature = noop;
      transformHooks.parser.tap((md) => {
        md.set({
          highlight: (str2, language) => {
            enableFeature();
            const { hljs } = window;
            if (hljs) {
              return hljs.highlightAuto(str2, language ? [language] : void 0).value;
            }
            autoload().then(() => {
              transformHooks.retransform.call();
            });
            return str2;
          }
        });
      });
      transformHooks.beforeParse.tap((_, context) => {
        enableFeature = () => {
          context.features[name] = true;
        };
      });
      return {
        styles: (_c = plugin.config) == null ? void 0 : _c.styles
      };
    }
  });
  const plugins = [frontmatter, plugin$1, plugin, npmUrl];
  function cleanNode(node) {
    var _a, _b;
    if (node.type === "heading") {
      node.children = node.children.filter((item) => item.type !== "paragraph");
    } else if (node.type === "list_item") {
      node.children = node.children.filter((item) => {
        if (["paragraph", "fence"].includes(item.type)) {
          if (!node.content) {
            node.content = item.content;
            node.payload = {
              ...node.payload,
              ...item.payload
            };
          }
          return false;
        }
        return true;
      });
      if (((_a = node.payload) == null ? void 0 : _a.index) != null) {
        node.content = `${node.payload.index}. ${node.content}`;
      }
    } else if (node.type === "ordered_list") {
      let index = ((_b = node.payload) == null ? void 0 : _b.startIndex) ?? 1;
      node.children.forEach((item) => {
        if (item.type === "list_item") {
          item.payload = {
            ...item.payload,
            index
          };
          index += 1;
        }
      });
    }
    if (node.children.length > 0) {
      node.children.forEach((child) => cleanNode(child));
      if (node.children.length === 1 && !node.children[0].content) {
        node.children = node.children[0].children;
      }
    }
  }
  function resetDepth(node, depth = 0) {
    node.depth = depth;
    node.children.forEach((child) => {
      resetDepth(child, depth + 1);
    });
  }
  class Transformer {
    constructor(plugins$1 = plugins) {
      this.assetsMap = {};
      this.urlBuilder = new UrlBuilder();
      this.hooks = createTransformHooks(this);
      this.plugins = plugins$1.map(
        (plugin2) => typeof plugin2 === "function" ? plugin2() : plugin2
      );
      const assetsMap = {};
      for (const { name: name2, transform } of this.plugins) {
        assetsMap[name2] = transform(this.hooks);
      }
      this.assetsMap = assetsMap;
      const md = new Remarkable("full", {
        html: true,
        breaks: true,
        maxNesting: Infinity
      });
      md.renderer.rules.htmltag = wrapFunction(
        md.renderer.rules.htmltag,
        (render, ...args) => {
          const result = render(...args);
          this.hooks.htmltag.call({ args, result });
          return result;
        }
      );
      this.md = md;
      this.hooks.parser.call(md);
    }
    buildTree(tokens) {
      const { md } = this;
      const root = {
        type: "root",
        depth: 0,
        content: "",
        children: [],
        payload: {}
      };
      const stack = [root];
      let depth = 0;
      for (const token of tokens) {
        const payload = {};
        if (token.lines) {
          payload.lines = token.lines;
        }
        let current = stack[stack.length - 1];
        if (token.type.endsWith("_open")) {
          const type2 = token.type.slice(0, -5);
          if (type2 === "heading") {
            depth = token.hLevel;
            while ((current == null ? void 0 : current.depth) >= depth) {
              stack.pop();
              current = stack[stack.length - 1];
            }
          } else {
            depth = Math.max(depth, (current == null ? void 0 : current.depth) || 0) + 1;
            if (type2 === "ordered_list") {
              payload.startIndex = token.order;
            }
          }
          const item = {
            type: type2,
            depth,
            payload,
            content: "",
            children: []
          };
          current.children.push(item);
          stack.push(item);
        } else if (!current) {
          continue;
        } else if (token.type === `${current.type}_close`) {
          if (current.type === "heading") {
            depth = current.depth;
          } else {
            stack.pop();
            depth = 0;
          }
        } else if (token.type === "inline") {
          const revoke = this.hooks.htmltag.tap((ctx) => {
            var _a;
            const comment2 = (_a = ctx.result) == null ? void 0 : _a.match(/^<!--([\s\S]*?)-->$/);
            const data = comment2 == null ? void 0 : comment2[1].trim().split(" ");
            if ((data == null ? void 0 : data[0]) === "fold") {
              current.payload = {
                ...current.payload,
                fold: ["all", "recursively"].includes(data[1]) ? 2 : 1
              };
              ctx.result = "";
            }
          });
          const text2 = md.renderer.render([token], md.options, {});
          revoke();
          current.content = `${current.content || ""}${text2}`;
        } else if (token.type === "fence") {
          const result = md.renderer.render([token], md.options, {});
          current.children.push({
            type: token.type,
            depth: depth + 1,
            content: result,
            children: [],
            payload
          });
        } else
          ;
      }
      return root;
    }
    transform(content) {
      var _a;
      const context = {
        content,
        features: {},
        contentLineOffset: 0
      };
      this.hooks.beforeParse.call(this.md, context);
      const tokens = this.md.parse(context.content, {});
      this.hooks.afterParse.call(this.md, context);
      let root = this.buildTree(tokens);
      cleanNode(root);
      if (((_a = root.children) == null ? void 0 : _a.length) === 1)
        root = root.children[0];
      resetDepth(root);
      return { ...context, root };
    }
    /**
     * Get all assets from enabled plugins or filter them by plugin names as keys.
     */
    getAssets(keys) {
      const styles2 = [];
      const scripts = [];
      keys ?? (keys = this.plugins.map((plugin2) => plugin2.name));
      for (const assets of keys.map((key) => this.assetsMap[key])) {
        if (assets) {
          if (assets.styles)
            styles2.push(...assets.styles);
          if (assets.scripts)
            scripts.push(...assets.scripts);
        }
      }
      return {
        styles: styles2.map((item) => patchCSSItem(this.urlBuilder, item)),
        scripts: scripts.map((item) => patchJSItem(this.urlBuilder, item))
      };
    }
    /**
     * Get used assets by features object returned by `transform`.
     */
    getUsedAssets(features) {
      const keys = this.plugins.map((plugin2) => plugin2.name).filter((name2) => features[name2]);
      return this.getAssets(keys);
    }
    fillTemplate(root, assets, extra) {
      extra = {
        ...extra
      };
      extra.baseJs ?? (extra.baseJs = baseJsPaths.map((path) => this.urlBuilder.getFullUrl(path)).map((path) => buildJSItem(path)));
      const { scripts, styles: styles2 } = assets;
      const cssList = [...styles2 ? persistCSS(styles2) : []];
      const context = {
        getMarkmap: () => window.markmap,
        getOptions: extra.getOptions,
        jsonOptions: extra.jsonOptions,
        root
      };
      const jsList = [
        ...persistJS(
          [
            ...extra.baseJs,
            ...scripts || [],
            {
              type: "iife",
              data: {
                fn: (getMarkmap, getOptions, root2, jsonOptions) => {
                  const markmap = getMarkmap();
                  window.mm = markmap.Markmap.create(
                    "svg#mindmap",
                    (getOptions || markmap.deriveOptions)(jsonOptions),
                    root2
                  );
                },
                getParams: ({ getMarkmap, getOptions, root: root2, jsonOptions }) => {
                  return [getMarkmap, getOptions, root2, jsonOptions];
                }
              }
            }
          ],
          context
        )
      ];
      const html = template.replace("<!--CSS-->", () => cssList.join("")).replace("<!--JS-->", () => jsList.join(""));
      return html;
    }
  }
  const transformerVersions = {
    "markmap-lib": "process.env.VERSION",
    d3: "7.8.5"
  };
  exports.Transformer = Transformer;
  exports.baseJsPaths = baseJsPaths;
  exports.builtInPlugins = plugins;
  exports.template = template;
  exports.transformerVersions = transformerVersions;
  Object.defineProperty(exports, Symbol.toStringTag, { value: "Module" });
})(this.markmap = this.markmap || {}, window.katex);
